import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes, inject } from 'mobx-react';
import ProductCollectionPropertySelect from '../ProductCollectionPropertySelect';

import { modelOf } from '../../../prop-types';
import ProductMatrix from '../../../models/product/ProductMatrix';
import ProductPropertyType from '../../../types/ProductPropertyType';
import ProductStore from '../../../store/ProductStore';
import ConfigStore from '../../../store/ConfigStore';
import Analytics from '../../../analytics/Analytics';
import AccountStore from '../../../store/AccountStore';
import FullProduct from '../../../models/product/FullProduct';
import FullProductMatrix from '../../../models/product/FullProductMatrix';

@observer
export class ProductCollectionProductPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeColumnElement: null,
      activeRowElement: null,
    };
  }

  componentDidMount() {
    const { productCollection, productStore } = this.props;
    const { row, column } = productCollection;
    const { activeColumnElement, activeRowElement } = this.state;
    if (activeColumnElement) {
      this.setProductVariationColumn(column, activeColumnElement);
      productStore.setSelectedColumn(activeColumnElement.name);
    }

    if (activeRowElement) {
      this.setProductVariationRow(activeRowElement, row);
      productStore.setSelectedRow(activeRowElement.name);
    }
  }

  componentDidUpdate() {
    const { activeColumnElement, activeRowElement } = this.state;
    const { activeProductId } = this.props;
    if (activeColumnElement && activeRowElement && !activeProductId) {
      this.updateProductId(activeColumnElement, activeRowElement);
    }
  }

  componentWillUnmount() {
    const { productStore } = this.props;
    productStore.setProductVariationColumn(null);
    productStore.setSelectedColumn(null);
    productStore.setProductVariationRow(null);
    productStore.setSelectedRow(null);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      activeColumnElement: nextProps.columnId
        ? nextProps.productCollection.column.getElementWithId(
            nextProps.columnId
          )
        : nextProps.productCollection.getDefaultColumnElement(),
      activeRowElement: nextProps.rowId
        ? nextProps.productCollection.row.getElementWithId(nextProps.rowId)
        : nextProps.productCollection.getDefaultRowElement(),
    };
  }

  getColumnElementsWithAvailability = () => {
    const { product, productCollection } = this.props;
    const { activeRowElement } = this.state;
    return productCollection.column.elements.reduce(
      (elementsWithAvailability, element) => {
        const status = activeRowElement
          ? productCollection.getItemStatus(
              element.id,
              activeRowElement.id,
              product.availability_type
            )
          : productCollection.getColumnStatus(element.id);
        if (status.exists) {
          elementsWithAvailability.push({
            element,
            available: status.available,
          });
        }
        return elementsWithAvailability;
      },
      []
    );
  };

  getRowElementsWithAvailability = () => {
    const { product, productCollection } = this.props;
    const { activeColumnElement } = this.state;

    return productCollection.row.elements.reduce(
      (elementsWithAvailability, element) => {
        const status = activeColumnElement
          ? productCollection.getItemStatus(
              activeColumnElement.id,
              element.id,
              product.availability_type
            )
          : productCollection.getRowStatus(element.id);

        if (status.exists) {
          elementsWithAvailability.push({
            element,
            available: status.available,
          });
        }
        return elementsWithAvailability;
      },
      []
    );
  };

  setQueryParams = ({ columnElement, rowElement }) => {
    let queryParams = {};

    if (columnElement?.id) {
      queryParams = {
        ...queryParams,
        columnId: columnElement.id,
      };
    }

    if (rowElement?.id) {
      queryParams = {
        ...queryParams,
        rowId: rowElement.id,
      };
    }

    return queryParams;
  };

  updateProductId = (columnElement, rowElement) => {
    const { productCollection, onProductChange } = this.props;
    const { column, row } = productCollection;

    let productId = null;
    if (
      columnElement !== null &&
      columnElement !== undefined &&
      rowElement !== null &&
      rowElement !== undefined
    ) {
      const item = productCollection.getItem(columnElement.id, rowElement.id);
      if (item) {
        productId = item.product.id;
      }
      this.setProductVariationColumn(column, columnElement);
      this.setProductVariationRow(rowElement, row);
    }

    // Only set query params, if we do not already have a product
    let queryParams = !productId
      ? this.setQueryParams({ columnElement, rowElement })
      : null;

    onProductChange(productId, queryParams);
  };

  onColumnChange = (columnElement) => {
    this.setState({
      activeColumnElement: columnElement,
    });
    this.updateProductId(columnElement, this.state.activeRowElement);
  };

  onRowChange = (rowElement) => {
    this.setState({
      activeRowElement: rowElement,
    });
    this.updateProductId(this.state.activeColumnElement, rowElement);
  };

  getDisplayFlagsForProperty = (property) => {
    const isColor = property.type === ProductPropertyType.COLOR;
    return {
      // TODO: Add a configuration for using buttons
      //showButtons: !isColor,
      showImages: isColor,
      showDropdown: true,
    };
  };

  setProductVariationColumn = (column, columnElement) => {
    const { productStore } = this.props;

    if (!columnElement || !column || !column.name || !columnElement.name) {
      productStore.setProductVariationColumn(null);
      return;
    }

    productStore.setProductVariationColumn(
      `${column.name}: ${columnElement.name}`
    );

    productStore.setSelectedColumn(columnElement.name);
  };

  setProductVariationRow = (rowElement, row) => {
    const { productStore } = this.props;

    if (!rowElement || !row || !row.name || !rowElement.name) {
      productStore.setProductVariationRow(null);
      return;
    }

    productStore.setProductVariationRow(`${row.name}: ${rowElement.name}`);
    productStore.setSelectedRow(rowElement.name);
  };

  render() {
    const { configStore, product, productCollection, productImages } =
      this.props;

    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const ifBuyingAllowed =
      product.merchantInfo && product.merchantInfo.buyingAllowed;

    if (ifShoppingCenter && !ifBuyingAllowed) {
      return null;
    }

    const selects = [];
    if (!productCollection.column.isEmpty()) {
      selects.push(
        <ProductCollectionPropertySelect
          key={'column'}
          product={product}
          productImages={productImages}
          property={productCollection.column}
          elementsWithAvailability={this.getColumnElementsWithAvailability()}
          activeElementId={
            this.state.activeColumnElement && this.state.activeColumnElement.id
          }
          onPropertySelect={this.onColumnChange}
          {...this.getDisplayFlagsForProperty(productCollection.column)}
          disabled={!productCollection.columnIsSelectable()}
        />
      );
    }
    if (!productCollection.row.isEmpty()) {
      selects.push(
        <ProductCollectionPropertySelect
          key={'row'}
          product={product}
          productImages={productImages}
          property={productCollection.row}
          elementsWithAvailability={this.getRowElementsWithAvailability()}
          activeElementId={
            this.state.activeRowElement && this.state.activeRowElement.id
          }
          onPropertySelect={this.onRowChange}
          {...this.getDisplayFlagsForProperty(productCollection.row)}
          disabled={!productCollection.rowIsSelectable()}
        />
      );
    }

    if (productCollection.shouldSwapCollectionOrder()) {
      selects.reverse();
    }

    return <div className="ProductCollectionProductPicker">{selects}</div>;
  }
}

ProductCollectionProductPicker.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  product: modelOf(FullProduct).isRequired,
  productImages: MobxPropTypes.observableArray.isRequired,
  productCollection: modelOf(FullProductMatrix).isRequired,
  onProductChange: PropTypes.func.isRequired,
  activeProductId: PropTypes.string,
  columnId: PropTypes.number,
  rowId: PropTypes.number,
};

export default inject(
  'accountStore',
  'configStore',
  'productStore',
  'analytics'
)(ProductCollectionProductPicker);
