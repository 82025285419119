import { types } from 'mobx-state-tree';
import { blockScroll, unblockScroll } from '../../util/dom';

const Modal = types
  .model('Modal', {
    isOpen: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      toggle(useBlockScroll = true, state = null) {
        self.isOpen = state || !self.isOpen;
        if (useBlockScroll) {
          self.isOpen ? blockScroll() : unblockScroll();
        }
      },
    };
  });

export default Modal;
