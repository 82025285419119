import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import TranslationStore from '../../../store/TranslationStore';
import useNavigationLogo from './hook';

const NavigationLogo = ({
  configStore,
  accountStore,
  translationStore,
  sticky,
  useStickyHeaderOnStartup,
}) => {
  const useScrollingHeader = useStickyHeaderOnStartup || sticky;

  const [getTranslatedLogo] = useNavigationLogo({
    accountStore,
    translationStore,
    useScrollingHeader,
  });

  const translatedLogo = getTranslatedLogo();

  if (!translatedLogo) {
    return null;
  }

  return (
    <NavbarBrand tag={Link} className="NavigationLogo" to="/">
      <img
        src={translatedLogo.image_path}
        alt={configStore.store.name}
        width={translatedLogo.width}
        height={translatedLogo.height}
      />
    </NavbarBrand>
  );
};

NavigationLogo.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  translationStore: modelOf(TranslationStore).isRequired,
  sticky: PropTypes.bool,
  useStickyHeaderOnStartup: PropTypes.bool,
};

export default inject(
  'accountStore',
  'configStore',
  'translationStore'
)(observer(NavigationLogo));
