import { has, keyBy } from 'lodash';
import { getRoot, types } from 'mobx-state-tree';

import LinkType from '../types/LinkType';
import Paths from '../types/Paths';
import SectionIntegrationType, {
  SectionIntegrationTypeType,
} from '../types/SectionIntegrationType';
import SectionStatusType, {
  SectionStatusTypeType,
} from '../types/SectionStatusType';
import generatePath from '../util/generatePath';

const Section = types
  .model('Section', {
    id: types.identifierNumber,
    slug: types.string,
    display_name: types.string,
    link_type: types.maybeNull(types.string),
    blocked_categories: types.array(types.number),
    main_categories: types.array(types.number),
    content_html: types.maybeNull(types.string),
    configurations: types.frozen(),
    status: SectionStatusTypeType,
    SEO_title: types.maybeNull(types.string),
    SEO_description: types.maybeNull(types.string),
    integration_type: types.maybeNull(SectionIntegrationTypeType),
    integration_feed_url: types.maybeNull(types.string),
    integration_api_key: types.maybeNull(types.string),
    footer_description_html: types.maybeNull(types.string),
    name_in_navigation: types.maybeNull(types.string),
    visible_to_crawlers: types.boolean,
  })
  .views((self) => {
    return {
      get blockedCategoriesDict() {
        return keyBy(self.blocked_categories, (categoryId) => categoryId);
      },
      get breadcrumbs() {
        return [
          {
            text: self.display_name,
            url: self.path,
          },
        ];
      },
      get hasContentIntegration() {
        const configStore = getRoot(self).configStore;
        return (
          configStore.integrations.arinet.enabled &&
          self.integration_type === SectionIntegrationType.ARINET &&
          !!self.integration_feed_url &&
          !!self.integration_api_key
        );
      },
      get hasSectionIntegration() {
        const configStore = getRoot(self).configStore;
        return (
          configStore.integrations.zoovu.enabled &&
          self.integration_type === SectionIntegrationType.ZOOVU &&
          !!self.integration_feed_url &&
          !!self.integration_api_key
        );
      },
      get isIndexableByRobots() {
        if (self.status !== SectionStatusType.TEST_MODE) {
          return true;
        }

        return (
          self.status === SectionStatusType.TEST_MODE &&
          self.visible_to_crawlers
        );
      },
      get mainCategories() {
        const categoryStore = getRoot(self).categoryStore;
        return categoryStore.flattenedCategories.filter((category) =>
          self.mainCategoryIds.includes(category.id)
        );
      },
      get mainCategoriesDict() {
        return keyBy(self.mainCategoryIds, (categoryId) => categoryId);
      },
      get mainCategoryIds() {
        return self.main_categories.filter((categoryId) => {
          return !self.blockedCategoriesDict[categoryId];
        });
      },
      get navigationName() {
        return self.name_in_navigation && self.name_in_navigation.length > 0
          ? self.name_in_navigation
          : self.display_name;
      },
      get path() {
        if (
          self.link_type === LinkType.EXTERNAL_LINK ||
          self.link_type === LinkType.INTERNAL_LINK
        ) {
          return self.slug;
        }

        if (self.slug && self.slug.length > 0) {
          return generatePath(Paths.Section, {
            section: self.slug,
          });
        }
      },
      hasMainCategory(categoryId) {
        return has(self.mainCategoriesDict, categoryId);
      },
      includesCategory(category) {
        return (
          self.hasMainCategory(category.mainCategory.id) &&
          !has(self.blockedCategoriesDict, category.id)
        );
      },
    };
  })
  .actions((self) => {
    return {
      setConfiguration(overrides) {
        self.configurations = {
          ...self.configurations,
          ...overrides,
        };
      },
    };
  });

export default Section;
