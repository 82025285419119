import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const ProductDateAvailable = ({ product }) => {
  if (
    product.free_quantity !== 0 ||
    DateTime.utc() >= DateTime.fromISO(product.date_available)
  ) {
    return null;
  }

  const dateAvailable = DateTime.fromISO(
    product.date_available
  ).toLocaleString();

  return (
    <div className="ProductDateAvailable">
      <FormattedMessage
        id="product.dateAvailable"
        defaultMessage="Product will be available {date}"
        values={{
          date: dateAvailable,
        }}
      />
    </div>
  );
};

ProductDateAvailable.propTypes = {
  // Both Products and ChildProducts are allowed.
  product: PropTypes.object.isRequired,
};

export default inject('configStore')(observer(ProductDateAvailable));
