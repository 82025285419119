import { PropTypes as MobxPropTypes, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import Country from '../../../models/Country';
import { modelOf } from '../../../prop-types';
import DropdownSelect from '../../common/DropdownSelect';
import LocaleFlagIcon from '../LocaleFlagIcon';

const DEFAULT_LARGE_MENU_LENGTH = 16;

const RegionalSettingsDeliveryCountryDropdown = ({
  country,
  countries,
  onSelect,
}) => {
  return (
    <DropdownSelect
      label={
        <FormattedMessage
          {...globalTranslations.deliveryCountryLabel}
          values={{
            country: (
              <span>
                <LocaleFlagIcon locale={country.iso_code_2.toLowerCase()} />{' '}
                {country.name}
              </span>
            ),
          }}
        />
      }
      isLarge={countries.length > DEFAULT_LARGE_MENU_LENGTH}
    >
      {countries.map((country) => (
        <DropdownItem onClick={() => onSelect(country)} key={country.id}>
          <span>
            <LocaleFlagIcon locale={country.iso_code_2.toLowerCase()} />{' '}
            {country.name}
          </span>
        </DropdownItem>
      ))}
    </DropdownSelect>
  );
};

RegionalSettingsDeliveryCountryDropdown.propTypes = {
  country: modelOf(Country).isRequired,
  countries: MobxPropTypes.observableArrayOf(modelOf(Country)).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default observer(RegionalSettingsDeliveryCountryDropdown);
