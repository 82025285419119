import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import ReviewModel from '../../../models/review/Review';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import { countryCodeFromLocale } from '../../../util/languageMapper';
import LocaleFlagIcon from '../../common/LocaleFlagIcon';
import ReviewSwitchLanguage from '../ReviewSwitchLanguage';
import ReviewText from '../ReviewText';
import Stars from '../Stars';

const Review = ({ review, configStore }) => {
  const [showOriginal, switchTranslation] = useState(false);
  const reviewAddDate = DateTime.fromISO(review.date_added);

  const renderReviewDescription = () => {
    const reviewText = showOriginal
      ? review.original.reviews_text
      : review.description.reviews_text;

    return <ReviewText text={reviewText} />;
  };

  const renderReviewSwitchLanguage = () => {
    return (
      <ReviewSwitchLanguage
        showOriginal={showOriginal}
        onTranslationSwitch={switchTranslation}
      />
    );
  };

  const renderReviewToolbar = () => {
    return (
      <div className="Review__toolbar">
        <span className="Review__translation-info">
          <FormattedMessage
            id="review.translationInfo"
            defaultMessage="Translated from {flag}"
            values={{
              flag: (
                <LocaleFlagIcon
                  locale={countryCodeFromLocale(review.original.language)}
                />
              ),
            }}
          />
        </span>
        {renderReviewSwitchLanguage()}
      </div>
    );
  };

  return (
    <div className="Review">
      {review.original?.reviews_text &&
        review.description?.reviews_text &&
        renderReviewToolbar()}
      <div className="Review__content">
        <div className="Review__title">
          <span>
            {review.customers_name}
            <span className="Review__date">
              , {reviewAddDate.toLocaleString()}
            </span>
          </span>
        </div>
        {review.description.reviews_text && renderReviewDescription()}
        <table className="Review__ratings">
          <tbody>
            <tr>
              <th className="Review__rating-stars">
                <Stars amount={review.reviews_rating} />
              </th>
              <td className="Review__rating-title">
                <FormattedMessage
                  {...globalTranslations.reviewProductRatingTitle}
                />
              </td>
            </tr>
            <tr>
              <th className="Review__rating-stars">
                <Stars amount={review.service_rating} />
              </th>
              <td className="Review__rating-title">
                <FormattedMessage
                  {...globalTranslations.reviewServiceRatingTitle}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {review.description.admin_reply && (
          <div className="Review__admin-reply">
            <div className="Review__admin-reply-title">
              <FormattedMessage
                id="review.staffReplySentence"
                defaultMessage="{name} responds:"
                values={{ name: configStore.store.name }}
              />
            </div>
            <ReviewText
              className="Review__admin-reply-content"
              text={review.description.admin_reply}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Review.propTypes = {
  review: modelOf(ReviewModel).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(Review));
