import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import NavigationAddToCartRow from '../../components/cart/NavigationAddToCartRow';
import ScrollReset from '../../components/common/ScrollReset';
import Page from '../../components/layout/Page';
import ProductPageContainer from '../../components/product/ProductPageContainer';
import ProductPageHead from '../../components/product/ProductPageHead';
import MobilePageSkeleton from '../../components/skeleton/MobilePageSkeleton';
import PageSkeleton from '../../components/skeleton/PageSkeleton';
import globalUserMessages from '../../i18n/globalUserMessages';
import { modelOf } from '../../prop-types';
import RouteService from '../../services/RouteService';
import CategoryStore from '../../store/CategoryStore';
import ProductStore from '../../store/ProductStore';
import SectionStore from '../../store/SectionStore';
import UIStore from '../../store/UIStore';
import CommonPage from '../../types/CommonPage';
import Paths from '../../types/Paths';
import ProductClass from '../../types/ProductClass';
import TrackingEventType from '../../types/TrackingPageType';
import { globalUserMessageDataKey } from '../../util/constants';
import generatePath from '../../util/generatePath';
import {
  addParametersToPath,
  parse,
  removeQueryParameters,
} from '../../util/queryString';
import {
  fixPercentDecodingForParam,
  pathFragmentMatchesLocation,
} from '../../util/url';
import useProductPage from './hook';

const ProductPage = ({
  categoryStore,
  productStore,
  sectionStore,
  uiStore,
  routeService,
}) => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  // TODO
  // This function is a workaround for an issue regarding percent encoded
  // URLs in React Router and its history library.
  // It should be fixed in the next version of history > 4.9.0, which had
  // not yet been published at the time of this alternate solution;
  // when that version is out, this fix can be removed.
  //
  // See: https://github.com/ReactTraining/history/issues/505
  const getProductId = () => {
    return fixPercentDecodingForParam(params.id);
  };

  const productId = getProductId();

  const getCurrentSearchState = () => {
    return productStore.fullProductStates.get(productId);
  };

  const getCurrentProduct = () => {
    return productStore.fullProducts.get(productId);
  };

  let urlParams = null;
  if (location.search) {
    urlParams = parse(location.search);
  }

  const fetchParams = {
    id: productId,
    urlParams,
    state: getCurrentSearchState(),
  };

  const [fetchProductData] = useProductPage({
    productId,
  });

  fetchProductData({ productStore, fetchParams });

  const product = getCurrentProduct();

  const getActiveProductId = () => {
    let activeProductId;
    if (product.class === ProductClass.COLLECTION) {
      activeProductId = params.activeProductId;
    } else if (product.class === ProductClass.MULTI) {
      activeProductId = params.activeProductId;
    } else {
      activeProductId = product.id;
    }

    return fixPercentDecodingForParam(activeProductId);
  };

  const activeProductId =
    product &&
    getActiveProductId({
      productId: product.id,
      productClass: product.class,
      params,
    });

  /* Product 404 error and fallback redirections made instead of rendering skeleton */
  if (productStore?.lastError?.httpStatusCode === 404) {
    if (!productStore?.lastError.fallbackCategoryId) {
      return <Redirect to={routeService.getPath(Paths.NotFoundPage)} />;
    }

    // CustomEvent is not supported by node.
    if (!window.isSSR) {
      const customEvent = new CustomEvent(
        globalUserMessageDataKey.globalUserMessage,
        {
          detail: {
            ...globalUserMessages.productNotExist,
          },
        }
      );
      window.dispatchEvent(customEvent);
    }

    const path =
      categoryStore.findCategoryById(productStore.lastError.fallbackCategoryId)
        ?.path ?? Paths.FrontPage;

    return <Redirect to={routeService.getPath(path)} />;
  }

  /* Product data is required to proceed so meanwhile we render skeleton */
  if (!product || product.id !== params.id) {
    return uiStore.isMobile ? (
      <>
        <ScrollReset key={productId} />
        <MobilePageSkeleton />
      </>
    ) : (
      <>
        <ScrollReset key={productId} />
        <PageSkeleton />
      </>
    );
  }

  const redirectToProduct = () => {
    const productPath = routeService.getProductPath(product);
    return <Redirect to={productPath} />;
  };

  if (
    (sectionStore.activeSection &&
      !product.belongsToSection(sectionStore.activeSection.id)) ||
    (!sectionStore.activeSection &&
      product.belongsToSomeSection(sectionStore.sectionIds))
  ) {
    return <Redirect to={routeService.getProductPath(product)} />;
  }

  const expectedPath =
    (activeProductId && params.activeProductId
      ? product.pathWithActiveProductId(activeProductId)
      : product.path) || '';

  if (!pathFragmentMatchesLocation(expectedPath, location.pathname)) {
    const url = `${routeService.getProductPath(product, expectedPath)}${
      location.search
    }${location.hash}`;

    return <Redirect to={url} />;
  }

  // If active collection or multi item is not valid, redirect to parent.
  if (product.class === ProductClass.COLLECTION) {
    if (
      activeProductId &&
      !product.collection.getItemWithProductId(activeProductId)
    ) {
      return redirectToProduct();
    }
  }

  if (product.class === ProductClass.MULTI) {
    if (activeProductId && !product.getActualProduct(activeProductId)) {
      return redirectToProduct();
    }
  }

  // If the product is MULTI_CHILD, redirect to parent
  if (product.class === ProductClass.MULTI_CHILD) {
    const productPath = routeService.getProductPath(
      product,
      product.pathWithActiveProductId(product.id)
    );
    return <Redirect to={productPath} />;
  }

  const getManufacturerId = () => {
    return product?.manufacturer?.id;
  };

  const setQueryParams = () =>
    removeQueryParameters({
      location,
      params: ['columnId', 'rowId'],
    });

  const setActiveProductId = ({ activeProductId, queryParams }) => {
    // Store all query params to reinsert back the remaining params we want to preserve in the url
    // after history.replace
    const allQueryParams = setQueryParams();
    const pathParams = { ...params, activeProductId };

    const newProductPath = addParametersToPath(
      generatePath(match.path, pathParams),
      {
        ...queryParams,
        ...allQueryParams,
      }
    );

    history.replace(newProductPath);
  };

  return (
    <Page
      name={CommonPage.PRODUCT}
      className={classNames('ProductPage', getManufacturerId())}
      onDefinedTrackingPage={TrackingEventType.PRODUCT}
    >
      <ProductPageHead product={product} activeProductId={activeProductId} />
      <ScrollReset key={productId} />
      <NavigationAddToCartRow
        product={product}
        activeProductId={activeProductId}
      />
      <ProductPageContainer
        product={product}
        activeProductId={activeProductId}
        setActiveProductId={setActiveProductId}
      />
    </Page>
  );
};

ProductPage.propTypes = {
  categoryStore: modelOf(CategoryStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject(
  'categoryStore',
  'productStore',
  'sectionStore',
  'uiStore',
  'routeService'
)(observer(ProductPage));
