import loadable from '@loadable/component';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';

import classNames from 'classnames';
import Analytics from '../../../analytics/Analytics';
import globalTranslations from '../../../i18n/globalTranslations';
import FullProduct from '../../../models/product/FullProduct';
import FullProductMatrix from '../../../models/product/FullProductMatrix';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import CartStore from '../../../store/CartStore';
import ConfigStore from '../../../store/ConfigStore';
import CurrencyStore from '../../../store/CurrencyStore';
import UIStore from '../../../store/UIStore';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import RequestState from '../../../types/RequestState';
import { productCountValid, quantityValid } from '../../../util/formValidators';
import { calculateTotal, roundWithPrecision } from '../../../util/number';
import CustomCol from '../../bootstrap/CustomCol';
import Icon from '../../common/Icon';
import { initialImageOrigin } from '../../common/ImageLightbox/ImageLightboxProductImage';
import { DEFAULT_MAX_QUANTITY } from '../ProductAddToCart';
import ProductCollectionMatrixTooltip from '../ProductCollectionMatrixTooltip';
import ProductImage from '../ProductImage';

const ImageLightbox = loadable(() =>
  import(
    /* webpackChunkName: "common" */ '../../common/ImageLightbox/ImageLightboxModal'
  )
);

const messages = defineMessages({
  quantityInput: {
    id: 'product.quantityInput',
    defaultMessage: 'Quantity',
  },
  notAvailable: {
    id: 'product.notAvailable',
    defaultMessage: '(Not available)',
  },
});

@observer
export class ProductCollectionMatrix extends Component {
  constructor(props) {
    super(props);

    const { product } = props;

    product.sortProductImages('for_color_id');

    this.state = {
      selectedProducts: [],
      analyticsProducts: [],
      mainImageIndex: null,
      lightboxIsOpen: false,
      formError: false,
    };

    product.images.forEach((_, index) => {
      this.lightboxInitialState = {
        ...this.lightboxInitialState,
        [index]: {
          focus: false,
          scale: 1,
          origin: initialImageOrigin,
        },
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product.id !== this.props.product.id) {
      this.setState({
        selectedProducts: null,
        analyticsProducts: null,
      });
    }
  }

  getImageLightbox = () => {
    const { product } = this.props;
    const { mainImageIndex, lightboxIsOpen } = this.state;

    if (
      product.images.length === 0 ||
      !lightboxIsOpen ||
      mainImageIndex === null
    ) {
      return null;
    }

    this.lightboxInitialState.selectedImage = mainImageIndex;

    return (
      <ImageLightbox
        initialState={this.lightboxInitialState}
        mainImageIndex={mainImageIndex}
        lightboxIsOpen={lightboxIsOpen}
        product={product}
        onClick={this.toggleLightbox.bind(this, false)}
        images={product.images}
      />
    );
  };

  toggleLightbox = (lightboxIsOpen) => this.setState({ lightboxIsOpen });

  getCollectionRowElements = () => this.props.productMatrix.getRowElements();

  addSelectedProducts = () => {
    const { cartStore, analytics, accountStore, currencyStore } = this.props;
    const { selectedProducts, analyticsProducts } = this.state;
    selectedProducts &&
      analyticsProducts &&
      selectedProducts.length > 0 &&
      analyticsProducts.length > 0 &&
      cartStore
        .populate(selectedProducts, accountStore.showCartMatrix)
        .then(() => {
          const totalValue = this.calculateTotalAnalyticsValue(
            currencyStore,
            analyticsProducts
          );
          analytics.addToCart(analyticsProducts, totalValue);
          analytics.cartStatus({
            cart: cartStore.cart,
          });
        });
  };

  calculateTotalAnalyticsValue() {
    const { analyticsProducts } = this.state;
    const precision = 4;
    return roundWithPrecision(
      calculateTotal(
        analyticsProducts.map((product) => product.price * product.quantity),
        precision
      ),
      precision
    );
  }

  renderProductMatrixTitles = () => {
    const titles = this.props.productMatrix.getNames();
    return (
      <Col>
        <Label className="ProductCollectionMatrix__column-row-label">
          <span>
            {titles.columnName}
            {titles.columnName && titles.rowName && ' / '}
            {titles.rowName}
          </span>
        </Label>
      </Col>
    );
  };

  renderProductElementRows = () => {
    const productRows = this.getCollectionRowElements();

    return (
      productRows && (
        <thead>
          <tr>
            {productRows[0].name && <th />}
            {productRows.map(
              (rowElement, index) =>
                rowElement.name && (
                  <th
                    key={index}
                    className="ProductCollectionMatrix__row-element-title"
                    dangerouslySetInnerHTML={{ __html: rowElement.name }}
                  />
                )
            )}
          </tr>
        </thead>
      )
    );
  };

  renderProductColumns = () => {
    const { product, productMatrix, uiStore, ifProductRowImages } = this.props;
    return (
      <tbody>
        {productMatrix.getColumnElements().map((element) => {
          const productRowImage = product.images.find(
            (image) => image.for_color_id === element.id
          );
          const columnTitle = productMatrix.getNames();
          ifProductRowImages && ifProductRowImages(productRowImage);
          const productImage = (
            <>
              <ProductImage
                product={product}
                size={'small'}
                productImage={productRowImage}
                forceAspectRatio={false}
                lazyLoading={false}
                className="ProductCollectionMatrix__product-image"
                wrapperClassName="ProductCollectionMatrix__product-image-wrapper"
                overlayIcon={
                  productRowImage && (
                    <span className="ProductCollectionMatrix__product-image-overlay-icon">
                      <Icon name="expand" />
                    </span>
                  )
                }
              />
              <p className="ProductCollectionMatrix__product-column-title">
                {columnTitle.columnName}: {element.name}
              </p>
            </>
          );

          return (
            element && (
              <tr
                key={element.id}
                className="ProductCollectionMatrix__product-row"
              >
                {!uiStore.isMobile && (
                  <th
                    className="ProductCollectionMatrix__product-row-header"
                    scope="row"
                    onClick={
                      productRowImage &&
                      this.setMainImageIndex.bind(this, productRowImage)
                    }
                  >
                    {productImage}
                  </th>
                )}
                {uiStore.isMobile && (
                  <td
                    className="ProductCollectionMatrix__font-label-weight"
                    onClick={
                      productRowImage &&
                      this.setMainImageIndex.bind(this, productRowImage)
                    }
                  >
                    {productImage}
                  </td>
                )}
                {element && this.renderProductQuantityInputs(element.id)}
              </tr>
            )
          );
        })}
      </tbody>
    );
  };

  setMainImageIndex = (productRowImage) => {
    const { product } = this.props;
    const mainImageIndex = product.images.findIndex(
      (image) => image.for_color_id === productRowImage.for_color_id
    );
    this.setState({ mainImageIndex }, () => this.toggleLightbox(true));
  };

  // TODO: Refactor into a component or modify to use ProductMatrixCell
  renderProductQuantityInputs = (productCollectionElementId) => {
    const { product, productMatrix, uiStore, intl } = this.props;
    const rowElements = this.getCollectionRowElements();

    return (
      rowElements &&
      rowElements.map((rowElement) => {
        const elementIds = productMatrix.shouldSwapCollectionOrder()
          ? { rowId: productCollectionElementId, columnId: rowElement.id }
          : { columnId: productCollectionElementId, rowId: rowElement.id };

        const productCollectionElementItem =
          this.findProductCollectionElementItem(
            elementIds.columnId,
            elementIds.rowId
          );

        const element =
          uiStore.isMobile && this.findRowElementById(rowElement.id);

        const incomingQuantities =
          productCollectionElementItem.getIncomingQuantities();
        const ifProductAvailable = this.checkProductAvailability(
          productCollectionElementItem
        );

        const freeQuantity = productCollectionElementItem.product.free_quantity;

        const elementProductId = productCollectionElementItem.product.id;
        const stockIsLoading =
          productCollectionElementItem.stockStates.get(elementProductId) ===
          RequestState.LOADING;

        const renderLoadingIcon = () => {
          return (
            <Icon
              className={classNames(
                'ProductCollectionMatrix__quantity-input-loading-icon',
                {
                  'ProductCollectionMatrix__quantity-input-loading-icon--visible':
                    stockIsLoading,
                }
              )}
              name="circle-o-notch"
              spin={stockIsLoading}
            />
          );
        };

        const renderStocksInfoTooltip = () => {
          let isOpen;

          if (
            this.props.configStore.productPage.matrixDetailedStockInfoEnabled
          ) {
            isOpen =
              productCollectionElementItem.stocks.get(elementProductId) &&
              this.state[
                `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`
              ];
          } else {
            isOpen =
              this.state[
                `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`
              ];
          }

          return (
            isOpen && (
              <ProductCollectionMatrixTooltip
                productCollectionElementItem={productCollectionElementItem}
                isOpen={isOpen}
                stockUnit={product.stock_unit}
                productMatrix={productMatrix}
              />
            )
          );
        };

        return (
          <td
            key={`${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
            className="ProductCollectionMatrix__quantity-display-and-selection-column"
          >
            {uiStore.isMobile && (
              <Label
                className="ProductCollectionMatrix__font-label-weight"
                dangerouslySetInnerHTML={{ __html: element.name }}
              />
            )}
            <>
              <Row className="ProductCollectionMatrix__quantity-selection-row">
                <Col xs={12} className="align-self-center">
                  <Label
                    className="ProductCollectionMatrix__quantity-and-stock-unit"
                    for={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
                  >
                    {freeQuantity} {product.stock_unit}
                  </Label>
                </Col>
                {(incomingQuantities.length > 0 || !ifProductAvailable) && (
                  <Col xs={12}>
                    <Label className="ProductCollectionMatrix__exceptionalAvailability">
                      {ifProductAvailable ? (
                        <FormattedMessage
                          {...globalTranslations.exceptionalAvailability}
                        />
                      ) : (
                        <FormattedMessage {...messages.notAvailable} />
                      )}
                    </Label>
                  </Col>
                )}
                <Col xs={12}>
                  <FormGroup>
                    <span className="ProductCollectionMatrix__quantity-input-group">
                      <Input
                        disabled={!ifProductAvailable}
                        id={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
                        name={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
                        className="ProductCollectionMatrix__quantity-input"
                        type="number"
                        onChange={this.setProduct.bind(
                          this,
                          productCollectionElementItem,
                          productCollectionElementItem.row_id,
                          productCollectionElementItem.column_id
                        )}
                        value={
                          this.state[
                            `qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`
                          ] || ''
                        }
                        placeholder={intl.formatMessage(messages.quantityInput)}
                        onFocus={this.openTooltip.bind(
                          this,
                          productCollectionElementItem
                        )}
                        onBlur={this.hideTooltip.bind(
                          this,
                          productCollectionElementItem
                        )}
                        invalid={
                          !!this.state[
                            `qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--error`
                          ]
                        }
                      />
                      {renderLoadingIcon()}
                    </span>
                    {this.quantityError(
                      productCollectionElementItem,
                      productCollectionElementItem.column_id
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {renderStocksInfoTooltip()}
            </>
          </td>
        );
      })
    );
  };

  findProductCollectionElementItem = (
    productCollectionColumnElementId,
    productCollectionRowElementId
  ) =>
    this.props.productMatrix.getItem(
      productCollectionColumnElementId,
      productCollectionRowElementId
    );

  findRowElementById = (rowElementId) =>
    this.props.productMatrix
      .getRowElements()
      .find((rowElement) => rowElement.id === rowElementId);

  checkProductAvailability = (productCollectionElementItem) => {
    const { product } = this.props;
    const quantity = productCollectionElementItem.getAvailableAmount() > 0;
    const ifCanBeOrderedOutOfStock =
      productCollectionElementItem.canBeOrderedOutOfStock();
    const allowBackOrder =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER;
    return quantity || ifCanBeOrderedOutOfStock || allowBackOrder;
  };

  setProduct = (
    productCollectionElementItem,
    productCollectionRowElementId,
    productCollectionColumnElementId,
    event
  ) => {
    const name = event.target.name;
    const selectedProducts = this.state.selectedProducts;
    const id = productCollectionElementItem.product.id;
    const maxQuantity = this.getMaxQuantity(id);
    const qty = this.getQuantity(Number(event.target.value), maxQuantity);

    this.setState({ [name]: qty }, () => {
      const quantity =
        this.state[
          `qty--${productCollectionRowElementId}--${productCollectionColumnElementId}`
        ];

      this.validateProductQuantity(
        productCollectionRowElementId,
        productCollectionColumnElementId,
        maxQuantity,
        name
      );

      this.findSelectedProduct(selectedProducts, id, quantity);
      this.modifySelectedProductsForAnalytics();
    });
  };

  getMaxQuantity = (activeProductId) => {
    const { accountStore, configStore, product } = this.props;

    const maxAccountQuantity =
      accountStore.account && accountStore.account.max_product_quantity > 0
        ? accountStore.account.max_product_quantity
        : DEFAULT_MAX_QUANTITY;

    const maxBackorderQuantity =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
        ? configStore.product.backorderLimit > 0
          ? configStore.product.backorderLimit
          : DEFAULT_MAX_QUANTITY
        : DEFAULT_MAX_QUANTITY;

    const maxNormalProductQuantity =
      product.availability_type !== ProductAvailabilityType.ALLOW_BACKORDER
        ? product.canBeOrderedOutOfStock
          ? DEFAULT_MAX_QUANTITY
          : this.getMaxByPackage(product, activeProductId)
        : DEFAULT_MAX_QUANTITY;

    return Math.min(
      maxAccountQuantity,
      maxBackorderQuantity,
      maxNormalProductQuantity,
      DEFAULT_MAX_QUANTITY
    );
  };

  getQuantity = (qty, maxQuantity) => {
    const { product } = this.props;

    const freeQuantity = product.getFreeQuantity(product.id);

    const cannotBeOrdered =
      freeQuantity <= 0 && !product.canBeOrderedOutOfStock && qty < 0;
    const canBeOrdered = freeQuantity > 0 && qty > 0 && qty <= maxQuantity;
    const exceedsMaxQuantity = qty >= maxQuantity;
    const nonNegativeQuantity = qty > 0 && qty;

    return cannotBeOrdered
      ? 0
      : canBeOrdered
      ? nonNegativeQuantity
      : exceedsMaxQuantity
      ? maxQuantity
      : nonNegativeQuantity;
  };

  getMaxByPackage = (product, activeProductId) => {
    return product.sellInPackage
      ? Math.floor(
          product.getFreeQuantity(activeProductId) / product.package_size
        ) * product.package_size
      : product.getFreeQuantity(activeProductId);
  };

  validateProductQuantity(
    productCollectionRowElementId,
    productCollectionColumnElementId,
    maxQuantity,
    name
  ) {
    const quantityStep = this.getQuantityStep();
    const currentQuantity =
      this.state[
        `qty--${productCollectionRowElementId}--${productCollectionColumnElementId}`
      ];

    if (quantityStep > 1) {
      const validQuantity = quantityValid(maxQuantity, quantityStep);
      const validProductCount = productCountValid(quantityStep);
      const invalidQuantity =
        validQuantity(currentQuantity) || validProductCount(currentQuantity);

      if (invalidQuantity) {
        const error = { error: invalidQuantity };
        this.setState({
          [name + '--error']: error,
          formError: true,
        });
      }

      if (!invalidQuantity || !currentQuantity) {
        this.setState({
          [name + '--error']: null,
          formError: false,
        });
      }
    }
  }

  getQuantityStep = () => {
    const { product } = this.props;
    return product && product.sellInPackage ? product.package_size : 1;
  };

  findSelectedProduct = (selectedProducts, id, qty) => {
    const selectedProduct = selectedProducts.find(
      (product) => product.id === id
    );
    if (selectedProduct) {
      this.updateOrRemoveSelectedProductQuantity(
        selectedProducts,
        selectedProduct,
        qty
      );
    } else {
      qty > 0 && this.addSelectedProduct(selectedProducts, id, qty);
    }
  };

  updateOrRemoveSelectedProductQuantity = (
    selectedProducts,
    selectedProduct,
    qty
  ) => {
    const productIndex = selectedProducts.findIndex(
      (product) => product.id === selectedProduct.id
    );
    qty > 0 &&
      this.updateSelectedProductQuantity(
        selectedProducts,
        selectedProduct,
        qty,
        productIndex
      );
    (!qty || qty === 0) &&
      this.removeSelectedProduct(selectedProducts, productIndex);
  };

  updateSelectedProductQuantity = (
    selectedProducts,
    selectedProduct,
    qty,
    productIndex
  ) => {
    selectedProduct.qty = qty;
    selectedProducts.splice(productIndex, 1, selectedProduct);
    this.setState({ selectedProducts });
  };

  removeSelectedProduct = (selectedProducts, productIndex) => {
    selectedProducts.splice(productIndex, 1);
    this.setState({ selectedProducts });
  };

  addSelectedProduct = (selectedProducts, id, qty) => {
    selectedProducts.push({
      id,
      qty,
    });
    this.setState({ selectedProducts });
  };

  modifySelectedProductsForAnalytics = () => {
    const { accountStore, product } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const analyticsProducts = this.state.selectedProducts.map(
      (selectedProduct) => {
        const analyticsProduct = {};
        analyticsProduct.product = product;
        analyticsProduct.quantity = selectedProduct.qty;
        analyticsProduct.activeProductId = selectedProduct.id;
        analyticsProduct.price = product.getPrice(withTax, selectedProduct.id);
        return analyticsProduct;
      }
    );

    this.setState({
      analyticsProducts,
    });
  };

  openTooltip = (productCollectionElementItem) => {
    const inputTooltip = `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`;

    if (this.props.configStore.productPage.matrixDetailedStockInfoEnabled) {
      const { product } = this.props;
      const productId = product.id;
      this.setState({ [inputTooltip]: true });
      productCollectionElementItem.loadStocks(productId);
      return;
    }

    this.setState({ [inputTooltip]: true });
  };

  hideTooltip = (productCollectionElementItem) => {
    const inputTooltip = `${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}--tooltip`;
    this.setState({ [inputTooltip]: false });
  };

  quantityError = (
    productCollectionElementItem,
    productCollectionColumnElementId
  ) => {
    const quantity =
      this.state[
        `qty--${productCollectionElementItem.row_id}--${productCollectionColumnElementId}--error`
      ];

    const formError = quantity && quantity.error;

    if (!formError) {
      return null;
    }

    return (
      <FormFeedback className="ProductCollectionMatrix__invalid-feedback">
        {formError}
      </FormFeedback>
    );
  };

  renderAddSelectedProductsButton = (className) => {
    const { cartStore } = this.props;
    const loading = cartStore.state === RequestState.LOADING;
    return (
      <Button
        className={className}
        onClick={this.addSelectedProducts}
        color="primary"
        disabled={this.state.formError || loading}
        block
      >
        {loading ? (
          <Icon name="circle-o-notch" spin={loading} />
        ) : (
          <FormattedMessage
            id="productCollectionMatrix.addSelectedProducts"
            defaultMessage="Add Selected Products"
          />
        )}
      </Button>
    );
  };

  render() {
    const { accountStore, uiStore } = this.props;
    const size = accountStore.isViewOnly ? 12 : { size: 8, offset: 2 };
    const productRows = this.getCollectionRowElements();
    const styles = {
      tableLayout:
        !uiStore.isMobile && productRows.length >= 4 ? 'fixed' : 'unset',
    };
    return (
      <div className="ProductCollectionMatrix">
        <Row className="ProductCollectionMatrix__info-row">
          {!accountStore.isViewOnly && (
            <CustomCol md={3} xxl={2}>
              {this.renderAddSelectedProductsButton(
                'ProductCollectionMatrix__info-row--add-selected-products-button'
              )}
            </CustomCol>
          )}
          <CustomCol md={{ size: accountStore.isViewOnly ? 12 : 9 }} xxl={size}>
            <p className="ProductCollectionMatrix__product-matrix-info">
              <FormattedMessage {...globalTranslations.productMatrixInfo} />
            </p>
          </CustomCol>
        </Row>
        <Row>{this.renderProductMatrixTitles()}</Row>
        <Row>
          <Col xs={12}>
            <Table
              striped
              className="ProductCollectionMatrix__product-table"
              style={styles}
            >
              {!uiStore.isMobile && this.renderProductElementRows()}
              {this.renderProductColumns()}
            </Table>
          </Col>
        </Row>
        {!accountStore.isViewOnly && (
          <Row className="ProductCollectionMatrix__info-row-footer">
            <CustomCol
              md={{ size: 3, offset: 9 }}
              xxl={{ size: 2, offset: 10 }}
            >
              {this.renderAddSelectedProductsButton(
                'ProductCollectionMatrix__info-row-footer--add-selected-products-button'
              )}
            </CustomCol>
          </Row>
        )}
        {this.getImageLightbox()}
      </div>
    );
  }
}

ProductCollectionMatrix.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  product: modelOf(FullProduct).isRequired,
  productMatrix: modelOf(FullProductMatrix).isRequired,
};

export default injectIntl(
  inject(
    'analytics',
    'accountStore',
    'cartStore',
    'configStore',
    'currencyStore',
    'uiStore'
  )(ProductCollectionMatrix)
);
