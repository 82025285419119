import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router';
import RouterPropTypes from 'react-router-prop-types';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';

/**
 * This component deletes product data overflow
 */
@observer
export class StoreGarbageCollector extends Component {
  unlisten = null;

  componentDidMount() {
    const { history, productStore } = this.props;
    this.unlisten = history.listen((location, action) => {
      if (action !== 'REPLACE') {
        productStore.gcProductQueries();
        productStore.gcProducts();
        productStore.gcFullProducts();
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    return null;
  }
}

StoreGarbageCollector.propTypes = {
  productStore: modelOf(ProductStore).isRequired,
  history: RouterPropTypes.history.isRequired,
};

export default withRouter(inject('productStore')(StoreGarbageCollector));
