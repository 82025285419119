import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import Product from '../../../models/Product';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import ButtonLink from '../../common/ButtonLink';
import WysiwygContent from '../../common/WysiwygContent';
import FullProduct from '../../../models/product/FullProduct';

const ProductDescription = ({
  configStore,
  className,
  isProductPage,
  isSuitability,
  overflowHandler,
  product,
  productListView,
}) => {
  const READ_MORE_ACTIVATION_LENGTH = 170;

  const getSeoShortDescription = () => {
    if (!hasSeoShortDescription()) {
      return null;
    }

    const hasMoreContent =
      product.seoDescription.length >= READ_MORE_ACTIVATION_LENGTH;
    const description = hasMoreContent
      ? product.seoDescription + '... '
      : product.seoDescription;

    return (
      <>
        {description}
        {hasMoreContent && isProductPage && (
          <ButtonLink
            className="ProductPageContent__read-more-description-link"
            onClick={() => overflowHandler()}
          >
            <FormattedMessage {...globalTranslations.readMoreSentence} />
          </ButtonLink>
        )}
      </>
    );
  };

  const getShortDescription = () => {
    return !!product.description_short ? (
      <WysiwygContent html={product.description_short} />
    ) : null;
  };

  const getHtmlDescription = () => {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: product.suitability_description }}
      />
    );
  };

  const hasSeoShortDescription = () => {
    return !!product.seoDescription && product.seoDescription.length > 0;
  };

  const hasShortDescription = () => {
    return !!product.description_short && product.description_short.length > 0;
  };

  const getDescriptionContent = () => {
    if (isSuitability) {
      return getHtmlDescription();
    }

    if (
      productListView &&
      !configStore.product.useSeoDescriptionForShort &&
      !hasShortDescription()
    ) {
      return null;
    }

    return productListView ||
      (configStore.product.useSeoDescriptionForShort && !hasShortDescription())
      ? getSeoShortDescription()
      : getShortDescription();
  };

  const content = getDescriptionContent();

  if (!content) {
    return null;
  }

  return (
    <div className={classNames('ProductDescription', className)}>{content}</div>
  );
};

ProductDescription.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.oneOfType([
    modelOf(Product).isRequired,
    modelOf(FullProduct).isRequired,
  ]),
  overflowHandler: PropTypes.func,
  className: PropTypes.string,
  isProductPage: PropTypes.bool,
  isSuitability: PropTypes.bool,
  productListView: PropTypes.bool,
};

ProductDescription.defaultProps = {
  isProductPage: false,
  isSuitability: false,
};

export default inject('configStore')(observer(ProductDescription));
