import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import globalTranslations from '../../../i18n/globalTranslations';
import CanonicalLink from '../CanonicalLink';
import Paths from '../../../types/Paths';
import SEOTitle from '../SEOTitle';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import SEODescription from '../SEODescription';
import RouteService from '../../../services/RouteService';

@observer
export class SEOHomePage extends Component {
  render() {
    const { configStore, routeService } = this.props;
    return (
      <>
        <SEOTitle
          title={
            configStore.configurationTexts.CONF_SEO_TITLE ||
            globalTranslations.homeTitle
          }
          noSuffix={configStore.configurationTexts.CONF_SEO_TITLE !== ''}
        />
        {configStore.configurationTexts.CONF_SEO_DESCRIPTION && (
          <SEODescription
            content={configStore.configurationTexts.CONF_SEO_DESCRIPTION}
          />
        )}
        <CanonicalLink path={routeService.getPath(Paths.FrontPage)} />
      </>
    );
  }
}

SEOHomePage.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject('configStore', 'routeService')(SEOHomePage);
