import { useCallback } from 'react';

import RequestState from '../../types/RequestState';

const useProductPage = ({ productId }) => {
  /**
   *
   * @param {Object} props
   * @param {ProductStore} props.productStore
   * @param {Object} props.fetchParams
   * @param {Object} props.fetchParams.id
   * @param {Object} props.fetchParams.urlParams
   * @param {String} props.fetchParams.state
   */
  const fetchProductData = useCallback(
    ({ productStore, fetchParams }) => {
      // The default value for state is not set
      if (!fetchParams.state || fetchParams.state === RequestState.NONE) {
        productStore
          .loadProduct(fetchParams.id, fetchParams.urlParams)
          .catch((e) => {
            // Don't make error out of 404 product api response,
            // so sentry won't pick it up.
            if (e.response && e.response.status !== 404) {
              console.error(e);
            }
          });
      }
    },
    [productId]
  );

  return [fetchProductData];
};

export default useProductPage;
