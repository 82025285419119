import { PropTypes as MobxPropTypes, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import Language from '../../../models/Language';
import { modelOf } from '../../../prop-types';
import { countryCodeFromLocale } from '../../../util/languageMapper';
import DropdownSelect from '../DropdownSelect';
import LocaleFlagIcon from '../LocaleFlagIcon';

const RegionalSettingsLanguageDropdown = ({
  language,
  languages,
  onSelect,
}) => {
  return (
    <DropdownSelect
      label={
        <FormattedMessage
          {...globalTranslations.languageLabel}
          values={{
            language: (
              <span>
                <LocaleFlagIcon locale={countryCodeFromLocale(language.code)} />{' '}
                {language.name}
              </span>
            ),
          }}
        />
      }
    >
      {languages.map((language) => (
        <DropdownItem onClick={() => onSelect(language)} key={language.code}>
          <span>
            <LocaleFlagIcon locale={countryCodeFromLocale(language.code)} />{' '}
            {language.name}
          </span>
        </DropdownItem>
      ))}
    </DropdownSelect>
  );
};

RegionalSettingsLanguageDropdown.propTypes = {
  language: modelOf(Language).isRequired,
  languages: MobxPropTypes.observableArrayOf(modelOf(Language)).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default observer(RegionalSettingsLanguageDropdown);
