import { isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import globalTranslations from '../../../i18n/globalTranslations';
import ProductPriceInfo from '../../../models/ProductPriceInfo';
import QuantityDiscounts from '../../../models/QuantityDiscounts';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import QuantityDiscountDisplayStyles from '../../../types/QuantityDiscountDisplayStyles';
import DiscountSign from '../DiscountSign';
import Price from '../Price';
import ProductSingularPrice from '../ProductSingularPrice';
import QuantityBasedPrice from '../QuantityBasedPrice';

const ProductPagePrice = ({
  configStore,
  className,
  product,
  price,
  secondaryPrice,
  quantityDiscounts,
  withTax,
  withDiscountLabel,
  withDiscountUpTo,
  withDiscountBalloon,
  withDiscountSavings,
  discountInverted,
  isMulti,
  isSecondaryPrice,
  onOverridePrice,
}) => {
  const quantityDiscountDisplayStyle =
    configStore.product.productQuantityDiscountDisplayStyle;

  const { savings, discountAmount, hasDiscount, hasPriceRange } =
    product.getDiscountInfo(withTax, quantityDiscountDisplayStyle);

  const showDiscountBalloon = withDiscountBalloon && !hasPriceRange;
  const showDiscountSavings = withDiscountSavings && !hasPriceRange && savings;

  const renderDiscountDescription = () => {
    const discountPercentage = Math.round(
      product.price_info.discount_percentage
    );

    if (discountPercentage >= 40) {
      return (
        <FormattedMessage
          id="product.topDiscount"
          defaultMessage="Top discount"
        />
      );
    } else if (discountPercentage >= 30) {
      return (
        <FormattedMessage
          id="product.superOffer"
          defaultMessage="Super offer"
        />
      );
    } else {
      return (
        <FormattedMessage id="product.nowOnly" defaultMessage="Now only" />
      );
    }
  };

  const renderDiscountSign = () => {
    return (
      <DiscountSign
        percentage={discountAmount}
        withLabel={withDiscountLabel}
        withUpTo={withDiscountUpTo || hasPriceRange}
        inverted={discountInverted}
        className={className}
      />
    );
  };

  const renderQuantityBasedPrice = () => {
    return (
      <QuantityBasedPrice
        normalPrice={price}
        defaultPrice={price}
        className={'ProductPrice'}
        quantityDiscounts={quantityDiscounts}
        stockUnit={product.stock_unit}
        withTax={withTax}
        withDiscount={!isSecondaryPrice && hasDiscount}
      />
    );
  };

  const renderPrice = () => {
    const ifRenderPrice =
      isMulti ||
      !quantityDiscounts ||
      quantityDiscountDisplayStyle ===
        QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST;

    const ifRenderQuantityBasedPrice =
      !isMulti &&
      quantityDiscounts &&
      quantityDiscountDisplayStyle !==
        QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST;

    const ifRenderDiscount = hasDiscount && !isSecondaryPrice;

    if (onOverridePrice) {
      return onOverridePrice({
        ifRenderPrice,
        quantityBasedPrice: ifRenderQuantityBasedPrice
          ? renderQuantityBasedPrice()
          : null,
        discountSign: ifRenderDiscount ? renderDiscountSign() : null,
      });
    }

    return (
      <div
        className={classNames('ProductPagePrice__price-container', {
          'ProductPagePrice__quantity-based-price-container':
            ifRenderQuantityBasedPrice,
        })}
      >
        {ifRenderPrice && price}
        {ifRenderQuantityBasedPrice && renderQuantityBasedPrice()}
        {ifRenderDiscount && renderDiscountSign()}
      </div>
    );
  };

  return (
    <div className="ProductPagePrice">
      {hasDiscount && showDiscountBalloon && (
        <div className="ProductPagePrice__balloon">
          <FormattedMessage {...globalTranslations.productOffer} />
        </div>
      )}
      {hasDiscount && showDiscountSavings && (
        <div className="ProductPagePrice__savings">
          {renderDiscountDescription()}
          <span className="ProductPagePrice__savings-amount">
            <FormattedMessage
              {...globalTranslations.youSaveSentence}
              values={{
                savings: <Price price={savings} />,
              }}
            />
          </span>
        </div>
      )}
      {renderPrice()}
      <ProductSingularPrice product={product} withTax={withTax} />
      {product.price_info &&
        product.price_info.valid_until_html &&
        !isSecondaryPrice && (
          <div
            className="ProductPagePrice__valid-until"
            dangerouslySetInnerHTML={{
              __html: product.price_info.valid_until_html,
            }}
          />
        )}
      {secondaryPrice}
      {!isMulti &&
        quantityDiscounts &&
        quantityDiscountDisplayStyle ===
          QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST && (
          <QuantityBasedPrice
            className={'ProductPagePrice__quantity-based-price'}
            quantityDiscounts={quantityDiscounts}
            stockUnit={product.stock_unit}
            withTax={withTax}
            withDiscount={hasDiscount}
          />
        )}
    </div>
  );
};

ProductPagePrice.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.shape({
    class: PropTypes.string,
    price_info: modelOf(ProductPriceInfo),
    package_size: PropTypes.number,
    stock_unit: PropTypes.string,
    singular_price_unit: PropTypes.string,
    singular_price_divider: PropTypes.number,
  }).isRequired,
  withTax: PropTypes.bool.isRequired,
  price: PropTypes.node.isRequired,
  quantityDiscounts: modelOf(QuantityDiscounts),
  className: PropTypes.string,
  discountInverted: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSecondaryPrice: PropTypes.bool,
  withDiscountBalloon: PropTypes.bool,
  withDiscountLabel: PropTypes.bool,
  withDiscountSavings: PropTypes.bool,
  withDiscountUpTo: PropTypes.bool,
  onOverridePrice: PropTypes.func,
  secondaryPrice: PropTypes.node,
};

export default inject((stores, props) => ({
  configStore: stores.configStore,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(observer(ProductPagePrice));
