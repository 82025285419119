import classNames from 'classnames';
import { isEqual } from 'lodash';
import { inject, PropTypes as MobxPropTypes, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import StorageStore from '../../../store/StorageStore';
import QuantityDiscountDisplayStyles from '../../../types/QuantityDiscountDisplayStyles';
import RequestState from '../../../types/RequestState';
import Icon from '../../common/Icon';
import Discount from '../Discount';
import Price from '../Price';
import PriceSecondary from '../PriceSecondary';
import ProductAvailabilityList from '../ProductAvailabilityList';
import ProductCode from '../ProductCode';
import ProductCollectionPropertySelect from '../ProductCollectionPropertySelect';
import ProductExtraInfo from '../ProductExtraInfo';
import ProductImage from '../ProductImage';
import ProductMultiProductPickerPropertySelect from '../ProductMultiProductPickerPropertySelect';
import ProductPagePrice from '../ProductPagePrice';
import ProductPrice from '../ProductPrice';
import FullProduct from '../../../models/product/FullProduct';

@observer
export class ProductMultiProductPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePropertyElementMap: this.getInitialPropertyMap(),
    };
  }

  componentDidMount() {
    this.setSingleElementProperties();
    if (this.showAsCards()) {
      const { storageStore, product } = this.props;
      const siteUrl =
        product &&
        product.merchant_info &&
        product.merchant_info.site_url &&
        product.merchant_info.site_url;
      storageStore.makeSureStoragesLoaded(siteUrl);
      this.loadAllStocks();
    }
  }

  getInitialPropertyMap = () => {
    const { activeProductId, product } = this.props;

    if (activeProductId) {
      const childProduct = product.multi.findChild(activeProductId);
      if (childProduct) {
        return ProductMultiProductPicker.getPropertyElementMapForProduct(
          childProduct
        );
      }
    }

    return {};
  };

  static getPropertyElementMapForProduct = (product) => {
    return product.extra_properties.reduce(
      (propertyElementMap, extraProperty) => {
        propertyElementMap[extraProperty.id] = extraProperty.value_id;
        return propertyElementMap;
      },
      {}
    );
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.product, this.props.product)) {
      if (this.showAsCards()) {
        this.loadAllStocks();
      }

      this.setSingleElementProperties();
    }
  }

  setSingleElementProperties = () => {
    const { product } = this.props;
    const initialProperties = [];

    product.multi.properties.forEach((property) => {
      if (property.elements.length === 1) {
        if (property.elements[0].id) {
          initialProperties.push({
            [property.id]: property.elements[0].id,
          });
        }
      }
    });

    this.updatePropertyState(initialProperties);
  };

  updatePropertyState = (properties) => {
    const { activePropertyElementMap } = this.state;

    let updatedPropertyElementMap = { ...activePropertyElementMap };

    properties.forEach((property) => {
      updatedPropertyElementMap = {
        ...updatedPropertyElementMap,
        ...property,
      };
    });

    this.setState(() => {
      this.updateActiveProductId(updatedPropertyElementMap);

      return {
        activePropertyElementMap: updatedPropertyElementMap,
      };
    });
  };

  loadAllStocks = () => {
    const { product } = this.props;
    product.multi.children.forEach((childProduct) => {
      if (product.stockStates.get(childProduct.id) !== RequestState.LOADED) {
        product.loadStocks(childProduct.id);
      }
    });
  };

  getCardPrice = (childProduct) => {
    const { accountStore, configStore } = this.props;

    if (!childProduct.price_info) {
      return null;
    }

    const isDiscount = childProduct.price_info.is_discount;
    const discount = isDiscount &&
      childProduct.price_info.discount_percentage && (
        <Discount percentage={childProduct.price_info.discount_percentage} />
      );
    const validUntil = childProduct.price_info.valid_until_html && (
      <div
        className="ProductMultiProductPicker__card-valid-until"
        dangerouslySetInnerHTML={{
          __html: childProduct.price_info.valid_until_html,
        }}
      />
    );

    const withTax = this.props.accountStore.showPricesWithTax;
    const discountDescription = isDiscount && (
      <div className="ProductMultiProductPicker__card-savings-amount">
        <FormattedMessage id="product.offer" defaultMessage="Offer!" />{' '}
        <FormattedMessage
          {...globalTranslations.youSaveSentence}
          values={{
            savings: (
              <Price price={childProduct.price_info.getSavings(withTax)} />
            ),
          }}
        />
      </div>
    );

    return (
      <div className="ProductMultiProductPicker__card-price-wrapper">
        <div className="ProductMultiProductPicker__card-price">
          <ProductPrice
            hideDiscount
            product={childProduct}
            priceInfo={childProduct.price_info}
          />
          {discount}
          {(configStore.product.showSecondaryTaxPrice ||
            accountStore.isRetailer) && (
            <PriceSecondary product={childProduct} isMultiProductPicker />
          )}
        </div>
        <div className="ProductMultiProductPicker__card-discount">
          <ProductPrice
            hidePrice
            hideTaxExcludedInfo
            product={childProduct}
            priceInfo={childProduct.price_info}
          />
          {discountDescription}
        </div>
        {validUntil}
      </div>
    );
  };

  renderChildProductId = (childProduct) => {
    const { configStore } = this.props;
    const showProductCode = configStore.productPage.showProductCode;
    const showAdditionalProductCode =
      configStore.productPage.showAdditionalProductCode;

    if (!showProductCode || !showAdditionalProductCode) {
      return null;
    }

    return (
      <div className="ProductMultiProductPicker__additional-product-id">
        <ProductCode code={childProduct.productCode} />
      </div>
    );
  };

  getCardSelect = () => {
    const {
      product,
      activeProductId,
      onProductChange,
      scrollToTab,
      accountStore,
      configStore,
    } = this.props;

    const isViewOnly = accountStore.isViewOnly;
    const chooseMessage = (
      <FormattedMessage {...globalTranslations.chooseTitle} />
    );

    const multiValueProperties = this.getPropertiesWithMultipleSelections();
    const activeProperties =
      multiValueProperties.length === 1
        ? multiValueProperties
        : product.multi.properties;

    const unorderedCards = product.multi.children.map((childProduct) => {
      const effectiveOrder = [];
      const headerText = activeProperties
        .map((property) => {
          const childProperty = childProduct.extra_properties.find(
            (extraProperty) => extraProperty.id === property.id
          );

          if (!childProperty) {
            effectiveOrder.push(null);
            return null;
          }

          const sortValue = property.elements.findIndex(
            (element) => element.id === childProperty.value_id
          );
          effectiveOrder.push(sortValue > -1 ? sortValue : null);

          return childProperty && childProperty.value_name;
        })
        .filter((name) => !!name)
        .join(', ');
      const isActive = childProduct.id === activeProductId;
      const isAvailable = childProduct.free_quantity > 0;
      const onClick = () => onProductChange(childProduct.id);
      const childProductImage = childProduct.images.find(
        (image) => image.product_id === childProduct.id
      );

      return {
        order: effectiveOrder,
        component: (
          <div
            key={childProduct.id}
            className={classNames('ProductMultiProductPicker__card clearfix', {
              'ProductMultiProductPicker__card--extra-padding':
                !configStore.product.hideMultiProductSelectionImage,
              'ProductMultiProductPicker__card--active': isActive,
              'ProductMultiProductPicker__card--active-glow':
                !configStore.product.hideMultiProductSelectionImage && isActive,
            })}
            onClick={onClick}
          >
            <div className="ProductMultiProductPicker__selector-wrapper">
              {!configStore.product.hideMultiProductSelectionImage &&
                childProductImage && (
                  <FormGroup
                    className="ProductMultiProductPicker__card-image"
                    onClick={onClick}
                  >
                    <ProductImage
                      product={childProduct}
                      productImage={childProductImage}
                      size={'small'}
                      forceAspectRatio={false}
                      lazyLoading={false}
                    />
                  </FormGroup>
                )}
              <FormGroup
                check
                className="ProductMultiProductPicker__card-checkbox"
                disabled={!isAvailable}
              >
                <Label check>
                  <input
                    type="radio"
                    checked={isActive}
                    onClick={onClick}
                    readOnly
                  />
                  <span className="sr-only">
                    {chooseMessage} {headerText}
                  </span>
                </Label>
              </FormGroup>
            </div>
            {!isViewOnly && this.getCardPrice(childProduct)}
            <div className="ProductMultiProductPicker__card-title">
              {headerText}
            </div>
            {this.renderChildProductId(childProduct)}
            {!isViewOnly && (
              <ProductAvailabilityList
                scrollToTab={scrollToTab}
                product={product}
                activeProduct={childProduct}
                onBeforeScroll={onClick}
                shipping={false}
              />
            )}
            {isActive && <ProductExtraInfo product={childProduct} />}
            {isActive && !isViewOnly && (
              <ProductAvailabilityList
                scrollToTab={scrollToTab}
                product={product}
                activeProduct={childProduct}
                shipping={isAvailable}
                onBeforeScroll={onClick}
                onlineAvailability={false}
                storeAvailability={false}
              />
            )}
          </div>
        ),
      };
    });

    const cards = unorderedCards
      .sort((a, b) => {
        for (let i = 0; i < Math.max(a.order.length, b.order.length); ++i) {
          if (a.order[i] === null) {
            return -1;
          }
          if (b.order[i] === null) {
            return 1;
          }
          if (a.order[i] !== b.order[i]) {
            return a.order[i] < b.order[i] ? -1 : 1;
          }
        }
        return 0;
      })
      .map((card) => card.component);

    const labelText = activeProperties
      .map((property) => property.name)
      .filter((name) => !!name)
      .join(', ');

    return (
      <div className="ProductMultiProductPicker__card-select">
        <div className="ProductMultiProductPicker__label">
          <Icon name="long-arrow-down" /> {chooseMessage} {labelText}
        </div>
        {cards}
      </div>
    );
  };

  getDropdowns = () => {
    const {
      product,
      activeProductId,
      scrollToTab,
      accountStore,
      onProductChange,
    } = this.props;
    const isViewOnly = accountStore.isViewOnly;
    const activeChildProduct = product.multi.findChild(activeProductId);
    const activeProduct = activeChildProduct ? activeChildProduct : product;

    return (
      <div className="ProductMultiProductPicker__dropdowns">
        <ProductMultiProductPickerPropertySelect
          activeProductId={activeProductId}
          product={product}
          productImages={product.images}
          onProductChange={(productId, extraPropertiesMap) => {
            this.setState({ activePropertyElementMap: extraPropertiesMap });
            onProductChange(productId);
          }}
          hidden={product.multi.properties.every(
            (property) => property.elements.length <= 1
          )}
        />
        {this.renderDropdowns()}
        {this.renderPrice()}
        {activeProduct && (
          <>
            {activeChildProduct && (
              <ProductExtraInfo
                product={activeProduct}
                ignoreAdditionalProductCode={true}
              />
            )}
            {this.renderProductIsNotAvailable()}
            {!isViewOnly && activeChildProduct && (
              <ProductAvailabilityList
                scrollToTab={scrollToTab}
                product={product}
                activeProduct={activeChildProduct}
                shipping
              />
            )}
          </>
        )}
      </div>
    );
  };

  renderDropdowns = () => {
    const { product, activeProductId, onProductChange } = this.props;
    const { activePropertyElementMap } = this.state;

    return product.multi.properties.map((property) => (
      <ProductCollectionPropertySelect
        key={property.id}
        activeProductId={activeProductId}
        product={product}
        productImages={product.images}
        property={property}
        elementsWithAvailability={this.getPropertyElementsWithAvailability(
          property
        )}
        onPropertySelect={(element) => {
          const elementId = element && element.id;
          this.onPropertySelect(property.id, elementId);
        }}
        onProductChange={onProductChange}
        activeElementId={activePropertyElementMap[property.id]}
        {...this.getDisplayFlagsForProperty()}
        hidden={property.elements.length <= 1}
        disabled={property.elements.length <= 1}
      />
    ));
  };

  getPropertyElementsWithAvailability = (property) => {
    const { product } = this.props;
    return property.elements.reduce((elementsWithAvailability, element) => {
      const propertyElementMap = {
        ...this.state.activePropertyElementMap,
        [property.id]: element.id,
      };
      const status = product.multi.getPropertyElementStatus(propertyElementMap);
      elementsWithAvailability.push({
        element,
        available: status.available,
        disabled: !status.exists,
      });
      return elementsWithAvailability;
    }, []);
  };

  onPropertySelect = (propertyId, elementId) => {
    this.setState((state) => {
      const { activePropertyElementMap } = state;
      const updatedPropertyElementMap = {
        ...activePropertyElementMap,
        [propertyId]: elementId,
      };

      if (!elementId) {
        delete updatedPropertyElementMap[propertyId];
      }

      this.updateActiveProductId(updatedPropertyElementMap);
      return {
        activePropertyElementMap: updatedPropertyElementMap,
      };
    });
  };

  updateActiveProductId = (activePropertyElementMap) => {
    const { product, onProductChange } = this.props;
    let productId = null;

    if (
      Object.keys(activePropertyElementMap).length ===
      product.multi.properties.length
    ) {
      const childProduct = product.multi.findChildWithProperties(
        activePropertyElementMap
      );

      if (childProduct) {
        productId = childProduct.id;
      }
    }

    onProductChange(productId);
  };

  getDisplayFlagsForProperty = () => {
    const { configStore } = this.props;

    return {
      // TODO: Add a configuration for using buttons
      //showButtons: !isColor,
      showImages: !configStore.product.hideMultiProductSelectionImage,
      showDropdown: true,
      multiproductAsDropdown: configStore.product.multiproductAsDropdown,
    };
  };

  renderPrice = () => {
    const { accountStore, configStore, activeProductId, product } = this.props;
    const withTax = accountStore.showPricesWithTax;
    const { hasPriceRange } = product.getDiscountInfo(withTax);
    const activeChildProduct = product.multi.findChild(activeProductId);
    const quantityDiscounts = activeChildProduct?.getQuantityDiscounts();
    const quantityBasedDiscountDisplayStyle =
      configStore.product.productQuantityDiscountDisplayStyle;

    if (
      !activeChildProduct ||
      !activeChildProduct.price_info ||
      (!hasPriceRange &&
        (!quantityDiscounts.hasDiscounts ||
          quantityBasedDiscountDisplayStyle ===
            QuantityDiscountDisplayStyles.NONE))
    ) {
      return null;
    }

    return (
      <div className="ProductMultiProductPicker__dropdowns-price">
        <ProductPagePrice
          price={
            <ProductPrice
              product={activeChildProduct}
              priceInfo={activeChildProduct.price_info}
            />
          }
          product={activeChildProduct}
          withDiscountBalloon
          withDiscountSavings
          quantityDiscounts={quantityDiscounts.discounts}
        />
      </div>
    );
  };

  renderProductIsNotAvailable = () => {
    const { product } = this.props;
    const { activePropertyElementMap } = this.state;

    const status = product.multi.getPropertyElementStatus(
      activePropertyElementMap
    );

    if (status.exists) {
      return null;
    }

    return (
      <FormattedMessage {...globalTranslations.productIsNotAvailableTitle} />
    );
  };

  showAsCards = () => {
    const { configStore, product } = this.props;
    const multiSelectProperties = this.getPropertiesWithMultipleSelections();
    return (
      !configStore.product.multiproductAsDropdown &&
      (product.multi.properties.length === 1 ||
        multiSelectProperties.length === 1)
    );
  };

  getPropertiesWithMultipleSelections = () => {
    const { product } = this.props;
    return product.multi.properties.filter((property) => {
      return property.elements.length > 1;
    });
  };

  render() {
    const { configStore, product } = this.props;

    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const ifBuyingAllowed =
      product.merchantInfo && product.merchantInfo.buyingAllowed;

    if (ifShoppingCenter && !ifBuyingAllowed) {
      return null;
    }

    const productPickerContent = this.showAsCards()
      ? this.getCardSelect()
      : this.getDropdowns();

    return (
      <div className="ProductMultiProductPicker">{productPickerContent}</div>
    );
  }
}

ProductMultiProductPicker.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  storageStore: modelOf(StorageStore).isRequired,
  onProductChange: PropTypes.func.isRequired,
  product: modelOf(FullProduct).isRequired,
  productImages: MobxPropTypes.observableArray.isRequired,
  scrollToTab: PropTypes.func.isRequired,
  activeProductId: PropTypes.string,
  path: PropTypes.string,
};

export default inject(
  'accountStore',
  'configStore',
  'storageStore'
)(ProductMultiProductPicker);
