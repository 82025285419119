import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import CountryStore from '../../../store/CountryStore';
import CurrencyStore from '../../../store/CurrencyStore';
import LanguageStore from '../../../store/LanguageStore';
import { countryCodeFromLocale } from '../../../util/languageMapper';
import Icon from '../../common/Icon';
import LocaleFlagIcon from '../../common/LocaleFlagIcon';
import RegionalSettings from '../../common/RegionalSettings';
import RegionalSettingsDropdowns from '../../common/RegionalSettingsDropdowns';
import NavigationDropdownDrawer from '../NavigationDropdownDrawer';
import NavigationDropdownDrawerButton from '../NavigationDropdownDrawerButton';

const NavigationRegionalSettingsMenu = ({
  languageStore,
  currencyStore,
  countryStore,
}) => {
  const showCountries = countryStore.countries.length > 1;
  const showLanguages = languageStore.languages.length > 1;
  const showCurrencies = currencyStore.currencies.length > 1;

  const labels = [];
  if (showCountries) {
    labels.push(countryStore.activeCountry.name);
  }
  if (showLanguages) {
    labels.push(languageStore.activeLanguage.name);
  }
  if (showCurrencies) {
    labels.push(currencyStore.currencyCode);
  }

  if (labels.length === 0) {
    return null;
  }

  const labelIcon = showCountries ? (
    <LocaleFlagIcon
      locale={countryStore.activeCountry.iso_code_2.toLowerCase()}
    />
  ) : (
    <LocaleFlagIcon
      locale={countryCodeFromLocale(languageStore.activeLanguage.code)}
    />
  );

  return (
    <RegionalSettings>
      {(save, onChange, currentValues, isSaving) => (
        <NavigationDropdownDrawer
          label={
            <span className="NavigationRegionalSettingsMenu__dropdown-current-label">
              {labelIcon}
              {labels.join(' / ')}
            </span>
          }
          className="NavigationRegionalSettingsMenu"
          buttons={[
            NavigationDropdownDrawerButton({
              label: (
                <span>
                  <Icon
                    name={isSaving ? 'circle-o-notch' : 'check'}
                    spin={isSaving}
                  />{' '}
                  <FormattedMessage {...globalTranslations.saveTitle} />
                </span>
              ),
              onClick: (context) => {
                save().then(context.close);
              },
              key: 'submit',
              color: 'primary',
              disabled: isSaving,
            }),
          ]}
          right
        >
          <div className="NavigationRegionalSettingsMenu__selectors">
            <RegionalSettingsDropdowns {...currentValues} onChange={onChange} />
          </div>
        </NavigationDropdownDrawer>
      )}
    </RegionalSettings>
  );
};

NavigationRegionalSettingsMenu.propTypes = {
  languageStore: modelOf(LanguageStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
};

export default inject(
  'languageStore',
  'currencyStore',
  'countryStore'
)(observer(NavigationRegionalSettingsMenu));
