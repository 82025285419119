import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { injectIntl, intlShape } from 'react-intl';

import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import { roundWithPrecision } from '../../../util/number';
import Icon from '../../common/Icon';
import { DEFAULT_MAX_QUANTITY } from '../ProductAddToCart';

const createCartProduct = (id, qty) => {
  return { id, qty };
};

const ProductMatrixCell = ({
  accountStore,
  configStore,
  uiStore,
  intl,
  cellCount,
  onHandleProductQuantity,
  onRenderTooltip,
  className,
  product,
  propertyListRef,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [quantity, setQuantity] = useState('');

  let inputRef = useRef(null);

  useEffect(() => {
    if (propertyListRef && propertyListRef.current) {
      if (isOpen) {
        propertyListRef.current.addEventListener('scroll', hideTooltip);
      }
      if (!isOpen) {
        propertyListRef.current.removeEventListener('scroll', hideTooltip);
      }
    }
    return () => {
      if (propertyListRef && propertyListRef.current) {
        propertyListRef.current.removeEventListener('scroll', hideTooltip);
      }
    };
  }, [propertyListRef, isOpen]);

  const openPopover = () => setIsOpen(true);

  const closePopover = () => setIsOpen(false);

  const hideTooltip = (event) => {
    if (inputRef?.current && event.type === 'scroll') {
      // Let onBlur-callback handle state update.
      inputRef.current.blur();
    }
  };

  const handleProductQuantity = (e) => {
    let maxQuantity = getMaxQuantity();

    // Check if package is sold in centimeters.
    maxQuantity = product.countQuantityWithPackageSizeDecimals(maxQuantity);

    const newQuantity = e.target.value
      ? getQuantity(Number(e.target.value), maxQuantity)
      : '';

    onHandleProductQuantity(createCartProduct(product.id, newQuantity));

    // Empty string because console throws a warning about controlled inputs that
    // cannot be null.
    // For future improvement, parent component selectedProducts should provide
    // the quantity via prop.
    setQuantity(newQuantity);
  };

  const getQuantity = (qty, maxQuantity) => {
    const freeQuantity = product.getFreeQuantity(product.id);
    const cannotBeOrdered =
      freeQuantity <= 0 && !product.canBeOrderedOutOfStock && qty < 0;
    const canBeOrdered = freeQuantity > 0 && qty > 0 && qty <= maxQuantity;
    const exceedsMaxQuantity = qty >= maxQuantity;
    const nonNegativeQuantity = qty > 0 && qty;

    if (cannotBeOrdered) {
      return 0;
    }

    if (canBeOrdered) {
      return nonNegativeQuantity;
    }

    return exceedsMaxQuantity ? maxQuantity : nonNegativeQuantity;
  };

  const getMaxByPackage = (product) =>
    product.sellInPackage
      ? Math.floor(product.getFreeQuantity(product.id) / product.package_size) *
        product.package_size
      : product.getFreeQuantity(product.id);

  const getMaxQuantity = () => {
    const maxAccountQuantity =
      accountStore.account && accountStore.account.max_product_quantity > 0
        ? accountStore.account.max_product_quantity
        : DEFAULT_MAX_QUANTITY;

    const maxBackorderQuantity =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
        ? configStore.product.backorderLimit > 0
          ? configStore.product.backorderLimit
          : DEFAULT_MAX_QUANTITY
        : DEFAULT_MAX_QUANTITY;

    const maxNormalProductQuantity =
      product.availability_type !== ProductAvailabilityType.ALLOW_BACKORDER
        ? product.canBeOrderedOutOfStock
          ? DEFAULT_MAX_QUANTITY
          : getMaxByPackage(product)
        : DEFAULT_MAX_QUANTITY;

    return Math.min(
      maxAccountQuantity,
      maxBackorderQuantity,
      maxNormalProductQuantity,
      DEFAULT_MAX_QUANTITY
    );
  };

  const getUnavailableProduct = () => (
    <div
      key={uniqueId('Cell-')}
      className="ProductMatrixCell__unavailable-product"
    >
      <div className="ProductMatrixCell__line" />
    </div>
  );

  const getCellStyle = () => {
    let width = 100;

    if ((cellCount > 5 && !uiStore.isDesktop) || cellCount > 10) {
      width = 78;
    }

    return {
      width,
    };
  };

  const getNoProduct = () => {
    return (
      <div
        key={uniqueId('Cell-')}
        className="ProductMatrixCell__no-product"
        style={getCellStyle()}
      >
        <Icon name="ban" />
      </div>
    );
  };

  const renderPackageInput = () => {
    const getPackageSizeSum = () => {
      const sum = roundWithPrecision(quantity * product.package_size, 3);

      return (
        <div className="ProductMatrixCell__package-size-sum">
          ( {sum}
          <span className="ProductMatrixCell__package-stock-unit">
            {product.stock_unit}
          </span>
          )
        </div>
      );
    };

    return (
      <div className="ProductMatrixCell__package-input">
        <Input
          id={`ProductInfo-${product.id}`}
          className="ProductMatrixCell__input"
          type="number"
          name={product.id}
          value={quantity}
          onFocus={openPopover}
          onBlur={closePopover}
          onChange={handleProductQuantity}
          innerRef={inputRef}
          aria-label={intl.formatMessage(globalTranslations.quantityTitle)}
          placeholder={product.package_unit_name}
          pattern="[0-9]*"
        />
        {getPackageSizeSum()}
      </div>
    );
  };

  const renderInput = () => {
    if (product.sellInPackage) {
      return renderPackageInput();
    }

    return (
      <Input
        id={`ProductInfo-${product.id}`}
        className="ProductMatrixCell__input"
        type="number"
        name={product.id}
        value={quantity}
        onFocus={openPopover}
        onBlur={closePopover}
        onChange={handleProductQuantity}
        innerRef={inputRef}
        aria-label={intl.formatMessage(globalTranslations.quantityTitle)}
        placeholder={product.stock_unit}
      />
    );
  };

  if (!product) {
    return getNoProduct();
  }

  if (!product.available_online) {
    return getUnavailableProduct();
  }

  return (
    <div
      className={classNames('ProductMatrixCell', className)}
      style={getCellStyle()}
    >
      {renderInput()}
      {onRenderTooltip(isOpen)}
    </div>
  );
};

ProductMatrixCell.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
  cellCount: PropTypes.number.isRequired,
  onHandleProductQuantity: PropTypes.func.isRequired,
  onRenderTooltip: PropTypes.func.isRequired,
  className: PropTypes.string,
  product: PropTypes.object,
  propertyListRef: PropTypes.object,
};

export default injectIntl(
  inject('accountStore', 'configStore', 'uiStore')(observer(ProductMatrixCell))
);
