import PropTypes from 'prop-types';
import React from 'react';

import 'famfamfam-flags/dist/sprite/famfamfam-flags.css';

const FlagIcon = ({ countryCode }) => {
  return <span className={`FlagIcon famfamfam-flags ${countryCode}`} />;
};

FlagIcon.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

export default FlagIcon;
