import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import GenericMeta from '../GenericMeta';

const SEODescription = ({ content }) => {
  return (
    <>
      <Helmet>
        <meta name="description" content={content} />
      </Helmet>
      <GenericMeta property="og:description" content={content} />
      <GenericMeta name="twitter:description" content={content} />
    </>
  );
};

SEODescription.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default SEODescription;
