import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Country from '../../../models/Country';
import Currency from '../../../models/Currency';
import Language from '../../../models/Language';
import { modelOf } from '../../../prop-types';
import CountryStore from '../../../store/CountryStore';
import CurrencyStore from '../../../store/CurrencyStore';
import LanguageStore from '../../../store/LanguageStore';
import RegionalSettingsCurrencyDropdown from '../RegionalSettingsCurrencyDropdown';
import RegionalSettingsDeliveryCountryDropdown from '../RegionalSettingsDeliveryCountryDropdown';
import RegionalSettingsLanguageDropdown from '../RegionalSettingsLanguageDropdown';

const RegionalSettingsDropdowns = ({
  languageStore,
  currencyStore,
  countryStore,
  country,
  language,
  currency,
  onChange,
}) => {
  const showCountries = countryStore.countries.length > 1;
  const showLanguages = languageStore.languages.length > 1;
  const showCurrencies = currencyStore.currencies.length > 1;

  const dropdowns = [];

  if (showCountries) {
    dropdowns.push(
      <RegionalSettingsDeliveryCountryDropdown
        key="country"
        country={country}
        countries={countryStore.countries}
        onSelect={(newCountry) => onChange('country', newCountry)}
      />
    );
  }
  if (showLanguages) {
    dropdowns.push(
      <RegionalSettingsLanguageDropdown
        key="language"
        language={language}
        languages={languageStore.languages}
        onSelect={(newLanguage) => onChange('language', newLanguage)}
      />
    );
  }
  if (showCurrencies) {
    dropdowns.push(
      <RegionalSettingsCurrencyDropdown
        key="currency"
        currency={currency}
        currencies={currencyStore.currencies}
        onSelect={(newCurrency) => onChange('currency', newCurrency)}
      />
    );
  }

  if (dropdowns.length === 0) {
    return null;
  }

  return (
    <div className="NavigationRegionalSettingsMenu__selectors">{dropdowns}</div>
  );
};

RegionalSettingsDropdowns.propTypes = {
  languageStore: modelOf(LanguageStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  country: modelOf(Country).isRequired,
  currency: modelOf(Currency).isRequired,
  language: modelOf(Language).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default inject(
  'countryStore',
  'currencyStore',
  'languageStore'
)(observer(RegionalSettingsDropdowns));
