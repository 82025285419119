import classNames from 'classnames';
import { every } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Col, Container, Label, Popover, Row } from 'reactstrap';

import FullProductCollectionItem from '../../../models/product/FullProductCollectionItem';
import FullProductMatrix from '../../../models/product/FullProductMatrix';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import ProductIncomingQuantity from '../ProductIncomingQuantity';
import ProductMatrixDetailedStockInfo from '../ProductMatrixDetailedStockInfo';

const messages = defineMessages({
  availableForDelivery: {
    id: 'productMatrix.availableForDelivery',
    defaultMessage: 'Available for delivery: {freeQuantity} {stockUnit}',
  },
  deliveryStatus: {
    id: 'productMatrix.deliveryStatus',
    defaultMessage: 'Delivery status',
  },
  inStockQuantity: {
    id: 'productMatrix.inStockQuantity',
    defaultMessage: 'In own stock: {inStockQuantity} {stockUnit}',
  },
  productsHasBeenOrdered: {
    id: 'product.productsHasBeenOrdered',
    defaultMessage: '(Products has been ordered)',
  },
});

const ProductCollectionMatrixTooltip = ({
  configStore,
  productCollectionElementItem,
  isOpen,
  productMatrix,
  stockUnit,
}) => {
  const renderIncomingQuantities = () => {
    const incomingQuantities =
      productCollectionElementItem.getIncomingQuantities();

    if (!incomingQuantities || incomingQuantities.length === 0) {
      return null;
    }

    return incomingQuantities.map((incomingQuantity, index) => (
      <Col key={index} sm={12}>
        <ProductIncomingQuantity
          incomingQuantity={incomingQuantity}
          stockUnit={stockUnit}
          className="ProductCollectionMatrix__tooltip-incoming-quantity"
        />
      </Col>
    ));
  };

  const getOrderedProducts = () => {
    const incomingQuantities =
      productCollectionElementItem.getIncomingQuantities();

    if (!incomingQuantities || incomingQuantities.length === 0) {
      return null;
    }

    const predicate = {
      date: null,
      free_quantity: null,
      quantity: null,
    };

    return every(incomingQuantities, predicate);
  };

  const renderLabelRow = () => {
    const rowId = productCollectionElementItem.row_id;
    const columnId = productCollectionElementItem.column_id;
    const elementName = productMatrix.getProductElementNamesByItemElementId(
      rowId,
      columnId
    );

    return (
      <Label className="ProductCollectionMatrix__tooltip-label">
        <span className="ProductCollectionMatrix__tooltip-label-name">
          {elementName.columnName}
          {elementName.columnName && elementName.rowName && ' / '}
          {elementName.rowName}
        </span>
        <span className="ProductCollectionMatrix__tooltip-label-delivery-status">
          <FormattedMessage {...messages.deliveryStatus} />
        </span>
      </Label>
    );
  };

  const renderTooltipDetailedStockInfo = () => {
    return (
      <ProductMatrixDetailedStockInfo
        productCollectionElementItem={productCollectionElementItem}
        labelRow={renderLabelRow()}
      />
    );
  };

  const renderTooltipSimpleContent = () => {
    const inStockQuantity = productCollectionElementItem.getInStockQuantity();
    const freeQuantity = productCollectionElementItem.getAvailableAmount();
    const ifOrderedProducts = getOrderedProducts();

    return (
      <Container>
        <Row className="ProductCollectionMatrix__tooltip">
          <Col sm={12}>{renderLabelRow()}</Col>
          <Col sm={12}>
            <div className="ProductCollectionMatrix__tooltip-stock-quantity">
              <FormattedMessage
                {...messages.inStockQuantity}
                values={{
                  inStockQuantity,
                  stockUnit,
                }}
              />
            </div>
          </Col>
          {ifOrderedProducts ? (
            <FormattedMessage {...messages.productsHasBeenOrdered} />
          ) : (
            renderIncomingQuantities()
          )}
          <Col sm={12}>
            <div className="ProductCollectionMatrix__tooltip-free-quantity">
              <FormattedMessage
                {...messages.availableForDelivery}
                values={{
                  freeQuantity,
                  stockUnit,
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  const tooltipContent = configStore.productPage.matrixDetailedStockInfoEnabled
    ? renderTooltipDetailedStockInfo()
    : renderTooltipSimpleContent();

  return (
    <Popover
      popperClassName={classNames(
        'ProductCollectionMatrixTooltip',
        'ProductCollectionMatrix__product-quantity-tooltip'
      )}
      placement="top"
      isOpen={isOpen}
      target={`qty--${productCollectionElementItem.row_id}--${productCollectionElementItem.column_id}`}
      boundariesElement="window"
    >
      {tooltipContent}
    </Popover>
  );
};

ProductCollectionMatrixTooltip.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productMatrix: modelOf(FullProductMatrix).isRequired,
  productCollectionElementItem: modelOf(FullProductCollectionItem).isRequired,
  isOpen: PropTypes.bool,
  stockUnit: PropTypes.string,
};

export default inject('configStore')(observer(ProductCollectionMatrixTooltip));
