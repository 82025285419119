import isUrl from 'is-url';
import { isString } from 'lodash';

/**
 * Tries to parse the given path for a two letter language code.
 * Whitelists valid ISO-639-1 codes.
 *
 * @param path
 * @returns {null|string} 2 letters from the first part of the path, if exists.
 */
export function parseLanguageCode(path) {
  const pathMatches = path.match(
    /^\/(ab|aa|af|ak|sq|am|ar|an|hy|as|av|ae|ay|az|bm|ba|eu|be|bn|bh|bi|bs|br|bg|my|ca|km|ch|ce|ny|zh|cu|cv|kw|co|cr|hr|cs|da|dv|nl|dz|en|eo|et|ee|fo|fj|fi|fr|ff|gd|gl|lg|ka|de|ki|el|kl|gn|gu|ht|ha|he|hz|hi|ho|hu|is|io|ig|id|ia|ie|iu|ik|ga|it|ja|jv|kn|kr|ks|kk|rw|kv|kg|ko|kj|ku|ky|lo|la|lv|lb|li|ln|lt|lu|mk|mg|ms|ml|mt|gv|mi|mr|mh|ro|mn|na|nv|nd|ng|ne|se|no|nb|nn|ii|oc|oj|or|om|os|pi|pa|ps|fa|pl|pt|qu|rm|rn|ru|sm|sg|sa|sc|sr|sn|sd|si|sk|sl|so|st|nr|es|su|sw|ss|sv|tl|ty|tg|ta|tt|te|th|bo|ti|to|ts|tn|tr|tk|tw|ug|uk|ur|uz|ve|vi|vo|wa|cy|fy|wo|xh|yi|yo|za|zu|)\/.*$/
  );

  return pathMatches && pathMatches[1];
}

export function parseTokenFromPath(path) {
  const regex = /\/s\/set-new-password\/([^\/]+)\//;
  const match = path.match(regex);
  return match ? match[1] : null;
}

export function parseHash(hash) {
  if (hash.charAt(0) === '#') {
    // Hash includes the hashtag (#), strip it off.
    return hash.substring(1);
  }
  return hash;
}

export function isNotHandledByReactRouter(urlOrPath) {
  if (!urlOrPath || !isString(urlOrPath)) {
    return false;
  }

  // Special protocols
  if (/^(mailto|tel):/.test(urlOrPath)) {
    return true;
  }

  // Checkout and My account use old Netstore URLs
  if (/\/(kauppa|shop|butik)\/[^/]+\/\d+\/?/.test(urlOrPath)) {
    return true;
  }

  const isUri = isUrl(urlOrPath);

  if (!isUri) {
    // Paths to files (E.g. /path/to/file/name.json)
    if (/.+\.[a-zA-Z]+(\?.*)?$/.test(urlOrPath)) {
      return true;
    }
  }

  return isUri;
}

export function stripHostIfMatches(url, host) {
  return String(url).replace(new RegExp('(http|https)*:?\\/\\/' + host), '');
}

export function fixPercentDecodingForParam(param) {
  if (typeof param !== 'string') {
    return param;
  }

  return param
    .replace(/%26/gi, '&')
    .replace(/%3F/gi, '?')
    .replace(/%23/gi, '#')
    .replace(/%2B/gi, '+')
    .replace(/%3B/gi, ';')
    .replace(/%2C/gi, ',')
    .replace(/%2F/gi, '/')
    .replace(/%3A/gi, ':')
    .replace(/%40/gi, '@')
    .replace(/%3D/gi, '=')
    .replace(/%24/gi, '$');
}

export function escapeUrlForPaginatorTemplate(url) {
  // The slugs provided by the backend may contain special characters that need to be escaped
  // so that they are handled correctly by the paginator template.

  return url
    .replace(/\(/gi, '%28')
    .replace(/\)/gi, '%29')
    .replace(/\*/gi, '%2A')
    .replace(/:/gi, '%3A');
}

export function escapeUrlParametersForPaginatorTemplate(url) {
  // The parameters provided by search engine bots may contain special characters that need to be escaped
  // so that they are handled correctly by the paginator template.

  return escapeUrlForPaginatorTemplate(url.replace(/\//gi, '%2F'));
}

export function pathFragmentMatchesLocation(fragment, location) {
  let decodedLocation = '';
  try {
    // Test location for properly formatted URI.
    // decodeURI() may return error "URI malformed" as the product data contains illegal characters.
    // Return match in these cases.
    decodedLocation = decodeURI(location);
  } catch (error) {
    return true;
  }
  return decodedLocation.endsWith(decodeURI(fragment));
}

export function transformInternalLink(path) {
  return path.charAt(0) === '/' || path.includes('http') ? path : `/${path}`;
}

export function transformExternalLink(path) {
  if (/^https?:\/\//.test(path) || /^\/\//.test(path)) {
    return path;
  }

  return '//' + path;
}

export function transformDomainToUrl(domain) {
  return domain.length > 0 ? 'https://' + domain + '/' : '';
}

export function domainMatchesUrl(domain, url) {
  return url.includes(domain);
}
