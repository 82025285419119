import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import GenericMeta from '../GenericMeta';

const OGPProduct = ({
  price,
  currency,
  availability,
  manufacturer,
  retailerItemId,
  itemGroupId,
}) => {
  return (
    <>
      <Helmet>
        <html prefix="product: https://ogp.me/product#" />
      </Helmet>
      {price && (
        <GenericMeta
          property="product:price:amount"
          content={price.toString()}
        />
      )}
      {currency && (
        <GenericMeta property="product:price:currency" content={currency} />
      )}
      {availability && (
        <GenericMeta property="product:availability" content={availability} />
      )}
      {manufacturer && (
        <GenericMeta property="product:brand" content={manufacturer} />
      )}
      {retailerItemId && (
        <GenericMeta
          property="product:retailer_item_id"
          content={retailerItemId}
        />
      )}
      {itemGroupId && (
        <GenericMeta property="product:item_group_id" content={itemGroupId} />
      )}
    </>
  );
};

OGPProduct.propTypes = {
  currency: PropTypes.string,
  price: PropTypes.number,
  availability: PropTypes.string,
  manufacturer: PropTypes.string,
  retailerItemId: PropTypes.string,
  itemGroupId: PropTypes.string,
};

export default OGPProduct;
