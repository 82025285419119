import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { Col, Input, Row } from 'reactstrap';

import { modelOf } from '../../../prop-types';
import ApiWrapper from '../../../services/ApiWrapper';
import { LocationContextPropType } from '../../../services/LocationContext';
import UIStore from '../../../store/UIStore';
import RequestState from '../../../types/RequestState';
import { createErrorModel } from '../../../util/error';
import { parse, stringify } from '../../../util/queryString';
import ShowMoreContent from '../../common/ShowMoreContent';
import WysiwygContent from '../../common/WysiwygContent';
import GenericMeta from '../../head/GenericMeta';
import SEODescription from '../../head/SEODescription';
import SEOTitle from '../../head/SEOTitle';
import ContentForState from '../../loader/ContentForState';
import MainProductList from '../../product-list/MainProductList';

@observer
export class SuitableProductFinder extends Component {
  state = {
    requestState: RequestState.NONE,
    filters: [],
    product: {},
    recommendWithProductId: null,
    error: null,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categoryId !== this.props.categoryId ||
      !isEqual(
        SuitableProductFinder.getSearchParamsFromProps(prevProps),
        SuitableProductFinder.getSearchParamsFromProps(this.props)
      )
    ) {
      this.loadData();
    }
  }

  loadData = () => {
    const { categoryId, apiWrapper } = this.props;
    this.setState({ requestState: RequestState.LOADING });
    const currentSelections = this.getCurrentSelections();

    apiWrapper
      .request(`suitable-products/${categoryId}`, {
        params: {
          selectedValues: currentSelections,
        },
      })
      .then((data) => {
        const state = {
          product: {},
          requestState: RequestState.LOADED,
        };
        if (data.product) {
          state.product = data.product;
        }
        if (data.options) {
          state.filters = data.options;
        }
        this.setState(state);
      })
      .catch((error) => {
        this.setState({
          requestState: RequestState.ERROR,
          error: createErrorModel(error),
        });
      });
  };

  static getSearchParamsFromProps = (props) => {
    const {
      location: { search },
    } = props;
    return parse(search);
  };

  getSearchParameters = () => {
    return SuitableProductFinder.getSearchParamsFromProps(this.props);
  };

  setSearchParameters = (newParameters) => {
    const { history } = this.props;
    history.push({
      search: stringify({ ...this.getSearchParameters(), ...newParameters }),
    });
  };

  getCurrentSelections = () => {
    return this.getSearchParameters().suitableFor || [];
  };

  handleChange = (event, level) => {
    const value = event.target.value;

    // If empty option is selected, do nothing.
    if (value === '') {
      return;
    }

    this.setSearchParameters({
      suitableFor: [...this.getCurrentSelections().slice(0, level), value],
    });
  };

  getImageUrl = () => {
    const { product } = this.state;
    const { locationContext } = this.props;

    return product.image
      ? `${locationContext.protocol}//${locationContext.host}${product.image}`
      : null;
  };

  renderSelect = (options, level) => {
    return (
      <Input
        className="SuitableProductFinder__select"
        key={level}
        type="select"
        onChange={(e) => this.handleChange(e, level)}
        value={String(this.getCurrentSelections()[level])}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value || ''}>
            {option.name}
          </option>
        ))}
      </Input>
    );
  };

  renderSelects = () => {
    return (
      <div className="SuitableProductFinder__selects">
        {this.state.filters
          .slice(0, this.getCurrentSelections().length + 1)
          .map((filter, index) => this.renderSelect(filter, index))}
      </div>
    );
  };

  renderDescription = () => {
    const { product } = this.state;

    const hasImage = !!product.image;

    return (
      <div className="SuitableProductFinder__information">
        <Row>
          <Col className="SuitableProductFinder__header">
            <h2>{product.name}</h2>
          </Col>
        </Row>
        <Row>
          {hasImage && (
            <Col xs="4">
              <div className="SuitableProductFinder__image">
                <img src={product.image} alt={product.image} loading="lazy" />
              </div>
            </Col>
          )}
          <Col xs={hasImage ? 8 : 12}>
            {product.html && (
              <ShowMoreContent collapsedHeight={12}>
                <WysiwygContent html={product.html} />
              </ShowMoreContent>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  renderSEORelated = () => {
    const { product } = this.state;

    const imageUrl = this.getImageUrl();
    return (
      <Fragment>
        {product.name && <SEOTitle title={product.name} />}
        {product.description && (
          <SEODescription content={product.description} />
        )}
        {imageUrl && (
          <Fragment>
            <GenericMeta name="twitter:card" content={imageUrl} />
            <GenericMeta name="twitter:image" content={imageUrl} />
            <GenericMeta property="og:image" content={imageUrl} />
          </Fragment>
        )}
      </Fragment>
    );
  };

  render() {
    const { listName, listId } = this.props;
    const { product } = this.state;

    if (!product && !product.id) {
      return null;
    }

    return (
      <div className="SuitableProductFinder">
        {this.renderSEORelated()}
        <div className="SuitableProductFinder__top">
          <Row>
            <Col sm="12" xl="4">
              {this.renderSelects()}
            </Col>
            <Col sm="12" xl="8">
              {this.renderDescription()}
            </Col>
          </Row>
        </div>
        <ContentForState
          state={this.state.requestState}
          error={this.state.error}
          forLoaded={() => {
            return product.id ? (
              <MainProductList
                listId={listId}
                name={listName}
                fixedParams={{ recommendedWithId: product.id }}
              />
            ) : null;
          }}
        />
      </div>
    );
  }
}

SuitableProductFinder.propTypes = {
  categoryId: PropTypes.number.isRequired,
  listName: PropTypes.string.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  locationContext: LocationContextPropType.isRequired,
  uiStore: modelOf(UIStore).isRequired,
  apiWrapper: PropTypes.instanceOf(ApiWrapper).isRequired,
  listId: PropTypes.string,
};

export default withRouter(
  inject('apiWrapper', 'locationContext', 'uiStore')(SuitableProductFinder)
);
