import { types } from 'mobx-state-tree';

import MobileNavigationTab, {
  MobileNavigationTabType,
} from '../../types/MobileNavigationTab';
import { blockScroll, unblockScroll } from '../../util/dom';
import Modal from './Modal';

const NavigationOffCanvas = Modal.named('NavigationOffCanvas')
  .props({
    activeTab: types.optional(
      MobileNavigationTabType,
      MobileNavigationTab.PRODUCTS
    ),
  })
  .actions((self) => {
    return {
      selectTab(tab) {
        self.activeTab = tab;
      },
      close() {
        self.isOpen = false;
        unblockScroll();
      },
      open() {
        self.isOpen = true;
        blockScroll();
      },
    };
  });

export default NavigationOffCanvas;
