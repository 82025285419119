import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useShowMoreText, {
  TEXT_STATE,
} from '../../../hooks/text/useShowMoreText';
import globalTranslations from '../../../i18n/globalTranslations';
import ButtonLink from '../../common/ButtonLink';
import WysiwygContent from '../../common/WysiwygContent';

const ReviewText = ({ text, className }) => {
  const [hasMoreContent, showingMoreText, getText, handleTextState] =
    useShowMoreText({ text });

  const renderButtonText = () => {
    if (showingMoreText === TEXT_STATE.SHOW_MORE)
      return <FormattedMessage {...globalTranslations.readLessSentence} />;

    return <FormattedMessage {...globalTranslations.readMoreSentence} />;
  };

  return (
    <div className={classNames('ReviewText', 'Review__text', className)}>
      <WysiwygContent html={getText()} />
      {hasMoreContent && (
        <ButtonLink
          className="ReviewText__read-more-link"
          onClick={handleTextState}
        >
          {renderButtonText()}
        </ButtonLink>
      )}
    </div>
  );
};

ReviewText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ReviewText;
