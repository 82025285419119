import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Input, Label, Row } from 'reactstrap';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import { modelOf } from '../../../prop-types';
import StorageStore from '../../../store/StorageStore';
import RequestState from '../../../types/RequestState';
import ProductStorageStock from '../ProductStorageStock';
import BlockSpinner from '../../loader/BlockSpinner';
import UnexpectedError from '../../loader/UnexpectedError';
import ConfigStore from '../../../store/ConfigStore';
import globalTranslations from '../../../i18n/globalTranslations';
import FullProduct from '../../../models/product/FullProduct';

const MINIMUM_STORAGES_FOR_FILTER = 10;

const messages = defineMessages({
  'stock.filterByStorage': {
    id: 'stock.filterByStorage',
    defaultMessage: 'Filter by storage',
  },
});

@observer
class ProductStoreAvailability extends Component {
  state = {
    selectedStorageId: '',
  };

  componentDidMount() {
    const { storageStore, merchantSiteUrl } = this.props;
    storageStore.makeSureStoragesLoaded(merchantSiteUrl);
    this.loadStocks();
  }

  componentDidUpdate(prevProps) {
    const { activeProductId, configStore } = this.props;
    configStore.siteConfig.isShoppingCenter &&
      this.makeSureShoppingCenterStoragesLoaded(prevProps);
    if (prevProps.activeProductId !== activeProductId) {
      this.loadStocks();
    }
  }

  makeSureShoppingCenterStoragesLoaded = (prevProps) => {
    const { storageStore, activeProductId, merchantSiteUrl } = this.props;
    if (prevProps.activeProductId !== activeProductId) {
      storageStore.makeSureStoragesLoaded(merchantSiteUrl);
    }
  };

  loadStocks = () => {
    const { product, activeProductId } = this.props;
    if (product.stockStates.get(activeProductId) !== RequestState.LOADED) {
      product.loadStocks(activeProductId);
    }
  };

  getStoreAvailability = () => {
    const { product, activeProductId, storageStore } = this.props;
    let storageList = storageStore.listingStorages;

    if (this.state.selectedStorageId) {
      storageList = [];
      const selectedStorage = storageStore.findStorageById(
        this.state.selectedStorageId
      );
      if (selectedStorage) {
        storageList.push(selectedStorage);
      }
    }

    return storageList.map((storage) => {
      return (
        <ProductStorageStock
          key={storage.id}
          storage={storage}
          stock={product.findStorageStock(activeProductId, storage.id)}
          activeProductId={activeProductId}
          product={product}
        />
      );
    });
  };

  getStorageOptions = () => {
    const { storageStore } = this.props;
    return storageStore.listingStorages.map((storage) => {
      return (
        <option value={storage.id} key={storage.id}>
          {storage.name}
        </option>
      );
    });
  };

  handleChange = (event) => {
    this.setState({ selectedStorageId: event.target.value });
  };

  render() {
    const { product, activeProductId, storageStore } = this.props;
    const stockState = product.stockStates.get(activeProductId);
    return (
      <div className="ProductStoreAvailability">
        <h3 className="ProductStoreAvailability__title">
          <FormattedMessage
            id="stock.storeAvailabilityTitle"
            defaultMessage="Product availability in stores"
          />
        </h3>
        {storageStore.listingStorages.length >= MINIMUM_STORAGES_FOR_FILTER && (
          <>
            <Label for="ProductStoreAvailability__storage-select" hidden>
              Select
            </Label>
            <Input
              type="select"
              name="select"
              className="ProductStoreAvailability__storage-select"
              id="ProductStoreAvailability__storage-select"
              onChange={this.handleChange}
            >
              <option value="">
                {this.props.intl.formatMessage(
                  messages['stock.filterByStorage']
                )}
              </option>
              {this.getStorageOptions()}
            </Input>
          </>
        )}
        <div className="ProductStoreAvailability__headings">
          <Row>
            <Col className="ProductStoreAvailability__heading" md="3">
              <FormattedMessage
                id="stock.storeHeading"
                defaultMessage="Store"
              />
            </Col>
            <Col className="ProductStoreAvailability__heading" md="3">
              <FormattedMessage {...globalTranslations.availabilityTitle} />
            </Col>
            <Col className="ProductStoreAvailability__heading" md="3">
              <FormattedMessage
                id="stock.addressHeading"
                defaultMessage="Address"
              />
            </Col>
            <Col className="ProductStoreAvailability__heading" md="3">
              <FormattedMessage
                id="stock.contactHeading"
                defaultMessage="Contact Information and Opening Hours"
              />
            </Col>
          </Row>
        </div>

        {(storageStore.state === RequestState.LOADING ||
          stockState === RequestState.LOADING) && <BlockSpinner />}
        {(storageStore.state === RequestState.ERROR ||
          stockState === RequestState.ERROR) && <UnexpectedError />}
        {product.stocks.get(activeProductId) &&
          storageStore.state === RequestState.LOADED &&
          this.getStoreAvailability()}
      </div>
    );
  }
}

ProductStoreAvailability.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  storageStore: modelOf(StorageStore).isRequired,
  product: modelOf(FullProduct).isRequired,
  activeProductId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  merchantSiteUrl: PropTypes.string,
};

export default inject(
  'configStore',
  'storageStore'
)(injectIntl(ProductStoreAvailability));
