import { some } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const ClickOutside = ({
  children,
  onClickOutside,
  excludedSelector,
  className,
  style,
  ...rest
}) => {
  //TODO: Refactor into utility hook.

  const containerElement = useRef(null);
  let isTouch = false;

  useEffect(() => {
    document.addEventListener('touchend', handle, true);
    document.addEventListener('click', handle, true);

    return () => {
      document.removeEventListener('touchend', handle, true);
      document.removeEventListener('click', handle, true);
    };
  }, []);

  const inExcluded = (target) => {
    return some(document.querySelectorAll(excludedSelector), (element) =>
      element.contains(target)
    );
  };

  const handle = (e) => {
    if (e.type === 'touchend') {
      isTouch = true;
    }

    if (e.type === 'click' && isTouch) {
      return;
    }

    if (
      !inExcluded(e.target) &&
      containerElement &&
      !containerElement.current.contains(e.target)
    ) {
      onClickOutside(e);
    }
  };

  return (
    <div className={className} style={style} ref={containerElement} {...rest}>
      {children}
    </div>
  );
};

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  excludedSelector: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ClickOutside;
