import { types } from 'mobx-state-tree';
import QuantityDiscountDisplayStyles from '../types/QuantityDiscountDisplayStyles';
import BannerStyle, { BannerStyleType } from '../types/banner/BannerStyle';

const GlobalConfig = types.model('GlobalConfig', {
  ACCOUNT_COMPANY: types.optional(types.boolean, true),
  ACTIVATE_ALSO_PURCHASED_PRODUCTS: types.optional(types.boolean, true),
  ACTIVATE_SECTIONS: types.optional(types.boolean, true),
  ACTIVATE_SPOTMORE: types.optional(types.boolean, false),
  ACTIVE_VEHICLE_PART_SEARCH_INTEGRATION: types.optional(types.string, ''),
  ADD_BOTTLE_DEPOSIT_TO_PRICE: types.optional(types.boolean, true),
  ADDITIONAL_PRODUCT_TABS_ACTIVE: types.optional(types.boolean, false),
  ALLOW_FILE_UPLOAD_FOR_CUSTOMERS: types.optional(types.boolean, true),
  ALLOW_PRODUCT_REVIEWS: types.optional(types.boolean, true),
  ALLOW_SALESPERSON_LOGIN_ALL_CLIENTS: types.optional(types.boolean, false),
  BANNER_CAROUSEL_MAIN_FOOTER_STYLE: types.optional(
    BannerStyleType,
    BannerStyle.BOTH
  ),
  BANNER_SCENT_HORIZONTAL_POSITION: types.optional(types.string, 'center'),
  BANNER_SCENT_MOBILE_ENABLE: types.optional(types.boolean, false),
  BANNER_SCENT_ON_TOP: types.optional(types.boolean, false),
  BANNER_SCENT_PATTERN: types.optional(types.string, ''),
  BANNER_SCENT_REPEAT: types.optional(types.string, 'no-repeat'),
  BOX_MANUFACTURERS_STYLE: types.optional(types.string, ''),
  BOX_MANUFACTURERS_LIMIT_TO_SECTION: types.optional(types.boolean, true),
  CAPTCHA_ENABLE: types.optional(types.boolean, false),
  CAPTCHA_SITE_KEY: types.optional(types.string, ''),
  CART_ERROR_INFO_PAGE_ID: types.optional(types.string, ''),
  CART_SHOW_ADDED_PRODUCT_IMAGES: types.optional(types.boolean, false),
  CART_SIMPLIFIED_MOBILE_BUTTON: types.optional(types.boolean, true),
  CATEGORY_SLIDER_UI_SELECTION: types.optional(types.string, '0'),
  CONFIGURATION_TEXTS: types.model({
    CONF_SEO_TITLE: types.optional(types.string, ''),
    CONF_SEO_DESCRIPTION: types.optional(types.string, ''),
  }),
  CONTACT_ENTRY_ADDRESS: types.optional(types.boolean, true),
  CONTACT_FORM_ADDRESS_NEEDED: types.optional(types.boolean, false),
  CONTACT_ENTRY_PHONE: types.optional(types.boolean, true),
  COOKIE_PRIVACY_INFO_PAGE_ID: types.optional(types.string, ''),
  CRITEO_MERCHANT_ID: types.optional(types.string, ''),
  CUSTOMER_NAME_NEEDED_ON_NEWSLETTER: types.optional(types.boolean, false),
  CUSTOMER_SERVICE_OPEN: types.optional(types.string, ''),
  CUSTOMER_SERVICE_PRICE: types.optional(types.string, ''),
  DEFAULT_CATEGORY_PRODUCT_SORT_OPTION: types.optional(types.string, ''),
  DEFAULT_CUSTOMER_TYPE: types.optional(types.string, ''),
  DEFAULT_LANGUAGE: types.optional(types.string, ''),
  DENY_LOGGING: types.optional(types.boolean, false),
  DENY_ROBOTS: types.optional(types.boolean, false),
  DELIVERYTERMS_PAGE_ID: types.optional(types.string, ''),
  DISPLAY_MULTI_PRODUCTS_MODEL: types.optional(types.boolean, false),
  DISPLAY_PRODUCTS_MODEL: types.optional(types.boolean, true),
  ENABLE_ARINET_INTEGRATION: types.optional(types.boolean, false),
  ENABLE_B2B_ORDER_HISTORY: types.optional(types.boolean, false),
  ENABLE_CRITEO_INTEGRATION: types.optional(types.boolean, false),
  ENABLE_CUSTOMER_REGISTRATION: types.optional(types.boolean, false),
  ENABLE_EXTERNAL_CAMPAIGN_VISIT_TRACKING: types.optional(types.boolean, false),
  ENABLE_FACEBOOK_GRAPH_API: types.optional(types.boolean, false),
  ENABLE_FACEBOOK_TRACKING: types.optional(types.boolean, false),
  ENABLE_LITE_CHECKOUT: types.optional(types.boolean, false),
  ENABLE_MONEY_TRANSFER_ACCOUNT: types.optional(types.boolean, false),
  ENABLE_MY_ACCOUNT: types.optional(types.boolean, true),
  ENABLE_NEWSLETTER_SUBSCRIPTION: types.optional(types.boolean, true),
  ENABLE_PDF_ON_CART_MODAL: types.optional(types.string, '0'),
  ENABLE_PDF_ON_CATEGORY_PAGE: types.optional(types.string, '0'),
  ENABLE_PDF_ON_PRODUCT_PAGE_CONTENT: types.optional(types.string, '0'),
  ENABLE_PDF_ON_SECTION_PAGE: types.optional(types.string, '0'),
  ENABLE_POSTAFFILIATE_INTEGRATION: types.optional(types.boolean, false),
  ENABLE_PRODUCT_PAGE_PRODUCT_ENQUIRY: types.optional(types.boolean, true),
  ENABLE_PRODUCT_SEARCH_SUGGESTIONS: types.optional(types.boolean, true),
  ENABLE_PRODUCTS_WATCHER: types.optional(types.boolean, true),
  ENABLE_QUICK_REGISTERATION: types.optional(types.boolean, true),
  ENABLE_RECURRING_ORDERS: types.optional(types.boolean, false),
  ENABLE_RESERVE_IN_STORE: types.optional(types.boolean, false),
  ENABLE_STORAGE_INFO: types.optional(types.boolean, true),
  ENABLE_SERVICE_FORM_INTEGRATION: types.optional(types.boolean, false),
  ENABLE_SWOGO_INTEGRATION: types.optional(types.boolean, false),
  ENABLE_YOTPO_INTEGRATION: types.optional(types.boolean, false),
  ENABLE_WISHLIST_EMAIL_SHARE: types.optional(types.boolean, true),
  ENABLE_ZOOVU_INTEGRATION: types.optional(types.boolean, false),
  ENTRY_CITY_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_DOB_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_EMAIL_ADDRESS_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_FIRST_NAME_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_LAST_NAME_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_LOGIN_INFO_PAGE_ID: types.optional(types.string, ''),
  ENTRY_LOGIN_NAME_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_PASSWORD_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_POSTCODE_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_STATE_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_STREET_ADDRESS_MIN_LENGTH: types.optional(types.string, ''),
  ENTRY_TELEPHONE_MIN_LENGTH: types.optional(types.string, ''),
  EXTERNAL_CAMPAIGNS_EXPIRY_TIME: types.optional(types.string, ''),
  FACEBOOK_PIXEL_ID: types.optional(types.string, ''),
  FACEBOOK_VERIFICATION_CONTENT: types.optional(types.string, ''),
  FIND_FROM_ACCESSORIES_CATEGORIES: types.array(
    types.optional(types.string, '')
  ),
  FOOTER_BANNER_HEIGHT: types.optional(types.string, '400'),
  FOOTER_MOBILE_COLUMNS: types.optional(types.boolean, true),
  FRONTPAGE_TABS_MAX_PRODUCTS: types.optional(types.string, ''),
  FRONTPAGE_TABS_ORDER: types.array(types.optional(types.string, '')),
  FRONTPAGE_TEXT: types.optional(types.string, ''),
  GA4_CUSTOMERS_ID: types.optional(types.string, ''),
  GIOSG_CUSTOMER_ID: types.optional(types.string, ''),
  GTM_CUSTOMERS_ID: types.optional(types.string, ''),
  HEADER_CENTER: types.optional(types.boolean, false),
  HEADER_SEARCH_BAR_MOVE_TOP_NAVIGATION: types.optional(types.boolean, false),
  HEADER_SHOPPING_CART_MOVE_LOGIN_RIGHT_SIDE: types.optional(
    types.boolean,
    false
  ),
  HEADER_CENTER_SHOP_LOGO: types.optional(types.boolean, false),
  HEADER_SHOW_STICKY_HEADER_ON_STARTUP: types.optional(types.boolean, false),
  HEADER_HIDE_STICKY_HEADER_ON_SCROLLING_DOWN: types.optional(
    types.boolean,
    false
  ),
  HEADER_MOBILE_SEARCHBAR_VISIBILITY: types.optional(types.boolean, true),
  HEADER_MOBILE_SECTION_SLIDER: types.optional(types.boolean, true),
  HIDE_MULTIPRODUCT_SELECTION_IMAGE: types.optional(types.boolean, true),
  INVOICE_TELEPHONE: types.optional(types.string, ''),
  KLARNA_SUPPORTED_LOCALE_CURRENCIES: types.optional(types.string, ''),
  LANGUAGE_TEXTS: types.model({
    TEXT_SEO_STORE_NAME_ADD: types.optional(types.string, ''),
  }),
  LAYOUT_STORE_THEME_SELECTION: types.optional(types.string, ''),
  MAIN_BANNER_FULL_WIDTH: types.optional(types.boolean, true),
  MAIN_BANNER_HEIGHT: types.optional(types.string, '400'),
  MAIN_BANNER_WITHIN_CONTENT: types.optional(types.boolean, true),
  MASTER_SETTING_FOR_PDF_PRINTING: types.optional(types.boolean, false),
  MATRIX_DIMENSION_ORDER_BY_COUNT: types.optional(types.boolean, false),
  MAX_PRODUCTS_FOR_INSTANT_PDF_GENERATION: types.optional(types.string, '100'),
  MEGAMENU_FORCE_TWO_PRODUCT_CATEGORY_COLUMNS: types.optional(
    types.boolean,
    false
  ),
  MEGAMENU_TWO_COLUMN_MAX_COUNT: types.optional(types.string, ''),
  MEGAMENU_GROUP_ADDITIONAL_LINKS: types.optional(types.boolean, false),
  MODULE_PAYMENT_KCO_EID: types.optional(types.string, ''),
  MODULE_PAYMENT_KREDITOR_EID: types.optional(types.string, ''),
  MULTIPRODUCT_AS_DROPDOWN: types.optional(types.boolean, false),
  NAVIGATION_MENU_STYLE: types.optional(types.string, ''),
  NAVIGATION_ADD_TO_CART_ROW: types.optional(types.string, ''),
  NEW_PRODUCTS_EXPIRARY_TIME: types.optional(types.string, ''),
  NOT_VISIBLE_PROPERTIES: types.optional(types.array(types.number), []),
  ORDER_ONLY_PRODUCT_ORDER_LIMIT: types.optional(types.string, ''),
  PAGE_SUBCATEGORY_ENABLE_TABS: types.optional(types.boolean, true),
  POSTAFFILIATE_ACCOUNT_ID: types.optional(types.string, ''),
  POSTAFFILIATE_MERCHANT_URL: types.optional(types.string, ''),
  PREVENT_NOINDEX_BY_PARAMETER: types.array(types.optional(types.string, '')),
  PRIVACY_STATEMENT_PAGE_ID: types.optional(types.string, ''),
  PRODUCT_ADDED_TO_CART_MODAL_STYLE: types.optional(types.string, ''),
  PRODUCT_IMAGE_ASPECT_RATIO: types.optional(types.string, ''),
  PRODUCT_LIST_CARD_DEFAULT_STYLE: types.optional(types.string, ''),
  PRODUCT_LIST_CART_BUTTON: types.optional(types.boolean, true),
  PRODUCT_LIST_CODE: types.optional(types.boolean, false),
  PRODUCT_LIST_DESCRIPTION: types.optional(types.boolean, true),
  PRODUCT_LIST_EAN: types.optional(types.boolean, false),
  PRODUCT_LIST_EXTRA_ID: types.optional(types.boolean, false),
  PRODUCT_LIST_FILTER_BY_SEARCH_PHRASE: types.optional(types.boolean, false),
  PRODUCT_LIST_FILTER_BY_STOCK: types.optional(types.boolean, true),
  PRODUCT_LIST_FILTER_BY_STOCK_DEFAULT_STATE: types.optional(
    types.boolean,
    true
  ),
  PRODUCT_LIST_MANUFACTURERS_CODE: types.optional(types.boolean, false),
  PRODUCT_LIST_NO_CUSTOMER_GROUP_PRICE_SEARCH: types.optional(
    types.boolean,
    false
  ),
  PRODUCT_LIST_QUANTITY: types.optional(types.boolean, false),
  PRODUCT_LIST_SINGULAR_PRICE: types.optional(types.boolean, false),
  PRODUCT_LIST_SORT_SELECTORS: types.optional(types.string, ''),
  PRODUCT_MATRIX_STOCKS_INFO_ENABLED: types.optional(types.boolean, false),
  PRODUCT_PAGE_SHIPPING_OPTIONS_CALCULATION_TYPE: types.optional(
    types.string,
    ''
  ),
  PRODUCT_PAGE_SHORT_DESCRIPTION_POSITION: types.optional(types.string, ''),
  PRODUCT_PAGE_SHOW_EAN_CODE: types.optional(types.boolean, true),
  PRODUCT_PAGE_SHOW_EXTRA_ID: types.optional(types.boolean, false),
  PRODUCT_PAGE_SHOW_MANUFACTURERS_CODE: types.optional(types.boolean, false),
  PRODUCT_PAGE_SHOW_MANUFACTURERS_LOGO: types.optional(types.boolean, false),
  PRODUCT_PAGE_SHOW_PRODUCT_CODE: types.optional(types.boolean, true),
  PRODUCT_PAGE_SHOW_ADDITIONAL_PRODUCT_ID: types.optional(types.boolean, false),
  PRODUCT_PAGE_ENABLE_ADDITIONAL_SERVICE_MODAL: types.optional(
    types.boolean,
    false
  ),
  PRODUCT_PAGE_SHOW_SHIPPING_DETAILS_TAB: types.optional(types.boolean, true),
  PRODUCT_PAGE_SHOW_WARRANTY: types.optional(types.boolean, true),
  PRODUCT_PAGE_SHOW_WEIGHT: types.optional(types.boolean, false),
  PRODUCT_PAGE_SHOW_TAX_BREAKDOWN_FOR_CONSUMERS: types.optional(
    types.boolean,
    false
  ),
  PRODUCT_PAGE_VALIDATE_FOR_CART: types.optional(types.boolean, false),
  PRODUCT_REVIEWS_ALLOW_FOR_VISITORS: types.optional(types.boolean, false),
  PRODUCT_REVIEWS_ANONYMOUS: types.optional(types.boolean, false),
  PRODUCT_REVIEWS_REQUIRE_APPROVAL: types.optional(types.boolean, false),
  PRODUCT_SEARCH_SUGGESTIONS_COUNT: types.optional(types.string, ''),
  PRODUCT_SEARCH_PLAN: types.optional(types.string, ''),
  PRODUCT_SEARCH_POPULAR_SEARCHES: types.optional(types.boolean, true),
  PRODUCT_SEARCH_RECENT_SEARCHES: types.optional(types.boolean, true),
  PRODUCT_SEARCH_USE_BARCODE_SEARCH: types.optional(types.boolean, false),
  PRODUCTS_PRICE_ZERO_IS_VIEW_ONLY: types.optional(types.boolean, false),
  PRODUCTS_QUANTITY_DISCOUNT_DISPLAY_STYLE: types.optional(
    types.string,
    QuantityDiscountDisplayStyles.NORMAL_AND_LOWEST
  ),
  PROPOSAL_REQUEST_SHOW_DELIVERY_AND_QUANTITY: types.optional(
    types.boolean,
    false
  ),
  QUICK_REGISTERATION_PHONE_NEEDED: types.optional(types.boolean, false),
  RECURRING_ORDERS_INFO_PAGE_ID: types.optional(types.string, ''),
  REQUIRE_LOGIN_FOR_ENTRY: types.optional(types.boolean, false),
  RESERVE_IN_STORE_EXCLUDE_PROPERTY_ID: types.optional(types.string, ''),
  RESERVE_IN_STORE_RESERVATION_EXPIRY_TIME: types.optional(types.string, ''),
  SHOPALIKE_TRACKING_ID: types.optional(types.string, ''),
  SECTIONPAGE_USE_PRODUCT_LIST: types.optional(types.boolean, false),
  SECTION_PAGE_SINGLE_ROW_TABS: types.optional(types.boolean, false),
  SHOPPING_CENTER_BUYING_GUIDE_PAGE_ID: types.optional(types.string, ''),
  SHOW_ALL_PRODUCTS_FROM_TOP: types.optional(types.boolean, false),
  SHOW_ALWAYS_PRODUCT_PACKAGES: types.optional(types.boolean, false),
  SHOW_CATEGORY_LIST_BOTTOM: types.optional(types.boolean, true),
  SHOW_COLORS_AND_SIZES_ON_LISTING: types.optional(types.boolean, false),
  SHOW_COOKIE_USAGE_NOTICE: types.optional(types.boolean, true),
  SHOW_CUSTOMER_GROUP_NAME: types.optional(types.boolean, false),
  SHOW_CUSTOMERS_VAT_NUMBER: types.optional(types.boolean, true),
  SHOW_FOOTER_MANUFACTURERS: types.optional(types.boolean, false),
  SHOW_LEFT_COLUMN: types.optional(types.boolean, false),
  SHOW_MAIN_CATEGORY_SLIDER_TOP: types.optional(types.boolean, true),
  SHOW_MAIN_LINK_SPECIAL_PRODUCTS: types.optional(types.boolean, true),
  SHOW_MAIN_LINK_NEW_PRODUCTS: types.optional(types.boolean, true),
  SHOW_MAIN_LINK_SOLD_PRODUCTS: types.optional(types.boolean, false),
  SHOW_MAIN_LINK_MOST_SOLD: types.optional(types.boolean, false),
  SHOW_MAIN_LINK_RECOMMENDED: types.optional(types.boolean, false),
  SHOW_MEGAMENU_MANUFACTURERS: types.optional(types.boolean, true),
  SHOW_PRODUCTS_MENU_BUTTON: types.optional(types.boolean, true),
  SHOW_PRODUCTS_SHORT_DESCRIPTION: types.optional(types.boolean, true),
  SHOW_SUBCATEGORIES_ON_MAINCAT: types.optional(types.boolean, true),
  SHOW_WISHLIST: types.optional(types.boolean, true),
  SHOW_PAGES_100_PERCENT_WIDE: types.optional(types.boolean, false),
  SHOW_STORE_LOGO_ON_MOBILE: types.optional(types.boolean, true),
  SHOW_ASSISTANT_MENU_ON_MOBILE: types.optional(types.boolean, true),
  SOLD_PRODUCTS_SHOW_PRODUCT_CARD: types.optional(types.boolean, true),
  STORE_INFO_PAGE_ID: types.optional(types.string, ''),
  STORE_NAME: types.optional(types.string, ''),
  STORE_OWNER_EMAIL_ADDRESS: types.optional(types.string, ''),
  TWITTER_SITE: types.optional(types.string, ''),
  USE_GA4: types.optional(types.boolean, false),
  USE_GIOSG: types.optional(types.boolean, false),
  USE_GTM: types.optional(types.boolean, false),
  USE_IMAGE_DESCRIPTION_ALT_TEXT: types.optional(types.boolean, true),
  USE_PRODUCT_LONG_DESCRIPTION_AS_SHORT_DESCRIPTION: types.optional(
    types.boolean,
    true
  ),
  USE_SHOPALIKE_SERVICE: types.optional(types.boolean, false),
  YOTPO_APP_KEY: types.optional(types.string, ''),
  VIP_CUSTOMER_BENEFITS_INFOPAGE_ID: types.optional(types.string, ''),
});

export default GlobalConfig;
