import PropTypes from 'prop-types';
import React from 'react';

import FlagIcon from '../FlagIcon';

const LocaleFlagIcon = ({ locale }) => {
  return <FlagIcon countryCode={locale} />;
};

LocaleFlagIcon.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default LocaleFlagIcon;
