import { getRoot, resolveIdentifier, types } from 'mobx-state-tree';
import LinkType from '../types/LinkType';
import Paths from '../types/Paths';
import SectionStatusType, {
  SectionStatusTypeType,
} from '../types/SectionStatusType';
import generatePath from '../util/generatePath';

const Category = types
  .model('Category', {
    children: types.optional(types.array(types.late(() => Category)), []),
    description_html: types.maybeNull(types.string),
    discount: types.maybeNull(types.number),
    footer_description_html: types.maybeNull(types.string),
    icon: types.maybeNull(types.string),
    id: types.identifierNumber,
    image: types.maybeNull(types.string),
    mobile_image: types.maybeNull(types.string),
    is_new: types.maybeNull(types.boolean),
    link_type: types.maybeNull(types.string),
    name: types.string,
    name_in_navigation: types.maybeNull(types.string),
    parent_id: types.maybeNull(types.number),
    section: types.maybeNull(
      types.model({
        status: SectionStatusTypeType,
        visible_to_crawlers: types.maybeNull(types.boolean),
      })
    ),
    SEO_description: types.maybeNull(types.string),
    SEO_title: types.maybeNull(types.string),
    show_category_filter: types.maybeNull(types.boolean),
    slug: types.string,
  })
  .views((self) => {
    return {
      getNonBlockedChildren(blockedCategoriesDict) {
        return (
          self.children &&
          self.children.filter(
            (category) => !blockedCategoriesDict[category.id]
          )
        );
      },
      get ancestors() {
        const ancestors = [];
        let currentCategory = self;
        while (currentCategory.parentCategory) {
          const parent = currentCategory.parentCategory;
          ancestors.push(parent);
          currentCategory = parent;
        }
        ancestors.reverse();
        return ancestors;
      },
      get breadcrumbs() {
        let breadcrumbSections = self.ancestors.map((ancestor) => {
          return {
            text: ancestor.name,
            url: ancestor.path,
          };
        });

        breadcrumbSections.push({
          text: self.name,
          url: self.path,
        });

        return breadcrumbSections;
      },
      get hierarchy() {
        return [...self.ancestors, self];
      },
      get isIndexableByRobots() {
        // By default we want pages to be indexable.
        // Applicable for ACTIVATE_SECTIONS = false stores.
        if (!self.section) {
          return true;
        }

        if (self.section.status !== SectionStatusType.TEST_MODE) {
          return true;
        }

        return (
          self.section.status === SectionStatusType.TEST_MODE &&
          self.section.visible_to_crawlers
        );
      },
      get mainCategory() {
        return self.hierarchy[0];
      },
      get navigationName() {
        return self.name_in_navigation && self.name_in_navigation.length > 0
          ? self.name_in_navigation
          : self.name;
      },
      get parentCategory() {
        if (self.parent_id !== null && self.parent_id !== undefined) {
          return resolveIdentifier(Category, getRoot(self), self.parent_id);
        }
        return null;
      },
      get path() {
        const categories = self.hierarchy;

        if (
          self.link_type === LinkType.EXTERNAL_LINK ||
          self.link_type === LinkType.INTERNAL_LINK
        ) {
          return self.slug;
        }

        return generatePath(Paths.Category, {
          id: self.id,
          rootProductGroup: categories[0].slug,
          productGroup: categories[1] && categories[1].slug,
          subProductGroup: categories[2] && categories[2].slug,
        });
      },
    };
  });

export default Category;
