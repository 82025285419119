import loadable from '@loadable/component';
import classNames from 'classnames';
import { head, isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Collapse } from 'reactstrap';

import Form from '../../../models/Form';
import FormField, { createFormField } from '../../../models/FormField';
import ProductImageModel from '../../../models/ProductImage';
import AdditionalService from '../../../models/product/AdditionalService';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import CurrencyStore from '../../../store/CurrencyStore';
import UIStore from '../../../store/UIStore';
import ServiceOptionType from '../../../types/ServiceOptionType';
import { formatPrice } from '../../../util/number';
import Icon from '../../common/Icon';
import { initialImageOrigin } from '../../common/ImageLightbox/ImageLightboxProductImage';
import Switch from '../../common/Switch';
import WysiwygContent from '../../common/WysiwygContent';
import CheckboxField from '../../form/CheckboxField';
import FormGroupField from '../../form/FormGroupField';
import RadioField from '../../form/RadioField';
import SelectField from '../../form/SelectField';
import Price from '../Price';
import FullProduct from '../../../models/product/FullProduct';

const ImageLightbox = loadable(() =>
  import(
    /* webpackChunkName: "common" */ '../../common/ImageLightbox/ImageLightboxModal'
  )
);

const LIST_ID = 'product_addition_service';
const LIST_NAME = 'Product Additional Service';

const InputStatus = {
  NONE: 'NONE',
  TEXT_TOO_LONG: 'TEXT_TOO_LONG',
  TEXT_REQUIRED: 'TEXT_REQUIRED',
};

const DEFAULT_SERVICE_OPTIONS_CONTAINER_HEIGHT = 150;
const DEFAULT_SERVICE_OPTIONS_COUNT_BREAK = 10;
const DEFAULT_SERVICE_OPTIONS_ADD_HEIGHT = 15;

const formName = 'AddToCartForm';

const labels = {
  postalCodeRequired: (
    <FormattedMessage
      id="additionalService.postalCodeRequired"
      defaultMessage="Eligible postal code is required for the service"
    />
  ),
  postalCodeIsInvalid: (
    <FormattedMessage
      id="additionalService.postalCodeIsInvalid"
      defaultMessage="Postal code is invalid for this service"
    />
  ),
};

const createImage = (index, product_id, path, alt) => {
  return {
    index,
    product_id,
    path,
    alt,
  };
};

const createService = (
  areaRequired,
  id,
  isSelected,
  options,
  productId,
  text,
  textMaxLength,
  textRequired,
  validPostalCode
) => {
  return {
    areaRequired,
    id,
    isSelected,
    options,
    productId,
    text,
    textMaxLength,
    textRequired,
    validPostalCode,
  };
};

@observer
class ProductAdditionalService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputStatus: InputStatus.NONE,
      lightboxIsOpen: false,
      activeImage: 0,
    };

    this.createTextInputOption();
    this.createRadioButtonOptions();
    this.createCheckboxOptions();
    this.createDropdownOptions();

    this.lightboxInitialState = {};

    props.product.images.forEach((_, index) => {
      this.lightboxInitialState = {
        ...this.lightboxInitialState,
        [index]: {
          focus: false,
          scale: 1,
          origin: initialImageOrigin,
        },
      };
    });
  }

  componentDidMount() {
    this.addDefaultDropdownOption();
    this.addDefaultRadioOption();
  }

  createTextInputOption = () => {
    const { form, service } = this.props;
    const textFieldName = `text_${service.id}`;
    const formField = createFormField({});
    form.setField(textFieldName, formField);
  };

  createRadioButtonOptions = () => {
    const { form, service } = this.props;
    const radioButtonOptions = this.getRadioButtonOptions();

    if (radioButtonOptions.length > 0) {
      const radioFieldName = `radioOptions_${service.id}`;
      const formField = createFormField({});
      formField.setValue(head(radioButtonOptions).product_id);
      form.setField(radioFieldName, formField);
    }
  };

  getRadioButtonOptions = () => {
    const { service } = this.props;
    return service.options.filter(
      (option) => option.type.indexOf(ServiceOptionType.RADIO) !== -1
    );
  };

  createCheckboxOptions = () => {
    const { form, service } = this.props;
    const checkboxOptions = this.getCheckboxOptions();

    if (checkboxOptions.length > 0) {
      const checkboxFieldName = `checkboxOptions_${service.id}`;
      const formField = createFormField({});
      form.setField(checkboxFieldName, formField);
    }
  };

  getCheckboxOptions = () => {
    const { service } = this.props;
    return service.options.filter(
      (option) => option.type.indexOf(ServiceOptionType.CHECKBOX) !== -1
    );
  };

  createDropdownOptions = () => {
    const { form, service } = this.props;
    const dropdownOptions = this.getDropdownOptions(service);

    if (dropdownOptions.length > 0) {
      const dropdownFieldName = `dropdownOptions_${service.id}`;
      const formField = createFormField({});
      formField.setValue(head(dropdownOptions).product_id);
      form.setField(dropdownFieldName, formField);
    }
  };

  getDropdownOptions = () => {
    const { service } = this.props;
    return service.options.filter(
      (option) => option.type.indexOf(ServiceOptionType.DROPDOWN) !== -1
    );
  };

  addDefaultRadioOption = () => {
    const { form, service, selectedOptions } = this.props;

    const radioField = form.fields.get(`radioOptions_${service.id}`);

    if (radioField) {
      this.setState({
        activeImage: this.getImageIndex(selectedOptions.radio),
      });

      radioField.setValue(selectedOptions.radio);
    }
  };

  addDefaultDropdownOption = () => {
    const { form, service, selectedOptions } = this.props;

    const dropdownField = form.fields.get(`dropdownOptions_${service.id}`);

    if (dropdownField) {
      this.setState({
        activeImage: this.getImageIndex(selectedOptions.dropdown),
      });

      dropdownField.setValue(selectedOptions.dropdown);
    }
  };

  toggleLightbox = (product_id = '') => {
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen,
      activeImage: this.getImageIndex(product_id),
    });
  };

  checkbox = (fieldName, props) => {
    const { form, service } = this.props;

    const checkboxField = form.fields.get(`checkboxOptions_${service.id}`);

    if (!checkboxField) {
      return null;
    }

    return (
      checkboxField.active && (
        <CheckboxField
          className={'ProductAdditionalService__CheckboxFieldSet'}
          key={fieldName}
          field={checkboxField}
          formName={formName}
          fieldName={fieldName}
          handleChange={this.handleCheckboxChange}
          {...props}
        />
      )
    );
  };

  dropdown = (fieldName, props) => {
    const { form, service } = this.props;

    const dropdownField = form.fields.get(`dropdownOptions_${service.id}`);

    if (!dropdownField) {
      return null;
    }

    return (
      dropdownField.active && (
        <SelectField
          key={fieldName}
          className={'ProductAdditionalService__DropdownFieldSet'}
          field={dropdownField}
          formName={formName}
          fieldName={fieldName}
          handleChange={this.handleDropdownChange}
          style={{
            width: '100%',
            color: '#333',
          }}
          {...props}
        />
      )
    );
  };

  getSelectOptionLabel = (option) => {
    const { withTax, currencyStore } = this.props;
    const price = option.price && option.price.getPrice(withTax);

    return `${option.selection_text} ${
      price > 0 ? '  ' + formatPrice(price, currencyStore.activeCurrency) : ''
    }`;
  };

  sendSelectItem = (listId, listName) => {
    const { analytics, activeProductId, product } = this.props;
    const analyticsProduct = {
      product,
      activeProductId,
    };

    analytics.productClick({
      listName,
      productList: [analyticsProduct],
      listId,
    });
  };

  handleServiceToggle = () => {
    const {
      configStore,
      activeProductId,
      service,
      text,
      isSelected,
      selectedOptions,
      handleSelectedServices,
    } = this.props;

    handleSelectedServices(
      createService(
        service.requires_area_validation,
        service.id,
        !isSelected,
        selectedOptions,
        service.product_id,
        text,
        service.text_max_length,
        service.text_required,
        service.valid_postal_code
      )
    );

    configStore.analytics.ga4.enabled &&
      !isSelected &&
      activeProductId &&
      this.sendSelectItem(LIST_ID, LIST_NAME);
  };

  getCheckboxValues = (value) => {
    const { selectedOptions } = this.props;
    let currentOptions = [];
    if (selectedOptions && selectedOptions.checkbox) {
      currentOptions = selectedOptions.checkbox.slice();
    }

    const hasValue = currentOptions.indexOf(value);
    if (hasValue !== -1) {
      currentOptions.splice(hasValue, 1);
    } else {
      currentOptions.push(value);
    }

    return currentOptions;
  };

  handleCheckboxChange = (e) => {
    const {
      form,
      text,
      service,
      isSelected,
      selectedOptions,
      handleSelectedServices,
    } = this.props;

    const checkboxField = form.fields.get(`checkboxOptions_${service.id}`);

    let newOptions = {};
    const values = this.getCheckboxValues(e.target.value);

    if (selectedOptions) {
      newOptions = { ...selectedOptions };
    }
    newOptions.checkbox = values;

    checkboxField.setValue(values);
    handleSelectedServices(
      createService(
        service.requires_area_validation,
        service.id,
        isSelected,
        newOptions,
        service.product_id,
        text,
        service.text_max_length,
        service.text_required,
        service.valid_postal_code
      )
    );
  };

  handleDropdownChange = (e) => {
    const {
      form,
      text,
      service,
      isSelected,
      selectedOptions,
      handleSelectedServices,
    } = this.props;

    const dropdownField = form.fields.get(`dropdownOptions_${service.id}`);
    let newOptions = {};

    if (selectedOptions) {
      newOptions = { ...selectedOptions };
    }
    newOptions.dropdown = e.target.value;

    this.setState({
      activeImage: this.getImageIndex(e.target.value),
    });

    dropdownField.setValue(e.target.value);

    handleSelectedServices(
      createService(
        service.requires_area_validation,
        service.id,
        isSelected,
        newOptions,
        service.product_id,
        text,
        service.text_max_length,
        service.text_required,
        service.valid_postal_code
      )
    );
  };

  renderHeaderHtml = () => {
    const { service } = this.props;

    return service.header_html.length > 0 ? (
      <WysiwygContent html={service.header_html} />
    ) : null;
  };

  renderServiceSelectionText = () => {
    const { service } = this.props;

    return (
      <div className="ProductAdditionalService__toggle-description">
        {service.selection_text}
        {service.is_required && (
          <span className="ProductAdditionalService__required-service">*</span>
        )}
      </div>
    );
  };

  renderServiceToggler = () => {
    const { isSelected, service, product, postalCodeField } = this.props;
    const isRequired = service.is_required;
    const postalCodeIsRequired = service.requires_area_validation;
    const ifValidatedService =
      postalCodeIsRequired &&
      postalCodeField &&
      product.getValidatedService({
        postalCode: postalCodeField.value,
        serviceId: service.id,
      })?.valid_postal_code;

    return (
      <div className="ProductAdditionalService__toggle">
        <div className="ProductAdditionalService__toggle-switch">
          <Switch
            checked={isSelected}
            disabled={
              isRequired ||
              (postalCodeIsRequired && (isRequired || !ifValidatedService))
            }
            onChange={this.handleServiceToggle}
          >
            {this.renderServiceSelectionText()}
          </Switch>
        </div>
        {service.price && (
          <div className="ProductAdditionalService__toggle-price">
            {this.renderPrice(service.price)}
          </div>
        )}
      </div>
    );
  };

  renderDescription = () => {
    const { service } = this.props;

    return service.description.length > 0 ? (
      <WysiwygContent html={service.description} />
    ) : null;
  };

  renderPrice = (price) => {
    const { withTax } = this.props;

    if (price.with_tax > 0) {
      return <Price price={price.getPrice(withTax)} />;
    }
    return null;
  };

  renderTextInput = () => {
    const { service } = this.props;

    if (!service.enable_text_input) {
      return null;
    }

    return (
      <div className="ProductAdditionalService__content-text">
        {service.text_input_info && (
          <div className="ProductAdditionalService__text-description">
            {service.text_input_info}
          </div>
        )}
        {this.textarea('text_' + service.id, {
          label: service.text_max_length ? (
            <FormattedMessage
              id="additionalService.typeTextHereLengthSentence"
              defaultMessage="Type your text here. Maximum of {maxLength} characters."
              values={{
                maxLength: service.text_max_length,
              }}
            />
          ) : (
            <FormattedMessage
              id="additionalService.typeTextHereSentence"
              defaultMessage="Type your text here."
            />
          ),
          onChange: this.handleTextChange,
          required: service.text_required,
          invalid: this.state.inputStatus !== InputStatus.NONE,
        })}
      </div>
    );
  };

  textarea = (fieldName, props) => {
    const { text, form, service } = this.props;

    const textField = form.fields.get(`text_${service.id}`);

    if (!textField) {
      return null;
    }

    return (
      textField.active && (
        <FormGroupField
          field={textField}
          formName={formName}
          fieldName={fieldName}
          type="textarea"
          value={text}
          className="ProductAdditionalService_textarea"
          {...props}
        />
      )
    );
  };

  handleTextChange = (e) => {
    const { service, isSelected, selectedOptions, handleSelectedServices } =
      this.props;

    if (service.text_required && e.target.value.length === 0) {
      this.setState({
        inputStatus: InputStatus.TEXT_REQUIRED,
      });
    } else if (
      service.text_max_length &&
      e.target.value.length > service.text_max_length
    ) {
      this.setState({
        inputStatus: InputStatus.TEXT_TOO_LONG,
      });
    } else {
      if (this.state.inputStatus !== InputStatus.NONE) {
        this.setState({
          inputStatus: InputStatus.NONE,
        });
      }
    }

    handleSelectedServices(
      createService(
        service.requires_area_validation,
        service.id,
        isSelected,
        selectedOptions,
        service.product_id,
        e.target.value,
        service.text_max_length,
        service.text_required,
        service.valid_postal_code
      )
    );
  };

  getMatchingDropdownOption = (option) => {
    const { selectedOptions } = this.props;

    return (
      option.type === ServiceOptionType.DROPDOWN &&
      option.product_id === selectedOptions.dropdown.toString()
    );
  };

  getDropdownOptionImage = () => {
    const { service, selectedOptions } = this.props;
    let filteredOption;

    if (selectedOptions.dropdown === null) {
      filteredOption = head(
        service.options.filter(
          (option) => option.type === ServiceOptionType.DROPDOWN
        )
      );
    } else {
      filteredOption = head(
        service.options.filter(this.getMatchingDropdownOption)
      );
    }

    return filteredOption &&
      filteredOption.image &&
      filteredOption.image.length > 0
      ? createImage(
          0,
          filteredOption.product_id,
          filteredOption.image,
          filteredOption.selection_text
        )
      : null;
  };

  hasContent = () => {
    const { service } = this.props;
    return !!(
      service.description ||
      service.enable_text_input ||
      service.options.length > 0
    );
  };

  hasImages = () => {
    const { service } = this.props;

    if (service.options.length === 0) {
      return false;
    }

    return service.options.filter((option) => !!option.image).length > 0;
  };

  getImageIndex = (product_id) => {
    const images = this.getImages();
    let value = 0;

    images.forEach((image, index) => {
      if (image.product_id.toString() === product_id.toString()) {
        value = index;
      }
    });
    return value;
  };

  getImages = () => {
    const { service } = this.props;

    if (service.options.length === 0) {
      return null;
    }

    return service.options
      .filter((option) => {
        return !!(option.image && option.image.length > 0);
      })
      .map((option, index) => {
        return createImage(
          index,
          option.product_id,
          option.image,
          option.selection_text
        );
      });
  };

  renderSelectedDropdown = () => {
    const { service, withTax, selectedOptions } = this.props;
    if (!selectedOptions.dropdown) {
      return null;
    }

    const filteredOption = head(
      service.options.filter(this.getMatchingDropdownOption)
    );

    if (!filteredOption) {
      return null;
    }

    const price =
      filteredOption.price && filteredOption.price.getPrice(withTax);

    if (!price || price <= 0) {
      return null;
    }

    return (
      <div className="ProductAdditionalService__dropdown-info">
        <FormattedMessage
          id="additionalService.optionPriceSentence"
          defaultMessage="The price for this option is {price}"
          values={{
            price: (
              <b>
                <Price price={price} />
              </b>
            ),
          }}
        />
      </div>
    );
  };

  renderDropdownImage = () => {
    const image = this.getDropdownOptionImage();

    if (!image) {
      return null;
    }

    return (
      <div
        className="ProductAdditionalService__dropdown-image"
        onClick={() => this.toggleLightbox.bind(this, image.product_id)}
      >
        <img src={image.path} alt={image.alt} loading="lazy" />
      </div>
    );
  };

  getOptionLabel = (option) => {
    const { withTax } = this.props;

    return (
      <div className="ProductAdditionalService__option-label">
        <div className="ProductAdditionalService__option-label-text">
          {option.selection_text}
        </div>
        {option.price && option.price.with_tax > 0 && (
          <div className="ProductAdditionalService__option-label-price">
            <Price price={option.price.getPrice(withTax)} />
          </div>
        )}
        {this.hasImages() && (
          <div
            className="ProductAdditionalService__option-label-image"
            onClick={() => this.toggleLightbox.bind(this, option.product_id)}
          >
            {option.image && (
              <img
                src={option.image}
                alt={option.selection_text}
                loading="lazy"
              />
            )}
          </div>
        )}
      </div>
    );
  };

  handleRadioChange = (e) => {
    const {
      text,
      service,
      isSelected,
      selectedOptions,
      handleSelectedServices,
      form,
    } = this.props;

    const radioField = form.fields.get(`radioOptions_${service.id}`);
    let newOptions = {};

    if (selectedOptions) {
      newOptions = { ...selectedOptions };
    }
    newOptions.radio = e.target.value;

    this.setState({
      activeImage: this.getImageIndex(e.target.value),
    });

    radioField.setValue(e.target.value);

    handleSelectedServices(
      createService(
        service.requires_area_validation,
        service.id,
        isSelected,
        newOptions,
        service.product_id,
        text,
        service.text_max_length,
        service.text_required,
        service.valid_postal_code
      )
    );
  };

  radio = (fieldName, props) => {
    const { form, service } = this.props;
    const radioField = form.fields.get(`radioOptions_${service.id}`);

    if (!radioField) {
      return null;
    }

    return (
      radioField.active && (
        <RadioField
          className={'ProductAdditionalService__RadioFieldSet'}
          inline={false}
          field={radioField}
          formName={formName}
          fieldName={fieldName}
          handleChange={this.handleRadioChange}
          {...props}
        />
      )
    );
  };

  getCSS = (services, className) => {
    // Need to somehow calculate height dynamically to divide fields into two columns.
    let height = DEFAULT_SERVICE_OPTIONS_CONTAINER_HEIGHT;
    if (services.length > DEFAULT_SERVICE_OPTIONS_COUNT_BREAK) {
      const addedHeight = services.length - DEFAULT_SERVICE_OPTIONS_COUNT_BREAK;
      height =
        DEFAULT_SERVICE_OPTIONS_CONTAINER_HEIGHT +
        addedHeight * DEFAULT_SERVICE_OPTIONS_ADD_HEIGHT;
    }

    return `${className}
    {
      flex-flow: column wrap !important;
      align-content: flex-start;
      height: ${height}px
    }`;
  };

  renderServiceOptions = () => {
    const { form, service, uiStore } = this.props;

    const dropdownField = form.fields.get(`dropdownOptions_${service.id}`);
    const checkboxField = form.fields.get(`checkboxOptions_${service.id}`);
    const radioField = form.fields.get(`radioOptions_${service.id}`);

    if (service.options.length === 0) {
      return null;
    }

    const radio = radioField ? (
      <div className="ProductAdditionalService__radio-option">
        {!uiStore.isSmallest && (
          <style>
            {this.getCSS(
              service.options,
              '.ProductAdditionalService__radio-option-field'
            )}
          </style>
        )}
        {this.radio(`radioOptions_${service.id}`, {
          options: service.options
            .filter((option) => option.type === ServiceOptionType.RADIO)
            .map((option) => ({
              value: String(option.product_id),
              label: this.getOptionLabel(option),
            })),
        })}
      </div>
    ) : null;

    const checkbox = checkboxField ? (
      <div className="ProductAdditionalService__checkbox-option">
        {!uiStore.isSmallest && (
          <style>
            {this.getCSS(
              service.options,
              '.ProductAdditionalService__checkbox-option-field'
            )}
          </style>
        )}
        {this.checkbox(`checkboxOptions_${service.id}`, {
          options: service.options
            .filter((option) => option.type === ServiceOptionType.CHECKBOX)
            .map((option) => ({
              value: String(option.product_id),
              label: this.getOptionLabel(option),
            })),
        })}
      </div>
    ) : null;

    const dropdown = dropdownField ? (
      <div className="ProductAdditionalService__dropdown">
        {!uiStore.isSmallest && (
          <style>
            {this.getCSS(
              service.options,
              '.ProductAdditionalService__dropdown-option-field'
            )}
          </style>
        )}
        {this.dropdown(`dropdownOptions_${service.id}`, {
          options: service.options
            .filter((option) => option.type === ServiceOptionType.DROPDOWN)
            .map((option) => ({
              value: String(option.product_id),
              label: this.getSelectOptionLabel(option),
            })),
        })}
      </div>
    ) : null;

    return (
      <>
        {radio}
        {checkbox}
        {dropdown}
        {this.renderSelectedDropdown()}
        {this.renderDropdownImage()}
      </>
    );
  };

  showToggle = () => {
    const { service } = this.props;
    const onlyDropdown = !!!service.options.filter(
      (option) => option.type !== ServiceOptionType.DROPDOWN
    ).length;

    return !(
      service.is_required &&
      service.price &&
      service.price.with_tax === 0 &&
      onlyDropdown
    );
  };

  renderError = () => {
    const { inputStatus } = this.state;
    let message = null;

    switch (inputStatus) {
      case InputStatus.TEXT_REQUIRED:
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="validation.additionalService.TEXT_REQUIRED"
              defaultMessage="Additional service requires text input."
            />
          </Alert>
        );
        break;
      case InputStatus.TEXT_TOO_LONG:
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="validation.additionalService.TEXT_TOO_LONG"
              defaultMessage="Too many characters."
            />
          </Alert>
        );
        break;
      case InputStatus.NONE:
      default:
        break;
    }
    return message;
  };

  renderLightbox = () => {
    const { service, product } = this.props;
    const { activeImage, lightboxIsOpen } = this.state;
    const images = this.getImages();

    if (
      !service.product_id ||
      !images ||
      images.length === 0 ||
      !lightboxIsOpen
    ) {
      return null;
    }

    const lightboxImages = images.map((image, index) => {
      return ProductImageModel.create({
        id: index,
        product_id: service.product_id,
        sizes: {
          full: image.path,
        },
        description: '',
      });
    });

    this.lightboxInitialState.selectedImage = activeImage;

    return (
      <ImageLightbox
        initialState={this.lightboxInitialState}
        mainImageIndex={activeImage}
        lightboxIsOpen={lightboxIsOpen}
        product={product}
        onClick={this.toggleLightbox}
        images={lightboxImages}
      />
    );
  };

  postalCodeValidationState = () => {
    const { product, service, postalCodeField } = this.props;
    const postalCodeIsRequired = service.requires_area_validation;

    return (
      postalCodeField &&
      postalCodeIsRequired &&
      product.getValidatedService({
        postalCode: postalCodeField.value,
        serviceId: service.id,
      })?.valid_postal_code
    );
  };

  renderPostalCodeRequiredText = () => {
    const validPostalCode = this.postalCodeValidationState();

    return (
      <div
        className={classNames('ProductAdditionalService__information-text', {
          'ProductAdditionalService__information-text--invalid':
            validPostalCode === false,
        })}
      >
        <Icon
          name={
            validPostalCode === false ? 'exclamation-triangle' : 'info-circle'
          }
        />
        {validPostalCode === false
          ? labels.postalCodeIsInvalid
          : labels.postalCodeRequired}
      </div>
    );
  };

  render() {
    const { service, isSelected } = this.props;
    const postalCodeIsRequired = service.requires_area_validation;

    if (!service) {
      return null;
    }

    const validPostalCode = this.postalCodeValidationState();

    return (
      <div className="ProductAdditionalService">
        {this.renderHeaderHtml()}
        {this.showToggle()
          ? this.renderServiceToggler()
          : this.renderServiceSelectionText()}
        {this.hasContent() && (
          <Collapse isOpen={isSelected}>
            <div className="ProductAdditionalService__content">
              {this.renderDescription()}
              {this.renderTextInput()}
              {this.renderServiceOptions()}
              {this.renderError()}
              {this.renderLightbox()}
            </div>
          </Collapse>
        )}
        {postalCodeIsRequired &&
          !validPostalCode &&
          this.renderPostalCodeRequiredText()}
      </div>
    );
  }
}

ProductAdditionalService.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  form: modelOf(Form).isRequired,
  product: modelOf(FullProduct).isRequired,
  service: modelOf(AdditionalService).isRequired,
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  withTax: PropTypes.bool.isRequired,
  selectedOptions: PropTypes.object.isRequired,
  handleSelectedServices: PropTypes.func.isRequired,
  postalCodeField: modelOf(FormField),
  activeProductId: PropTypes.string,
};

export default inject((stores, props) => ({
  configStore: stores.configStore,
  currencyStore: stores.currencyStore,
  uiStore: stores.uiStore,
  analytics: stores.analytics,
  withTax: isNil(props.withTax)
    ? stores.accountStore.showPricesWithTax
    : props.withTax,
}))(ProductAdditionalService);
