import { useCallback } from 'react';
import TranslatedImageType from '../../../types/TranslatedImageType';

// Standard bootstrap scss input height is 35px
const NAVIGATION_LOGO_SCROLL_HEIGHT = 35;

const useNavigationLogo = ({
  accountStore,
  translationStore,
  useScrollingHeader,
}) => {
  const getTranslatedImage = (translatedImageType) => {
    return translationStore.translatedImages.find(
      (image) => image.image_key === translatedImageType
    );
  };

  const hasAccountLogo = () => !!accountStore.account?.logo;

  const getAccountLogo = () => accountStore.account?.userLogo;

  const getScrollLogo = () =>
    getTranslatedImage(TranslatedImageType.STORE_SCROLL_LOGO);

  const getStoreLogo = () => getTranslatedImage(TranslatedImageType.STORE_LOGO);

  const getTranslatedLogo = useCallback(() => {
    const storeLogo = getStoreLogo();
    const scrollLogo = getScrollLogo();

    if (hasAccountLogo()) {
      return getAccountLogo();
    } else if (useScrollingHeader && scrollLogo) {
      return scrollLogo;
    } else if (storeLogo) {
      const effectiveStoreLogo = {
        ...storeLogo,
        height: useScrollingHeader
          ? NAVIGATION_LOGO_SCROLL_HEIGHT
          : storeLogo.height,
      };

      return effectiveStoreLogo;
    } else {
      return null;
    }
  }, [useScrollingHeader]);

  return [getTranslatedLogo];
};

export default useNavigationLogo;
