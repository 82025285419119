import { maxBy, minBy, some } from 'lodash';
import { types } from 'mobx-state-tree';

import ChildProduct from '../ChildProduct';
import ProductProperty from './ProductProperty';

const ProductMulti = types
  .model('ProductMulti', {
    properties: types.array(ProductProperty),
    children: types.array(types.late(ChildProduct)),
  })
  .actions((self) => {
    return {
      setChildren(children) {
        self.children = children;
      },
    };
  })
  .views((self) => {
    return {
      childrenIncludesId: (id) => {
        return self.children.slice().find((child) => child.id === id);
      },
      findChild(activeProductId) {
        return self.children.find((child) => child.id === activeProductId);
      },
      getChildIdentifiers() {
        return self.children.map((child) => child.id);
      },
      getChildWithBestDiscount() {
        return maxBy(self.children, 'price_info.discount_percentage');
      },
      getMinMaxPrice(withTax) {
        const getPrice = (childProduct) => {
          if (!childProduct.price_info) {
            return;
          }

          return childProduct.price_info.getPrice(withTax);
        };

        const minProduct = minBy(self.children, getPrice);
        const maxProduct = maxBy(self.children, getPrice);

        return {
          min: minProduct ? minProduct.price_info : 0.0,
          max: maxProduct ? maxProduct.price_info : 0.0,
        };
      },
      getPropertyElementsById(elementId) {
        return self.properties.find((property) => property.id === elementId);
      },
      getSecondaryPriceRange(withTax) {
        return self.getMinMaxPrice(withTax);
      },
      hasSomePrice() {
        const getPrice = (childProduct) => {
          if (!childProduct.price_info) {
            return false;
          }
          return childProduct.price_info.getPrice(true) > 0;
        };
        return self.children.some(getPrice);
      },
      hasPriceRange(withTax) {
        const priceRange = self.getMinMaxPrice(withTax);

        if (priceRange && priceRange.min && priceRange.max) {
          return priceRange.min !== priceRange.max;
        }

        return false;
      },
      get priceRange() {
        return self.getMinMaxPrice();
      },
    };
  });

export default ProductMulti;
