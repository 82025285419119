import Cookies from 'js-cookie';

/**
 * @typedef UserData
 * @type {Object}
 * @property {string|string[]} em
 * @property {string|string[]} ph
 * @property {string|string[]} fn
 * @property {string|string[]} ln
 * @property {string|string[]} ct
 * @property {string|string[]} zp
 * @property {string|string[]} country
 * @property {string} fbc
 * @property {string} fbp
 */

class UserData {
  #accountStore;
  #orderStore;
  #activeCountry = null;

  constructor(accountStore, orderStore, countryStore) {
    this.#accountStore = accountStore;
    this.#orderStore = orderStore;
    this.#activeCountry = countryStore.activeCountry;
  }

  /**
   *
   * @param {string} country
   * @returns {UserData}
   */
  getUserData = () => {
    const country = this.#activeCountry?.iso_code_2 ?? null;

    let userData = {
      country,
      fbc: this.#getFBCCookie(),
      fbp: this.#getFBPCookie(),
    };

    userData = {
      ...this.#getUserInfo(),
      ...userData,
    };

    return userData;
  };

  /**
   *
   * @returns {UserData}
   */
  #getUserInfo = () => {
    let userInfo = this.#getAccountInfo();
    userInfo = this.#getOrderAccountInfo(userInfo);

    return userInfo;
  };

  #getAccountInfo = () => {
    const { account } = this.#accountStore;

    if (!account?.personalInfo) {
      return;
    }

    let userInfo = {
      em: account.personalInfo.email,
      ph: account.personalInfo.phoneNumber,
    };

    if (typeof account.personalInfo === 'object') {
      return {
        fn: account.personalInfo.firstName,
        ln: account.personalInfo.lastName,
        ...userInfo,
      };
    }

    return {
      fn: account.personalInfo.firstName,
      ...userInfo,
    };
  };

  /**
   *
   * @param {UserData} userInfo
   * @returns {UserData}
   */
  #getOrderAccountInfo = (userInfo) => {
    const { currentOrder } = this.#orderStore;
    const customerInfo = currentOrder?.customerInfo;

    if (!customerInfo) {
      return userInfo;
    }

    userInfo = {
      ...userInfo,
      em: customerInfo.email,
      ph: customerInfo.telephone,
      ct: customerInfo.city,
      zp: customerInfo.postalCode,
    };

    if (customerInfo.company) {
      return {
        fn: customerInfo.company,
        ...userInfo,
      };
    }

    return {
      fn: customerInfo.firstname,
      ln: customerInfo.lastname,
      ...userInfo,
    };
  };

  #getFBPCookie = () => {
    const fbp = Cookies.get('_fbp');
    if (fbp) {
      return fbp;
    }

    return null;
  };

  #getFBCCookie = () => {
    if (typeof document === 'undefined') {
      return;
    }

    const fbc = Cookies.get('_fbc');

    if (fbc) {
      return fbc;
    }

    return null;
  };
}

export default UserData;
