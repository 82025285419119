import { defineMessages } from 'react-intl';

export default defineMessages({
  abbrDayUnit: {
    id: 'global.abbrDayUnit',
    defaultMessage: 'd',
  },
  abbrHourUnit: {
    id: 'global.abbrHourUnit',
    defaultMessage: 'h',
  },
  acceptTitle: {
    id: 'global.acceptTitle',
    defaultMessage: 'Accept',
  },
  accountChangeClientSentence: {
    id: 'global.accountChangeClientSentence',
    defaultMessage: 'Log in as a client',
  },
  accountCreateSentence: {
    id: 'global.accountCreateSentence',
    defaultMessage: 'Create an account',
  },
  accountLogOutSentence: {
    id: 'global.accountLogOutSentence',
    defaultMessage: 'Log out',
  },
  additionalLinksTitle: {
    id: 'global.additionalLinksTitle',
    defaultMessage: 'Quick links',
  },
  additionalServicesTitle: {
    id: 'global.additionalServicesTitle',
    defaultMessage: 'Additional Services',
  },
  addNewAddressTitle: {
    id: 'global.addNewAddressTitle',
    defaultMessage: 'Add New Address',
  },
  addNewUserTitle: {
    id: 'global.addNewUserTitle',
    defaultMessage: 'Add New User',
  },
  addressBookTitle: {
    id: 'global.addressBookTitle',
    defaultMessage: 'Address Book',
  },
  addToCartButton: {
    id: 'global.addToCartButton',
    defaultMessage: 'Add to Cart',
  },
  addToProposalButton: {
    id: 'global.addToProposalButton',
    defaultMessage: 'Add to Proposal',
  },
  allowPartialDeliverySentence: {
    id: 'global.allowPartialDeliverySentence',
    defaultMessage: 'Allow delivery even though not every product is available',
  },
  allowShippingChangeSentence: {
    id: 'recurringOrder.allowShippingChangeSentence',
    defaultMessage:
      'Allow automatic change of shipping method when current method is not possible. (Might change shipping costs)',
  },
  allowUserCreateAddressLabel: {
    id: 'global.allowUserCreateAddressLabel',
    defaultMessage: 'Allow user to create addresses',
  },
  allowUserMakePurchaseLabel: {
    id: 'global.allowUserMakePurchaseLabel',
    defaultMessage: 'Allow user to make purchases',
  },
  AmountAndInTotal: {
    id: 'global.AmountAndInTotal',
    defaultMessage: 'Amount / In Total',
  },
  availabilityTitle: {
    id: 'global.availabilityTitle',
    defaultMessage: 'Availability',
  },
  backToStore: {
    id: 'global.backToStore',
    defaultMessage: 'Back to the store',
  },
  ascendingTitle: {
    id: 'global.ascendingTitle',
    defaultMessage: 'Ascending',
  },
  balanceUser: {
    id: 'global.balanceUser',
    defaultMessage: 'Client account',
  },
  balanceUserNameTitle: {
    id: 'global.balanceUserNameTitle',
    defaultMessage: "User's Name",
  },
  balanceUserAccountInfo: {
    id: 'global.balanceUserAccountInfo',
    defaultMessage: 'Client information',
  },
  balanceUserAccountBalanceInfo: {
    id: 'global.balanceUserAccountBalanceInfo',
    defaultMessage: 'Balance orders',
  },
  balanceUserAccountStatus: {
    id: 'global.balanceUserAccountStatus',
    defaultMessage: 'Status',
  },
  balanceUserAccountPaymentWidgetError: {
    id: 'global.balanceUserAccountPaymentWidgetError',
    defaultMessage:
      'Sorry, an error occurred with your payment.Try refreshing the page and try again.If the problem persists, please contact our customer service.',
  },
  balanceUserCurrentBalance: {
    id: 'global.balanceUserCurrentBalance',
    defaultMessage: 'Balance',
  },
  balanceUserNewBalance: {
    id: 'global.balanceUserNewBalance',
    defaultMessage: 'Add balance',
  },
  balanceUserBalanceValidationError: {
    id: 'global.balanceUserBalanceValidationError',
    defaultMessage: 'Only even numbers are accepted.',
  },
  balanceUserNoBalancePurchases: {
    id: 'global.balanceUserNoBalancePurchases',
    defaultMessage: 'No balance purchases',
  },
  balanceUserPaymentTotal: {
    id: 'global.balanceUserPaymentTotal',
    defaultMessage: 'Total',
  },
  balanceUserPaymentHistoryOrderDate: {
    id: 'global.balanceUserPaymentHistoryOrderDate',
    defaultMessage: 'Date',
  },
  brandsTitle: {
    id: 'global.brandsTitle',
    defaultMessage: 'Brands',
  },
  businessIdTitle: {
    id: 'global.businessIdTitle',
    defaultMessage: 'Business ID',
  },
  businessIdHelp: {
    id: 'global.businessIdHelp',
    defaultMessage:
      'If you have forgotten your Business ID, you can find it by clicking {link}',
  },
  buttonInfo: {
    id: 'global.buttonInfo',
    defaultMessage:
      'You can also reserve and pick up the product from our store',
  },
  campaignBoxTitle: {
    id: 'global.campaignBoxTitle',
    defaultMessage: 'Enter the code here',
  },
  cancelTitle: {
    id: 'global.cancelTitle',
    defaultMessage: 'Cancel',
  },
  cartContents: {
    id: 'global.cartContents',
    defaultMessage: 'Cart contents',
  },
  categoriesTitle: {
    id: 'global.categoriesTitle',
    defaultMessage: 'Categories',
  },
  checkedTitle: {
    id: 'global.checkedTitle',
    defaultMessage: 'Searched',
  },
  checkoutOpenCartSentence: {
    id: 'global.checkoutOpenCartSentence',
    defaultMessage: 'Open cart',
  },
  checkoutOpenProposalOfferSentence: {
    id: 'global.checkoutOpenProposalOfferSentence',
    defaultMessage: 'Open proposal offer',
  },
  checkoutTitle: {
    id: 'global.checkout',
    defaultMessage: 'Checkout',
  },
  chooseTitle: {
    id: 'global.chooseTitle',
    defaultMessage: 'Choose',
  },
  cityTitle: {
    id: 'global.cityTitle',
    defaultMessage: 'City',
  },
  cloneOrder: {
    id: 'global.cloneOrder',
    defaultMessage: 'Repeat order',
  },
  closeTitle: {
    id: 'global.closeTitle',
    defaultMessage: 'Close',
  },
  clearTitle: {
    id: 'global.clearTitle',
    defaultMessage: 'Clear',
  },
  cartEmptySentence: {
    id: 'global.cartEmptySentence',
    defaultMessage: 'Your cart is empty',
  },
  companyTitle: {
    id: 'global.companyTitle',
    defaultMessage: 'Company',
  },
  confirmAddToCartTitle: {
    id: 'global.confirmAddToCartTitle',
    defaultMessage: 'Do you want to add products to the cart?',
  },
  confirmPasswordTitle: {
    id: 'global.confirmPasswordTitle',
    defaultMessage: 'Confirm Password',
  },
  confirmRemoveTitle: {
    id: 'global.confirmRemoveTitle',
    defaultMessage: 'Are you sure?',
  },
  contactPersonFirstNameTitle: {
    id: 'global.contactPersonFirstNameTitle',
    defaultMessage: 'Contact Person First Name',
  },
  contactPersonLastNameTitle: {
    id: 'global.contactPersonLastNameTitle',
    defaultMessage: 'Contact Person Last Name',
  },
  continueShopping: {
    id: 'global.continueShopping',
    defaultMessage: 'Continue Shopping',
  },
  continueTitle: {
    id: 'global.continue',
    defaultMessage: 'Continue',
  },
  copyrightContent: {
    id: 'global.copyrightContent',
    defaultMessage: 'Powered by Confirma E-COM',
  },
  countryTitle: {
    id: 'global.countryTitle',
    defaultMessage: 'Country / area',
  },
  couponFreeShippingSentence: {
    id: 'global.couponFreeShippingSentence',
    defaultMessage: 'Receive free delivery!',
  },
  couponDiscountOfferSentence: {
    id: 'global.couponDiscountOfferSentence',
    defaultMessage: 'Receive a {offer} discount coupon!',
  },
  couponLoginOfferSentence: {
    id: 'global.couponLoginOfferSentence',
    defaultMessage:
      'Join as a registered customer now or sign in and you will instantly receive a {offer} coupon.',
  },
  couponLoginFreeDeliverySentence: {
    id: 'global.couponLoginFreeDeliverySentence',
    defaultMessage:
      'Join as a registered customer now or sign in and you will instantly receive a coupon for free shipping of your purchase.',
  },
  couponMinimumOrderSentence: {
    id: 'global.couponMinimumOrderSentence',
    defaultMessage: 'Minimum order {minimumOrder}.',
  },
  couponRegisterOfferSentence: {
    id: 'global.couponRegisterOfferSentence',
    defaultMessage:
      'Register as our customer now and get a {offer} discount coupon.',
  },
  couponRegisterFreeDeliverySentence: {
    id: 'global.couponRegisterFreeDeliverySentence',
    defaultMessage:
      'Register as our customer now and get a discount coupon. The coupon can be used for free delivery.',
  },
  couponReviewValueSentence: {
    id: 'global.couponReviewValueSentence',
    defaultMessage: 'Value: {value}',
  },
  couponValidUntilSentence: {
    id: 'global.couponValidUntilSentence',
    defaultMessage: 'Offer valid until {offerExpiry}.',
  },
  creditAccountTitle: {
    id: 'global.creditAccountTitle',
    defaultMessage: 'Credit account',
  },
  currencyLabel: {
    id: 'global.currencyLabel',
    defaultMessage: 'Currency: {currency}',
  },
  currentPasswordTitle: {
    id: 'global.currentPasswordTitle',
    defaultMessage: 'Current Password',
  },
  customerProductsTitle: {
    id: 'global.customerProductsTitle',
    defaultMessage: 'Contract Products',
  },
  dateUpdatedTitle: {
    id: 'global.dateUpdatedTitle',
    defaultMessage: 'Last updated',
  },
  deliveryCountryLabel: {
    id: 'global.deliveryCountryLabel',
    defaultMessage: 'Delivery to: {country}',
  },
  deliveryDateTitle: {
    id: 'global.deliveryDateTitle',
    defaultMessage: 'Delivery date',
  },
  deliveryStartingSentence: {
    id: 'global.deliveryStartingSentence',
    defaultMessage: 'Delivery costs starting from {price}',
  },
  deliveryStartingAbridged: {
    id: 'global.deliveryStartingAbridged',
    defaultMessage: 'Shipping starting from',
  },
  descendingTitle: {
    id: 'global.descendingTitle',
    defaultMessage: 'Descending',
  },
  eanLabel: {
    id: 'global.eanLabel',
    defaultMessage: 'EAN: {ean}',
  },
  editTitle: {
    id: 'global.editTitle',
    defaultMessage: 'Edit',
  },
  emailTitle: {
    id: 'global.emailTitle',
    defaultMessage: 'Email',
  },
  emailOrUsernameTitle: {
    id: 'global.emailOrUsernameTitle',
    defaultMessage: 'Username or Email',
  },
  endDateTitle: {
    id: 'global.endDateTitle',
    defaultMessage: 'End date',
  },
  errorContent: {
    id: 'global.errorContent',
    defaultMessage:
      'Please refresh the page. If the problem continues please try again later.',
  },
  errorContentFront: {
    id: 'global.errorContentFront',
    defaultMessage:
      'Please refresh the page. If the problem continues please go to frontpage.',
  },
  errorFrontpage: {
    id: 'global.errorFrontpage',
    defaultMessage: 'To frontpage',
  },
  errorHeader: {
    id: 'global.errorHeader',
    defaultMessage: "We're sorry - something's gone wrong",
  },
  errorRefresh: {
    id: 'global.errorRefresh',
    defaultMessage: 'Refresh page',
  },
  errorReport: {
    id: 'global.errorReport',
    defaultMessage: 'Send report',
  },
  exceptionalAvailability: {
    id: 'global.exceptionalAvailability',
    defaultMessage: '(Exceptional availability)',
  },
  extraIdLabel: {
    id: 'global.extraIdLabel',
    defaultMessage: 'Extra ID: {extraId}',
  },
  fileTitle: {
    id: 'global.fileTitle',
    defaultMessage: 'File',
  },
  firstNameTitle: {
    id: 'global.firstNameTitle',
    defaultMessage: 'First Name',
  },
  formattedMonths: {
    id: 'global.formattedMonths',
    defaultMessage: '{months, plural, one {# month} other {# months}}',
  },
  formattedYears: {
    id: 'global.formattedYears',
    defaultMessage: '{years, plural, one {# year} other {# years}}',
  },
  formFailEmail: {
    id: 'global.formFailEmail',
    defaultMessage: 'Email is not valid',
  },
  formFail422: {
    id: 'global.formFail422',
    defaultMessage:
      'The information you entered is not valid. Please check the form.',
  },
  freeDeliverySentence: {
    id: 'global.freeDeliverySentence',
    defaultMessage: 'Free delivery',
  },
  freeQuantity: {
    id: 'global.freeQuantity',
    defaultMessage: 'Free Quantity: {freeQuantity} {stockUnit}',
  },
  fullNameTitle: {
    id: 'global.fullNameTitle',
    defaultMessage: 'Name',
  },
  hasStartingPriceSentence: {
    id: 'global.hasStartingPriceSentence',
    defaultMessage: 'Final shipping costs will be calculated in the checkout',
  },
  hereSentence: {
    id: 'global.here',
    defaultMessage: 'here',
  },
  homeTitle: {
    id: 'global.homeTitle',
    defaultMessage: 'Home',
  },
  imageTitle: {
    id: 'global.imageTitle',
    defaultMessage: 'image',
  },
  infoCallPhone: {
    id: 'global.infoCallPhone',
    defaultMessage: 'Call {phone}',
  },
  infoSendEmail: {
    id: 'global.infoSendEmail',
    defaultMessage: 'Send email',
  },
  infoTitle: {
    id: 'global.infoTitle',
    defaultMessage: 'Info',
  },
  isActiveLabel: {
    id: 'global.isActiveLabel',
    defaultMessage: 'Active username',
  },
  isCorporateCustomerSentence: {
    id: 'global.isCorporateCustomerSentence',
    defaultMessage: 'Company information',
  },
  isOpen: {
    id: 'global.isOpen',
    defaultMessage: 'Customer service is open Mon - Fri {timespan}',
  },
  itemCount: {
    id: 'global.itemCount',
    defaultMessage: '{count, plural, one {# product} other {# products}}',
  },
  itemsInCart: {
    id: 'global.itemsInCart',
    defaultMessage: '{itemCount} in cart.',
  },
  itemsInProposal: {
    id: 'global.itemsInProposal',
    defaultMessage: '{itemCount} in proposal.',
  },
  klarnaExpressCheckout: {
    id: 'global.klarnaExpressCheckout',
    defaultMessage: 'Klarna. Express Checkout',
  },
  languageLabel: {
    id: 'global.languageLabel',
    defaultMessage: 'Language: {language}',
  },
  lastNameTitle: {
    id: 'global.lastNameTitle',
    defaultMessage: 'Last Name',
  },
  linkTitle: {
    id: 'global.linkTitle',
    defaultMessage: 'Links',
  },
  loading: {
    id: 'global.loading',
    defaultMessage: 'Loading...',
  },
  logInSentence: {
    id: 'global.logInSentence',
    defaultMessage: 'Log in',
  },
  loginInformationTitle: {
    id: 'global.loginInformationTitle',
    defaultMessage: 'Login Information',
  },
  lowOrderFeeNoTaxSentence: {
    id: 'global.lowOrderFeeNoTaxSentence',
    defaultMessage: 'Low order fee of {amount} for orders less than {limit}',
  },
  lowOrderFeeSentence: {
    id: 'global.lowOrderFeeSentence',
    defaultMessage:
      'Low order fee of {amount} ({amountWithoutTax}) for orders less than {limit} ({limitwithoutTax} without VAT)',
  },
  manufacturerCodeLabel: {
    id: 'global.manufacturerCodeLabel',
    defaultMessage: 'Manufacturer part number: {manufacturerCode}',
  },
  mostPopularTitle: {
    id: 'global.mostPopularTitle',
    defaultMessage: 'Most Popular',
  },
  messageTitle: {
    id: 'global.messageTitle',
    defaultMessage: 'Message',
  },
  modifyOrderNotify: {
    id: 'global.modifyOrderNotify',
    defaultMessage: 'Modifying existing orders may change shipping costs.',
  },
  myAccount: {
    id: 'global.myAccount',
    defaultMessage: 'Overview',
  },
  myAccountChangePassword: {
    id: 'global.myAccountChangePassword',
    defaultMessage: 'Change password',
  },
  myAccountInfo: {
    id: 'global.myAccountInfo',
    defaultMessage: 'Personal information',
  },
  myAccountNoOrders: {
    id: 'global.myAccountNoOrders',
    defaultMessage: 'You have not ordered anything yet.',
  },
  myAccountNoOrdersFound: {
    id: 'global.myAccountNoOrdersFound',
    defaultMessage: 'No orders found.',
  },
  myAccountNoPurchases: {
    id: 'global.myAccountNoPurchases',
    defaultMessage: 'No purchases found.',
  },
  myAccountTitle: {
    id: 'global.myAccountTitle',
    defaultMessage: 'My Account',
  },
  myAccountUserTitle: {
    id: 'global.myAccountUserTitle',
    defaultMessage: 'My Account: {user}',
  },
  myCustomerAccountTitle: {
    id: 'global.myCustomerAccountTitle',
    defaultMessage: 'My Customer Account',
  },
  myOrderHistoryTitle: {
    id: 'global.myOrderHistoryTitle',
    defaultMessage: 'Order History',
  },
  newPasswordTitle: {
    id: 'global.newPasswordTitle',
    defaultMessage: 'New Password',
  },
  newsletterSubscriptionSentence: {
    id: 'global.newsletterSubscriptionSentence',
    defaultMessage: 'Subscribe to our newsletter',
  },
  newsletterSuccessSentence: {
    id: 'global.newsletterSuccessSentence',
    defaultMessage: 'You have been added to the mailing list successfully.',
  },
  newTitle: {
    id: 'global.newTitle',
    defaultMessage: 'New',
  },
  newProductsTitle: {
    id: 'global.newProductsTitle',
    defaultMessage: 'New Products',
  },
  nextTitle: {
    id: 'global.nextTitle',
    defaultMessage: 'Next',
  },
  nicknameTitle: {
    id: 'global.nicknameTitle',
    defaultMessage: 'Nickname',
  },
  noProductsFoundSentence: {
    id: 'global.noProductsFoundSentence',
    defaultMessage: 'No products found.',
  },
  notifyUserLabel: {
    id: 'global.notifyUserLabel',
    defaultMessage: 'Automatically send username to email.',
  },
  noTitle: {
    id: 'global.noTitle',
    defaultMessage: 'No',
  },
  notFoundTitle: {
    id: 'global.notFoundTitle',
    defaultMessage: 'Not found',
  },
  numberOfProductsTitle: {
    id: 'global.numberOfProductsTitle',
    defaultMessage: 'Number of products',
  },
  offerConfirmationThankYou: {
    id: 'checkout.offerConfirmationThankYou',
    defaultMessage: 'Thank you for your proposal request!',
  },
  offerInfoHeader: {
    id: 'global.offerInfoHeader',
    defaultMessage: 'Proposal request information',
  },
  offerNumber: {
    id: 'global.offerNumber',
    defaultMessage: 'Proposal request number',
  },
  ok: {
    id: 'global.ok',
    defaultMessage: 'OK',
  },
  onSaleTitle: {
    id: 'global.onSaleTitle',
    defaultMessage: 'On Sale',
  },
  orderConfirmationThankYou: {
    id: 'checkout.orderConfirmationThankYou',
    defaultMessage: 'Thank you for your order!',
  },
  orderCountSentence: {
    id: 'global.orderCountSentence',
    defaultMessage: '{count, plural, one {# order} other {# orders}}',
  },
  orderDate: {
    id: 'global.orderDate',
    defaultMessage: 'Order date',
  },
  orderInfoHeader: {
    id: 'global.orderInfoHeader',
    defaultMessage: 'Order information',
  },
  orderListingWeb: {
    id: 'global.orderListingWeb',
    defaultMessage: 'Webstore order',
  },
  orderNumber: {
    id: 'global.orderNumber',
    defaultMessage: 'Order number',
  },
  ourBrandsTitle: {
    id: 'global.ourBrandsTitle',
    defaultMessage: 'Our Brands',
  },
  passwordTitle: {
    id: 'global.passwordTitle',
    defaultMessage: 'Password',
  },
  paymentMethod: {
    id: 'global.paymentMethod',
    defaultMessage: 'Payment method',
  },
  paymentTokensTitle: {
    id: 'global.paymentTokensTitle',
    defaultMessage: 'Payment cards',
  },
  paymentTokensNewTitle: {
    id: 'global.paymentTokensNewTitle',
    defaultMessage: 'Add new payment card',
  },
  pdfPerPage: {
    id: 'global.pdfPerPage',
    defaultMessage: '{products} per page',
  },
  pickupPointTitle: {
    id: 'global.pickupPointTitle',
    defaultMessage: 'Pickup Point',
  },
  popularSearchesTitle: {
    id: 'global.popularSearchesTitle',
    defaultMessage: 'Popular searches',
  },
  previousTitle: {
    id: 'global.previousTitle',
    defaultMessage: 'Previous',
  },
  privacyPolicySentence: {
    id: 'global.privacyPolicySentence',
    defaultMessage: 'Privacy policy',
  },
  privacyStatementAcceptSentence: {
    id: 'global.privacyStatementAcceptSentence',
    defaultMessage:
      'I authorize the processing of my personal data according to the Privacy statement',
  },
  privacyStatementMustApproveSentence: {
    id: 'global.privacyStatementMustApproveSentence',
    defaultMessage: 'Approval of Privacy statement is required.',
  },
  priceFrom: {
    id: 'global.priceFrom',
    defaultMessage: 'from',
  },
  priceTitle: {
    id: 'global.priceTitle',
    defaultMessage: 'Price',
  },
  proceedToCheckoutSentence: {
    id: 'global.proceedToCheckoutSentence',
    defaultMessage: 'Proceed to checkout',
  },
  proceedToCartSentence: {
    id: 'global.proceedToCartSentence',
    defaultMessage: 'Shopping cart',
  },
  proceedToProposalSentence: {
    id: 'global.proceedToProposalSentence',
    defaultMessage: 'Proceed to proposal',
  },
  productAddedToCartSentence: {
    id: 'global.productAddedToCartSentence',
    defaultMessage:
      '{quantity, plural, one {# product} other {# products}} added to cart',
  },
  productAddedToProposalSentence: {
    id: 'global.productAddedToProposalSentence',
    defaultMessage:
      '{quantity, plural, one {# product} other {# products}} added to proposal',
  },
  productBundleAddedToCartSentence: {
    id: 'global.productBundleAddedToCartSentence',
    defaultMessage: 'Product bundle added to cart',
  },
  productBundleAddedToProposalSentence: {
    id: 'global.productBundleAddedToProposalSentence',
    defaultMessage: 'Product bundle added to proposal',
  },
  productBundlesTitle: {
    id: 'global.productBundlesTitle',
    defaultMessage: 'Product Bundles',
  },
  productCount: {
    id: 'global.productCount',
    defaultMessage: '{count, plural, one {# product} other {# products}}',
  },
  productEnquiryTitle: {
    id: 'global.productEnquiryTitle',
    defaultMessage: 'Ask about the product',
  },
  productEnquiryProposalTitle: {
    id: 'global.productEnquiryProposalTitle',
    defaultMessage: 'Ask for a proposal',
  },
  productIsNotAvailableTitle: {
    id: 'global.productIsNotAvailableTitle',
    defaultMessage:
      'Product is not available for purchase with these options. Please try another option',
  },
  productMatrixInfo: {
    id: 'global.productMatrixInfo',
    defaultMessage:
      'Select the products to be ordered by entering the quantities in the "Quantity" fields in the table. You can move between cells using the tab.',
  },
  productCodeTitle: {
    id: 'global.productCodeTitle',
    defaultMessage: 'Product Code',
  },
  productNameTitle: {
    id: 'global.productNameTitle',
    defaultMessage: 'Name',
  },
  productOffer: {
    id: 'global.productOffer',
    defaultMessage: 'Product offer',
  },
  productPdfTitle: {
    id: 'global.productPdfTitle',
    defaultMessage: 'Print page',
  },
  productsTitle: {
    id: 'global.productsTitle',
    defaultMessage: 'Products',
  },
  productTitle: {
    id: 'global.productTitle',
    defaultMessage: 'Product',
  },
  propertyChooseTitle: {
    id: 'global.propertyChooseTitle',
    defaultMessage: 'Choose {property}',
  },
  proposalContents: {
    id: 'global.proposalContents',
    defaultMessage: 'Proposal contents',
  },
  proposalEmptySentence: {
    id: 'global.proposalEmptySentence',
    defaultMessage: 'Your proposal is empty',
  },
  proposalTitle: {
    id: 'global.proposalTitle',
    defaultMessage: 'Proposal',
  },
  purchaseOrderCountHeader: {
    id: 'global.purchaseOrderCountHeader',
    defaultMessage: 'Order count',
  },
  purchaseUserHeader: {
    id: 'global.purchaseUserHeader',
    defaultMessage: 'User',
  },
  purchaseWithVatHeader: {
    id: 'global.purchaseWithVatHeader',
    defaultMessage: 'With VAT',
  },
  purchaseWithoutVatHeader: {
    id: 'global.purchaseWithoutVatHeader',
    defaultMessage: 'Without VAT',
  },
  purchasesListTitle: {
    id: 'global.purchasesListTitle',
    defaultMessage: 'User purchases',
  },
  quantityDecrementTitle: {
    id: 'global.quantityDecrementTitle',
    defaultMessage: 'Decrement quantity',
  },
  quantityIncrementTitle: {
    id: 'global.quantityIncrementTitle',
    defaultMessage: 'Increment quantity',
  },
  quantityTitle: {
    id: 'global.quantityTitle',
    defaultMessage: 'Quantity',
  },
  readAboutRecurring: {
    id: 'global.readAboutRecurring',
    defaultMessage:
      'Subscription is a recurring and automatically sent order. You can decide the products and how often you need a delivery.',
  },
  readLessSentence: {
    id: 'global.readLessSentence',
    defaultMessage: 'Read less',
  },
  readMoreSentence: {
    id: 'global.readMoreSentence',
    defaultMessage: 'Read more',
  },
  recommendedProductsTitle: {
    id: 'global.recommendedProductsTitle',
    defaultMessage: 'Recommended Products',
  },
  recurringOrdersTitle: {
    id: 'global.recurringOrdersTitle',
    defaultMessage: 'Subscriptions',
  },
  referenceTitle: {
    id: 'global.referenceTitle',
    defaultMessage: 'Reference',
  },
  relatedProductsTitle: {
    id: 'global.relatedProductsTitle',
    defaultMessage: 'Related Products',
  },
  regionalSettingsTitle: {
    id: 'global.regionalSettingsTitle',
    defaultMessage: 'Regional settings',
  },
  registerTitle: {
    id: 'global.registerTitle',
    defaultMessage: 'Register',
  },
  removeTitle: {
    id: 'global.removeTitle',
    defaultMessage: 'Remove',
  },
  reservationTitle: {
    id: 'global.reservationTitle',
    defaultMessage: 'Reserve in store',
  },
  retailerTitle: {
    id: 'global.retailerTitle',
    defaultMessage: 'Retailer',
  },
  reviewCount: {
    id: 'global.reviewCount',
    defaultMessage: '{count, plural, one {# review} other {# reviews}}',
  },
  reviewCountCompact: {
    id: 'global.reviewCountCompact',
    defaultMessage: '({count})',
  },
  reviewProductRatingTitle: {
    id: 'global.reviewProductRatingTitle',
    defaultMessage: 'Product',
  },
  reviewServiceRatingTitle: {
    id: 'global.reviewServiceRatingTitle',
    defaultMessage: 'Service and delivery',
  },
  reviewTitle: {
    id: 'global.reviewTitle',
    defaultMessage: 'Review',
  },
  reviewsTitle: {
    id: 'global.reviewsTitle',
    defaultMessage: 'Reviews',
  },
  RRP: {
    id: 'global.RRP',
    defaultMessage: 'RRP',
  },
  saveTitle: {
    id: 'global.saveTitle',
    defaultMessage: 'Save',
  },
  showAllProductsSentence: {
    id: 'global.showAllProductsSentence',
    defaultMessage: 'Show all products',
  },
  selectTitle: {
    id: 'global.selectTitle',
    defaultMessage: 'Select',
  },
  selectedTitle: {
    id: 'global.selectedTitle',
    defaultMessage: 'Selected',
  },
  selectedTitleWithValue: {
    id: 'global.selectedTitleWithValue',
    defaultMessage: 'Selected: {value}',
  },
  search: {
    id: 'global.search',
    defaultMessage: 'Search',
  },
  searchHistoryTitle: {
    id: 'global.searchHistoryTitle',
    defaultMessage: 'Recent searches',
  },
  searchNoResultsSentence: {
    id: 'global.searchNoResults',
    defaultMessage: 'No results found',
  },
  searchResultsTitle: {
    id: 'global.searchResultsTitle',
    defaultMessage: 'Search Results',
  },
  searchResultsForTitle: {
    id: 'global.searchResultsForTitle',
    defaultMessage: 'Search Results for {term}',
  },
  searchResultsForTermTitle: {
    id: 'global.searchResultsForTermTitle',
    defaultMessage: 'Results for the Search Term "{term}"',
  },
  searchTryAnotherSentence: {
    id: 'global.searchTryAnotherSentence',
    defaultMessage: 'Try another search',
  },
  seeCategoriesNavi: {
    id: 'global.seeCategoriesNavi',
    defaultMessage: 'Products and infopages',
  },
  sendTitle: {
    id: 'global.sendTitle',
    defaultMessage: 'Send',
  },
  shippingTitle: {
    id: 'global.shipping',
    defaultMessage: 'Shipping',
  },
  shippingAddressTitle: {
    id: 'global.shippingAddressTitle',
    defaultMessage: 'Shipping address',
  },
  shippingCostsTitle: {
    id: 'global.shippingCostsTitle',
    defaultMessage: 'Shipping costs',
  },
  shippingMethod: {
    id: 'global.shippingMethod',
    defaultMessage: 'Shipping method',
  },
  shoppingCenterAcceptableTerms: {
    id: 'global.shoppingCenterAcceptableTerms',
    defaultMessage:
      'By clicking "{continuePurchase}" I confirm I\'ve read {storeName} {termsOfUse} and {privacyStatement}, and merchant\'s {merchantName} {deliveryAndPaymentTerms} and {merchantPrivacyStatement}',
  },
  shoppingCenterDeliveryAndPaymentTerms: {
    id: 'global.shoppingCenterProductMerchantDeliveryAndPaymentTerms',
    defaultMessage: 'delivery and payment terms',
  },
  shoppingCenterPrivacyStatement: {
    id: 'global.shoppingCenterProductMerchantPrivacy',
    defaultMessage: 'privacy statement',
  },
  showLessText: {
    id: 'global.showLessText',
    defaultMessage: 'Show less',
  },
  showMoreText: {
    id: 'global.showMoreText',
    defaultMessage: 'Show more',
  },
  sizeGuideSentence: {
    id: 'global.sizeGuideSentence',
    defaultMessage: 'Size guide',
  },
  soldProductsTitle: {
    id: 'global.soldProductsTitle',
    defaultMessage: 'Sold Products',
  },
  startDateTitle: {
    id: 'global.startDateTitle',
    defaultMessage: 'Start date',
  },
  statusTitle: {
    id: 'global.statusTitle',
    defaultMessage: 'Status',
  },
  stockTemporarilyOutOfSentence: {
    id: 'global.stockTemporarilyOutOfSentence',
    defaultMessage: 'Product temporarily out of stock',
  },
  streetAddressTitle: {
    id: 'global.streetAddressTitle',
    defaultMessage: 'Street Address',
  },
  submitSurvey: {
    id: 'global.submitSurvey',
    defaultMessage: 'Submit',
  },
  successfulPurchaseBreadcrumbName: {
    id: 'global.successfulPurchaseBreadcrumbName',
    defaultMessage: 'Thank you for your order',
  },
  successfulProposalBreadcrumbName: {
    id: 'global.successfulProposalBreadcrumbName',
    defaultMessage: 'Thank you for your proposal offer',
  },
  successfulProposalPageTitle: {
    id: 'global.successfulProposalPageTitle',
    defaultMessage: 'Your proposal request has been sent!',
  },
  successfulProposalReceivedTitle: {
    id: 'global.successfulProposalReceivedTitle',
    defaultMessage: 'Your proposal request has been received!',
  },
  successfulPurchasePageTitle: {
    id: 'global.successfulPurchasePageTitle',
    defaultMessage: 'Your order has been registered!',
  },
  suggestionsTitle: {
    id: 'global.suggestionsTitle',
    defaultMessage: 'Suggestions',
  },
  sumWithVat: {
    id: 'global.sumWithVat',
    defaultMessage: 'Total with VAT',
  },
  sumWithoutVat: {
    id: 'global.sumWithoutVat',
    defaultMessage: 'Total without VAT',
  },
  surveySubmitError: {
    id: 'global.surveySubmitError',
    defaultMessage: 'Error submitting form. Please try again.',
  },
  taxExcluded: {
    id: 'global.taxExcluded',
    defaultMessage: '(tax excl.)',
  },
  taxIncluded: {
    id: 'global.taxIncluded',
    defaultMessage: '(tax incl.)',
  },
  telephoneTitle: {
    id: 'global.telephoneTitle',
    defaultMessage: 'Telephone',
  },
  termFilterPlaceholder: {
    id: 'global.termFilterPlaceholder',
    defaultMessage: 'Search',
  },
  termsSentence: {
    id: 'global.termsSentence',
    defaultMessage: 'Terms of Service',
  },
  toOrder: {
    id: 'global.toOrder',
    defaultMessage: 'See info',
  },
  totalPriceSentence: {
    id: 'global.totalPriceSentence',
    defaultMessage: 'Total',
  },
  totalPriceSentenceAbbr: {
    id: 'global.totalPriceSentenceAbbr',
    defaultMessage: 'Sum. {value}',
  },
  unitPriceTitle: {
    id: 'global.unitPriceTitle',
    defaultMessage: 'Unit price',
  },
  update: {
    id: 'global.update',
    defaultMessage: 'Update',
  },
  uploadFileTitle: {
    id: 'global.uploadFileTitle',
    defaultMessage: 'Upload file',
  },
  useDeliveryAddressTitle: {
    id: 'global.useDeliveryAddressTitle',
    defaultMessage: 'Delivery to another address',
  },
  userListTitle: {
    id: 'global.userListTitle',
    defaultMessage: 'Users for account',
  },
  validationMessageInvalidQuantity: {
    id: 'global.validationMessageInvalidQuantity',
    defaultMessage: 'The quantity must be between {min} and {max}.',
  },
  validationMessageInvalidFloor: {
    id: 'global.validationMessageInvalidFloor',
    defaultMessage:
      'The quantity must be divisible with the package product count {size}.',
  },
  validUntilFurtherNotice: {
    id: 'global.validUntilFurtherNotice',
    defaultMessage: 'Valid until further notice.',
  },
  warrantyLabel: {
    id: 'global.warrantyLabel',
    defaultMessage: 'Warranty: {warranty}',
  },
  weightTitle: {
    id: 'global.weightTitle',
    defaultMessage: 'Weight',
  },
  weRecommendTitle: {
    id: 'global.weRecommendTitle',
    defaultMessage: 'We Recommend',
  },
  writeNewReview: {
    id: 'global.writeNewReview',
    defaultMessage: 'Write a review',
  },
  yesTitle: {
    id: 'global.yesTitle',
    defaultMessage: 'Yes',
  },
  yourReferenceTitle: {
    id: 'global.yourReferenceTitle',
    defaultMessage: 'Reference',
  },
  yourReferenceSentence: {
    id: 'global.yourReferenceSentence',
    defaultMessage: 'Reference: {reference}',
  },
  yourEmailSentence: {
    id: 'global.yourEmail',
    defaultMessage: 'Your email',
  },
  youSaveSentence: {
    id: 'global.youSaveSentence',
    defaultMessage: 'You save {savings}',
  },
  zipCodeTitle: {
    id: 'global.zipCodeTitle',
    defaultMessage: 'Zip Code',
  },
  wishListTitle: {
    id: 'global.wishListPageTitle',
    defaultMessage: 'Wishlist',
  },
  vehiclePartSearchTitle: {
    id: 'global.vehiclePartSearchTitle',
    defaultMessage: 'Vehicle part search',
  },
  zoomInTitle: {
    id: 'global.zoomInTitle',
    defaultMessage: 'Zoom In',
  },
  zoomOutTitle: {
    id: 'global.zoomOutTitle',
    defaultMessage: 'Zoom Out',
  },
});
