import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import Analytics from '../../../analytics/Analytics';
import { modelOf } from '../../../prop-types';
import LocationContext from '../../../services/LocationContext';
import ConfigStore from '../../../store/ConfigStore';
import Icon from '../Icon';

const ShareButtons = ({
  configStore,
  analytics,
  locationContext,
  media,
  productId,
}) => {
  const title = window?.document?.title || '';
  const url = locationContext.href;
  const sendAnalyticsEvent = (shareContent) => {
    analytics.share(shareContent);
  };

  const share = (method, contentType) => {
    if (configStore.analytics.ga4.enabled) {
      sendAnalyticsEvent({
        method,
        content_type: contentType,
        item_id: productId,
      });
    }
  };

  const windowPrint = () => {
    share('Print', 'image');
    window.print();
  };

  return (
    <ul className="ShareButtons">
      <div
        className={classNames(
          'SocialMediaShareButton SocialMediaShareButton--facebook'
        )}
      >
        <FacebookShareButton
          url={url}
          quote={title}
          onClick={() => share('Facebook', 'image')}
        >
          <Icon name="facebook" />
        </FacebookShareButton>
      </div>
      <div
        className={classNames(
          'SocialMediaShareButton SocialMediaShareButton--twitter'
        )}
      >
        <TwitterShareButton
          url={url}
          title={title}
          onClick={() => share('Twitter', 'image')}
        >
          <Icon name="twitter" />
        </TwitterShareButton>
      </div>
      <div
        className={classNames(
          'SocialMediaShareButton SocialMediaShareButton--whatsapp'
        )}
      >
        <WhatsappShareButton
          url={url}
          title={title}
          separator=":: "
          onClick={() => share('Whatsapp', 'image')}
        >
          <Icon name="whatsapp" />
        </WhatsappShareButton>
      </div>
      {media && (
        <div
          className={classNames(
            'SocialMediaShareButton SocialMediaShareButton--pinterest'
          )}
        >
          <PinterestShareButton
            url={url}
            media={media}
            windowWidth={1000}
            windowHeight={730}
            onClick={() => share('Pinterest', 'image')}
          >
            <Icon name="pinterest" />
          </PinterestShareButton>
        </div>
      )}
      <div
        className={classNames(
          'SocialMediaShareButton SocialMediaShareButton--reddit'
        )}
      >
        <RedditShareButton
          url={url}
          title={title}
          windowWidth={660}
          windowHeight={460}
          onClick={() => share('Reddit', 'image')}
        >
          <Icon name="reddit" />
        </RedditShareButton>
      </div>
      <div
        className={classNames(
          'SocialMediaShareButton SocialMediaShareButton--email'
        )}
      >
        <EmailShareButton
          url={url}
          body={url}
          subject={title}
          separator={' - '}
          onClickCapture={() => share('Email', 'image')}
        >
          <Icon name="envelope" />
        </EmailShareButton>
      </div>
      <div
        className="SocialMediaShareButton SocialMediaShareButton--print"
        tabIndex="0"
        onClick={windowPrint}
      >
        <Icon name="print" />
      </div>
    </ul>
  );
};

ShareButtons.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  locationContext: PropTypes.instanceOf(LocationContext).isRequired,
  media: PropTypes.string,
  productId: PropTypes.string,
};

export default inject(
  'configStore',
  'analytics',
  'locationContext'
)(observer(ShareButtons));
