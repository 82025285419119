import React, { Component } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ReserveInStoreStore from '../../../store/ReserveInStoreStore';
import StorageStore from '../../../store/StorageStore';
import Storage from '../../../models/Storage';
import globalTranslations from '../../../i18n/globalTranslations';
import FullProduct from '../../../models/product/FullProduct';

@observer
export class ProductReserveInStoreButton extends Component {
  constructor(props) {
    super(props);

    this.buttonElement = null;

    this.state = {
      buttonMounted: false,
    };
  }

  toggleModal = () => {
    const { reserveInStoreStore, storage, product, activeProductId } =
      this.props;
    const { modal } = reserveInStoreStore;

    modal.toggle();
    modal.setProduct(product.id, activeProductId);
    if (storage) {
      modal.setStorage(storage);
    }
  };

  productInStock = () => {
    const { storageStore, storage, product, activeProductId } = this.props;

    const pickupStorageIds = storage
      ? [storage.id]
      : storageStore.pickupStorages.map((storage) => storage.id);
    const stocks = product.stocks.get(activeProductId);

    if (!stocks) {
      return false;
    }

    return stocks.some(
      (stock) =>
        stock.real_stock > 0 && pickupStorageIds.includes(stock.storage_id)
    );
  };

  setButtonElement = (ref) => {
    this.buttonElement = ref;
    this.setState({ buttonMounted: true });
  };

  render() {
    const { product, activeProductId, storage, shouldHideButton, onlyButton } =
      this.props;

    const productSelected = !!activeProductId;
    const stockLoaded = !!product.stocks.get(activeProductId);
    const inStock = stockLoaded && this.productInStock();

    if (!(productSelected && stockLoaded && inStock) && shouldHideButton) {
      return null;
    }

    const selectProductMessage = productSelected ? null : (
      <FormattedMessage
        id="reserveInStore.selectChildProductHint"
        defaultMessage="First choose the product. After this you can reserve this product from a store."
      />
    );
    const loadingMessage = stockLoaded ? null : (
      <FormattedMessage
        id="reserveInStore.stockLoadingHint"
        defaultMessage="Please wait, loading in-store availability..."
      />
    );
    let outOfStockMessage = null;
    if (!inStock) {
      outOfStockMessage = storage ? (
        <FormattedMessage
          id="reserveInStore.outOfStockSingleStoreHint"
          defaultMessage="This store doesn't currently have this product in stock."
        />
      ) : (
        <FormattedMessage
          id="reserveInStore.outOfStockMultipleStoresHint"
          defaultMessage="No pick-up stores currently have this product in stock."
        />
      );
    }

    const reserveButton = (
      <Button
        color="primary"
        onClick={this.toggleModal}
        disabled={!(productSelected && stockLoaded && inStock)}
        innerRef={this.setButtonElement}
        className="ProductReserveInStoreButton"
      >
        <FormattedMessage
          id="reserveInStore.button"
          defaultMessage="Reserve in store"
        />
      </Button>
    );

    if (onlyButton) {
      return reserveButton;
    }

    const tooltipMessage =
      selectProductMessage || loadingMessage || outOfStockMessage;

    return (
      <div className="ProductReserveInStore__wrapper">
        <FormattedMessage {...globalTranslations.buttonInfo} />
        {reserveButton}
        {tooltipMessage && this.state.buttonMounted && (
          <UncontrolledTooltip placement="top" target={this.buttonElement}>
            {tooltipMessage}
          </UncontrolledTooltip>
        )}
      </div>
    );
  }
}

ProductReserveInStoreButton.propTypes = {
  reserveInStoreStore: modelOf(ReserveInStoreStore).isRequired,
  storageStore: modelOf(StorageStore).isRequired,
  product: modelOf(FullProduct).isRequired,
  activeProductId: PropTypes.string,
  storage: modelOf(Storage),
  shouldHideButton: PropTypes.bool,
  onlyButton: PropTypes.bool,
};

export default inject(
  'reserveInStoreStore',
  'storageStore'
)(ProductReserveInStoreButton);
