/**
 *
 * @param {Object} props
 * @param {ProductStore} props.productStore
 * @param {Object} props.params
 * @param {Object} props.params.searchParams
 * @param {Boolean} props.params.allSections
 * @param {String} props.params.state
 */
const useFetchData = ({ productStore, params }) => {
  // The default value for state is not set
  if (!params.state) {
    productStore
      .loadProducts(params.searchParams, params.allSections)
      .catch((e) => {
        console.error(e);
      });
  }
};

export default useFetchData;
