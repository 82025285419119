import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { pull } from 'lodash';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';
import ProductSlider from '../ProductSlider';
import MissingProductLoader from '../../product/MissingProductLoader';

@observer
export class LastVisitedProductsSlider extends Component {
  listId = 'last_visited_products';
  listName = 'Last Visited Products';

  getProductIds = () => {
    const { productStore, currentProductId } = this.props;
    const lastVisitedProducts = productStore.lastVisitedProducts.slice();
    // Remove current product id from the list.
    return pull(lastVisitedProducts, currentProductId);
  };

  renderContent = (products) => {
    if (products.length === 0) {
      return null;
    }

    const cardSettings = {
      requireCanonicalLink: true,
    };

    return (
      <div className="LastVisitedProductsSlider">
        <h3>
          <FormattedMessage
            id="product.lastVisitedProducts"
            defaultMessage="Last visited products"
          />
        </h3>
        <ProductSlider
          listId={this.listId}
          name={this.listName}
          products={products}
          cardSettings={cardSettings}
        />
      </div>
    );
  };

  render() {
    const ids = this.getProductIds();
    if (ids.length === 0) {
      return null;
    }

    return (
      <MissingProductLoader ids={ids}>
        {(products) => this.renderContent(products)}
      </MissingProductLoader>
    );
  }
}

LastVisitedProductsSlider.propTypes = {
  productStore: modelOf(ProductStore).isRequired,
  currentProductId: PropTypes.string,
};

export default inject('productStore')(LastVisitedProductsSlider);
