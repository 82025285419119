import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ProductIncomingQuantityModel } from '../../../models/product/collection/ProductCollectionProduct';
import { modelOf } from '../../../prop-types';

const messages = defineMessages({
  incomingQuantity: {
    id: 'product.incomingQuantity',
    defaultMessage: 'Incoming: {freeQuantity} {stockUnit} {date}',
  },
  incomingQuantityNoQuantities: {
    id: 'product.incomingQuantityNoQuantities',
    defaultMessage: 'Incoming: {date}',
  },
  noDateAvailable: {
    id: 'product.noDateAvailable',
    defaultMessage: '(no date available)',
  },
});

const ProductIncomingQuantity = ({
  incomingQuantity,
  stockUnit,
  className,
}) => {
  const incomingQuantityDate =
    incomingQuantity.date &&
    DateTime.fromISO(incomingQuantity.date).toLocaleString();
  const freeQuantity = incomingQuantity.free_quantity;

  return (
    <div className={classNames('ProductIncomingQuantity', className)}>
      {freeQuantity ? (
        <FormattedMessage
          {...messages.incomingQuantity}
          values={{
            date: incomingQuantityDate ? (
              incomingQuantityDate
            ) : (
              <FormattedMessage {...messages.noDateAvailable} />
            ),
            freeQuantity,
            stockUnit,
          }}
        />
      ) : (
        <FormattedMessage
          {...messages.incomingQuantityNoQuantities}
          values={{
            date: incomingQuantityDate ? (
              incomingQuantityDate
            ) : (
              <FormattedMessage {...messages.noDateAvailable} />
            ),
          }}
        />
      )}
    </div>
  );
};

ProductIncomingQuantity.propTypes = {
  incomingQuantity: modelOf(ProductIncomingQuantityModel).isRequired,
  stockUnit: PropTypes.string,
  className: PropTypes.string,
};

export default ProductIncomingQuantity;
