import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import GenericMeta from '../GenericMeta';

const SEOTitle = ({ configStore, title, noSuffix }) => {
  const outputTitle = (title, shouldTranslate) => {
    const fullTitle = noSuffix
      ? title
      : `${title} - ${configStore.store.name} ${configStore.languageTexts.TEXT_SEO_STORE_NAME_ADD}`;
    return (
      <>
        {!shouldTranslate && (
          <>
            <GenericMeta property="og:title" content={fullTitle} />
            <GenericMeta name="twitter:title" content={fullTitle} />
          </>
        )}
        <Helmet>
          <title>{fullTitle}</title>
        </Helmet>
      </>
    );
  };

  const shouldTranslate = title && title.id;
  return (
    <>
      {shouldTranslate ? (
        <FormattedMessage {...title}>{outputTitle}</FormattedMessage>
      ) : (
        outputTitle(title, shouldTranslate)
      )}
    </>
  );
};

SEOTitle.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  noSuffix: PropTypes.bool,
};
export default inject('configStore')(observer(SEOTitle));
