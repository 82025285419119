import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Button } from 'reactstrap';

import Icon from '../../common/Icon';
import ProductSizeGuideModal from '../ProductSizeGuideModal';

const ProductSizeGuides = ({ sizeGuides }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (sizeGuides.length === 0) {
    return null;
  }

  return (
    <div className="ProductSizeGuides">
      {sizeGuides.map((guide, index) => {
        return (
          <Fragment key={guide.name + index}>
            <ProductSizeGuideModal
              isOpen={modal}
              toggle={toggleModal}
              guide={guide}
            />
            <Button
              color="secondary"
              className="ProductSizeGuides__modal-button"
              onClick={toggleModal}
            >
              <Icon name="info-circle" /> {guide.name}
            </Button>
          </Fragment>
        );
      })}
    </div>
  );
};

ProductSizeGuides.propTypes = {
  sizeGuides: PropTypes.arrayOf(PropTypes.object),
};

export default observer(ProductSizeGuides);
