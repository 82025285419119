import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import Stars from '../../review/Stars';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';
import FullProduct from '../../../models/product/FullProduct';

const ProductReviewWidget = ({ configStore, compact, product }) => {
  if (!configStore.reviews.enabled) {
    return null;
  }

  const amount = product.reviews_average;
  const reviewCount = product.reviews_count;

  return (
    <div className="ProductReviewWidget">
      <Stars amount={amount} />{' '}
      <span className="ProductReviewWidget__text">
        {compact ? (
          <FormattedMessage
            {...globalTranslations.reviewCountCompact}
            values={{ count: reviewCount }}
          />
        ) : (
          <FormattedMessage
            {...globalTranslations.reviewCount}
            values={{ count: reviewCount }}
          />
        )}
      </span>
    </div>
  );
};

ProductReviewWidget.propTypes = {
  configStore: modelOf(ConfigStore),
  product: PropTypes.oneOfType([
    modelOf(Product).isRequired,
    modelOf(FullProduct).isRequired,
  ]),
  compact: PropTypes.bool,
};

export default inject('configStore')(observer(ProductReviewWidget));
