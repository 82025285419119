import { types, getEnv, flow, cast } from 'mobx-state-tree';
import { keyBy } from 'lodash';

import StatefulStore from '../models/StatefulStore';
import { RequestStateType } from '../types/RequestState';
import RequestState from '../types/RequestState';
import Address from '../models/Address';

const AddressStore = StatefulStore.named('AddressStore')
  .props({
    addresses: types.optional(types.map(Address), {}),
    addressStates: types.optional(types.map(RequestStateType), {}),
  })
  .views((self) => {
    return {
      get addressArray() {
        return Array.from(self.addresses.values());
      },
    };
  })
  .actions((self) => {
    return {
      loadAddress: flow(function* loadAddress(id) {
        self.addressStates.set(id, RequestState.LOADING);

        try {
          const page = yield getEnv(self).apiWrapper.request(
            `address-book/${id}`
          );
          self.addresses.set(id, page);
          self.addressStates.set(id, RequestState.LOADED);
        } catch (e) {
          self.setError(e);
          self.addressStates.set(id, RequestState.ERROR);
          throw e;
        }
      }),
      loadAddresses: flow(function* loadAddresses() {
        self.setLoading(true);

        try {
          const addresses = yield getEnv(self).apiWrapper.request(
            `address-book`
          );
          const keyedAddresses = keyBy(addresses, 'id');
          self.addresses = cast(keyedAddresses);
          self.addressArray.forEach((address) => {
            self.addressStates.set(address.id, RequestState.LOADED);
          });
        } catch (e) {
          self.setError(e);
          throw e;
        } finally {
          self.setLoading(false);
        }
      }),
      removeAddress: (addressId) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .delete(`address-book/${addressId}`);
      },
      saveAddress: flow(function* saveAddress(address) {
        self.setLoading(true);
        try {
          yield getEnv(self)
            .apiWrapper.apiAxios()
            .post(`address-book/`, address);
        } catch (e) {
          self.setError(e);
          throw e;
        } finally {
          self.setLoading(false);
        }
      }),
      updateAddress: (addressId, address) => {
        return getEnv(self)
          .apiWrapper.apiAxios()
          .put(`address-book/${addressId}`, address);
      },
    };
  });

export default AddressStore;
