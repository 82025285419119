import { types } from 'mobx-state-tree';
import ReviewDescription from './ReviewDescription';

const Review = types.model('Review', {
  customers_name: types.string,
  date_added: types.string,
  description: ReviewDescription,
  id: types.identifier,
  original: types.maybeNull(ReviewDescription),
  reviews_rating: types.number,
  service_rating: types.number,
});

export default Review;
