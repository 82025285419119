import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import ButtonLink from '../../common/ButtonLink';

const ReviewSwitchLanguage = ({ showOriginal, onTranslationSwitch, intl }) => {
  const handleTranslationSwitch = useCallback(() => {
    onTranslationSwitch(!showOriginal);
  }, [showOriginal]);

  const getButtonText = () => {
    if (showOriginal) {
      return (
        <FormattedMessage
          id="review.seeTranslation"
          defaultMessage="See translation"
        />
      );
    }

    return (
      <FormattedMessage id="review.seeOriginal" defaultMessage="See original" />
    );
  };

  return (
    <div className="ReviewSwitchLanguage">
      <ButtonLink
        onClick={handleTranslationSwitch}
        aria-label={intl.formatMessage({
          id: 'review.seeOriginal',
          defaultMessage: 'See original',
        })}
      >
        {getButtonText()}
      </ButtonLink>
    </div>
  );
};

ReviewSwitchLanguage.propTypes = {
  showOriginal: PropTypes.bool.isRequired,
  onTranslationSwitch: PropTypes.func.isRequired,
};

export default injectIntl(ReviewSwitchLanguage);
