import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

import globalTranslations from '../../../i18n/globalTranslations';
import FullProductCollectionItem from '../../../models/product/FullProductCollectionItem';
import { modelOf } from '../../../prop-types';
import ProductIncomingQuantity from '../ProductIncomingQuantity';

const messages = defineMessages({
  availableForDelivery: {
    id: 'productMatrix.availableForDelivery',
    defaultMessage: 'Available for delivery: {freeQuantity} {stockUnit}',
  },
  inStockQuantity: {
    id: 'productMatrix.inStockQuantity',
    defaultMessage: 'In own stock: {inStockQuantity} {stockUnit}',
  },
});

const ProductMatrixDetailedStockInfo = ({
  productCollectionElementItem,
  labelRow,
}) => {
  const elementProductId = productCollectionElementItem.product.id;
  const stock = productCollectionElementItem.stocks.get(elementProductId);

  const renderIncomingQuantities = (incomingQuantities) => {
    if (!incomingQuantities || incomingQuantities.length === 0) {
      return null;
    }

    return incomingQuantities.map((incomingQuantity, index) => (
      <ProductIncomingQuantity
        key={index}
        incomingQuantity={incomingQuantity}
        stockUnit={stock.stock_unit}
        className="ProductMatrixDetailedStockInfo__incoming-quantity"
      />
    ));
  };

  const renderProcessingDays = (storage) => {
    if (storage.in_stock_processing_days.max) {
      return (
        <span className="ProductMatrixDetailedStockInfo__storage-processing-time">
          {storage.in_stock_processing_days.minProcessingTime}
          {'-'}
          {storage.in_stock_processing_days.maxProcessingTime}{' '}
          {storage.in_stock_processing_days.max >= 1 ? (
            <FormattedMessage {...globalTranslations.abbrDayUnit} />
          ) : (
            <FormattedMessage {...globalTranslations.abbrHourUnit} />
          )}
        </span>
      );
    }

    if (storage.in_stock_processing_days.min) {
      return (
        <span className="ProductMatrixDetailedStockInfo__storage-processing-time">
          {storage.in_stock_processing_days.minProcessingTime}{' '}
          {storage.in_stock_processing_days.min >= 1 ? (
            <FormattedMessage {...globalTranslations.abbrDayUnit} />
          ) : (
            <FormattedMessage {...globalTranslations.abbrHourUnit} />
          )}
        </span>
      );
    }

    return null;
  };

  const renderDetailedStockInfo = () => {
    const storages = stock.storages ?? [];

    return (
      <>
        <Col sm={12}>
          {storages.map((storage, index) => (
            <Row key={index}>
              <Col sm={12}>
                <div className="ProductMatrixDetailedStockInfo__storage">
                  <span className="ProductMatrixDetailedStockInfo__storage-quantity">
                    {storage.free_quantity} {stock.stock_unit}
                  </span>
                  <span className="ProductMatrixDetailedStockInfo__storage-name">
                    {storage.translations.storage_name}
                    <div className="ProductMatrixDetailedStockInfo__storage-incoming-quantities">
                      {renderIncomingQuantities(storage.incoming_quantities)}
                    </div>
                  </span>
                  {renderProcessingDays(storage)}
                </div>
              </Col>
            </Row>
          ))}
        </Col>
        <Col sm={12}>
          <div className="ProductMatrixDetailedStockInfo__stock-quantity">
            <FormattedMessage
              {...messages.inStockQuantity}
              values={{
                inStockQuantity: stock.in_stock_quantity,
                stockUnit: stock.stock_unit,
              }}
            />
          </div>
        </Col>
        <Col sm={12}>
          <div className="ProductMatrixDetailedStockInfo__free-quantity">
            <FormattedMessage
              {...messages.availableForDelivery}
              values={{
                freeQuantity: stock.free_quantity,
                stockUnit: stock.stock_unit,
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  return (
    <Container>
      <Row className="ProductMatrixDetailedStockInfo">
        <Col sm={12}>{labelRow}</Col>
        {renderDetailedStockInfo()}
      </Row>
    </Container>
  );
};

ProductMatrixDetailedStockInfo.propTypes = {
  productCollectionElementItem: modelOf(FullProductCollectionItem).isRequired,
  labelRow: PropTypes.object,
};

export default observer(ProductMatrixDetailedStockInfo);
