import { flow, getEnv, getParentOfType, types } from 'mobx-state-tree';

import RequestState, { RequestStateType } from '../../types/RequestState';
import ProductCollectionItem from './collection/ProductCollectionItem';
import ProductCollectionProduct from './collection/ProductCollectionProduct';
import ProductDetailedStockInfo from './collection/ProductDetailedStockInfo';
import FullProductMatrix from './FullProductMatrix';

const FullProductCollectionItem = types
  .compose(
    ProductCollectionItem,
    types
      .model('FullProductCollectionItem', {
        column_id: types.number,
        row_id: types.number,
        product: ProductCollectionProduct,
        stocks: types.optional(types.map(ProductDetailedStockInfo), {}),
        stockStates: types.optional(types.map(RequestStateType), {}),
      })
      .actions((self) => {
        return {
          /**
           * @param {string} productId Regular product id, not collection extended id
           */
          loadStocks: flow(function* loadStocks(productId) {
            // Using self.product.id is since it's full id with row and columnd ids; extended id
            if (self.stockStates.get(self.product.id) === RequestState.LOADED) {
              return;
            }

            self.stockStates.set(self.product.id, RequestState.LOADING);
            try {
              const storageStocks = yield getEnv(self).apiWrapper.request(
                `stocks-info/${productId}`,
                {
                  params: {
                    rowId: self.row_id,
                    columnId: self.column_id,
                  },
                },
                { active_section: null }
              );
              self.stocks.set(self.product.id, storageStocks);
              self.stockStates.set(self.product.id, RequestState.LOADED);
            } catch (e) {
              self.stockStates.set(self.product.id, RequestState.ERROR);
              throw e;
            }
          }),
        };
      })
      .views((self) => {
        return {
          get collection() {
            return getParentOfType(self, FullProductMatrix);
          },
          get rowName() {
            if (!self.collection) {
              return '';
            }
            const element = self.collection.row.getElementWithId(self.row_id);
            return element ? element.name : '';
          },
          get columnName() {
            if (!self.collection) {
              return '';
            }
            const element = self.collection.column.getElementWithId(
              self.column_id
            );
            return element ? element.name : '';
          },
          get name() {
            return [self.rowName, self.columnName]
              .filter((name) => !!name)
              .join(', ');
          },
        };
      })
  )
  .named('FullProductCollectionItem');

export default FullProductCollectionItem;
