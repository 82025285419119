import { types } from 'mobx-state-tree';

import ProductPriceInfo from '../ProductPriceInfo';

const Ad = types
  .model('Ad', {
    content: types.maybeNull(types.string),
    content_font: types.maybeNull(types.string),
    content_font_size_mobile: types.maybeNull(types.string),
    content_font_size_desktop: types.maybeNull(types.string),
    content_horizontal_align: types.maybeNull(types.string),
    content_text_color: types.maybeNull(types.string),
    content_vertical_align: types.maybeNull(types.string),
    // This refers to the possible product, not the banner itself.
    date_added: types.maybeNull(types.string),
    discount_text: types.maybeNull(types.string),
    hide_from_desktop_tablet_users: types.maybeNull(types.boolean),
    hide_from_mobile_users: types.maybeNull(types.boolean),
    id: types.identifier,
    image: types.maybeNull(types.string),
    image_height: types.maybeNull(types.integer),
    image_width: types.maybeNull(types.integer),
    is_external: types.boolean,
    link_url: types.maybeNull(types.string),
    manufacturer: types.maybeNull(types.string),
    mobile_image: types.maybeNull(types.string),
    mobile_image_height: types.maybeNull(types.integer),
    mobile_image_width: types.maybeNull(types.integer),
    overlay_background_color: types.maybeNull(types.string),
    overlay_image: types.maybeNull(types.string),
    popup_delay: types.maybeNull(types.integer),
    price_info: types.maybeNull(ProductPriceInfo),
    product_id: types.maybeNull(types.string),
    product_info: types.maybeNull(
      types.model('BannerProductInfo', {
        actual_code: types.string,
        canonical_path: types.optional(types.array(types.string), []),
        main_category_id: types.maybeNull(types.number),
        main_section_id: types.maybeNull(types.number),
      })
    ),
    seo_description: types.string,
    text_color: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    title_font: types.maybeNull(types.string),
    title_font_size_desktop: types.maybeNull(types.string),
    title_font_size_mobile: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    video_embed_style: types.maybeNull(types.string),
    video_file: types.maybeNull(types.string),
    video_link: types.maybeNull(types.string),
    video_mime_type: types.maybeNull(types.string),
    video_poster: types.maybeNull(types.string),
    video_poster_height: types.maybeNull(types.integer),
    video_poster_width: types.maybeNull(types.integer),
    zones: types.array(types.string),
  })
  .views((self) => ({
    get aspectRatioCSS() {
      return 'auto ' + self.image_width + ' / ' + self.image_height;
    },
    get canonicalPath() {
      return self.product_id && self.product_info?.canonical_path;
    },
    get mainSectionId() {
      return self.product_id && self.product_info?.main_section_id;
    },
    get priceInfo() {
      return self.product_id && !!self.price_info && self.price_info;
    },
    get productId() {
      return self.product_id;
    },
    get productCode() {
      return self.product_info?.actual_code;
    },
    get stylesAlign() {
      if (
        self.content_horizontal_align?.length === 0 &&
        self.content_vertical_align?.length === 0
      ) {
        return null;
      }
      return {
        justifyContent: self.content_vertical_align,
        textAlign: self.content_horizontal_align,
      };
    },
    stylesContent(isMobile) {
      if (
        self.content_font?.length === 0 &&
        self.content_font_size_desktop?.length === 0 &&
        self.content_font_size_mobile?.length === 0 &&
        self.content_text_color?.length === 0
      ) {
        return null;
      }
      return {
        fontFamily: self.content_font,
        fontSize: isMobile
          ? self.content_font_size_mobile
          : self.content_font_size_desktop,
        color: self.content_text_color,
      };
    },
    stylesTitle(isMobile) {
      if (
        self.title_font?.length === 0 &&
        self.title_font_size_desktop?.length === 0 &&
        self.title_font_size_mobile?.length === 0
      ) {
        return null;
      }
      return {
        fontFamily: self.title_font,
        fontSize: isMobile
          ? self.title_font_size_mobile
          : self.title_font_size_desktop,
      };
    },
  }));

export default Ad;
