import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import ReactResizeDetector from 'react-resize-detector';

import Analytics from '../../../../analytics/Analytics';
import { modelOf } from '../../../../prop-types';
import AdStore from '../../../../store/AdStore';
import ConfigStore from '../../../../store/ConfigStore';
import UIStore from '../../../../store/UIStore';
import RequestState from '../../../../types/RequestState';
import CommonSlider from '../../../common/Slider';
import AdLoaderSkeleton from '../../../skeleton/AdLoaderSkeleton';
import AdLoaded from '../../AdLoaded';
import AdLoader from '../../AdLoader';
import Banner from '../../Banner';
import BannerSliderStyles, {
  countMainAxisSize,
  countMinCrossAxisSize,
} from '../BannerSliderStyles';

const BannerSlider = ({
  adStore,
  configStore,
  uiStore,
  analytics,
  className,
  searchParams,
  maximumCrossAxisSize,
  overrideSettings,
  preLoadedAds,
  aspectRatio,
  adZone,
}) => {
  // Same as default to sync after change effect to reset slider.
  const AUTOPLAY_SPEED = 3000;
  const MAX_FULL_IMAGE_WIDTH = 1900;
  const id = useCallback(uniqueId('BannerSlider--'), []);
  const notVariableWidth = useCallback(
    (slides) =>
      !slides.every((slide) => slide.image_width >= MAX_FULL_IMAGE_WIDTH),
    []
  );

  const getSettings = useCallback((slides = []) => {
    const slideCount = slides.length;

    const effectiveSettings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: AUTOPLAY_SPEED,
      centerMode: false,
      draggable: true,
      swipeToSlide: true,
      variableWidth: notVariableWidth(slides),
      infinite: false,
      touchThreshold: 40,
      ...overrideSettings,
    };

    if (effectiveSettings.vertical) {
      effectiveSettings.slidesToShow = Math.min(
        slideCount,
        effectiveSettings.slidesToShow
      );
    }

    return effectiveSettings;
  }, []);

  const bannerSliderRef = useRef(null);
  const placeholderRef = useRef(null);
  const sliderRef = useRef(null);
  let resetSlideResetTimeoutID = null;

  const [sentPromotionEvents, setSentPromotionEvents] = useState([]);
  const [elementWidth, setElementWidth] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(resetSlideResetTimeoutID);
      resetSlideResetTimeoutID = null;
    };
  }, []);

  const handleResize = () => {
    if (!bannerSliderRef.current) {
      return null;
    }

    if (elementWidth !== bannerSliderRef.current.clientWidth) {
      setElementWidth(bannerSliderRef.current.clientWidth);
    }
  };

  const interactionStartHandler = () => {
    !getSettings().infinite && clearTimeout(resetSlideResetTimeoutID);
  };

  const interactionEndHandler = (slideCount) => {
    !getSettings().infinite && setSlideResetTimeout(slideCount);
  };

  const setSlideResetTimeout = (slideCount, currentSlide) => {
    if (!slideCount || currentSlide === undefined) {
      return;
    }

    resetSlideResetTimeoutID = setTimeout(() => {
      if (
        sliderRef.current &&
        slideCount % (currentSlide || currentSlideIndex) >= 0
      ) {
        sliderRef.current.slickGoTo(0);
      }
    }, AUTOPLAY_SPEED);
  };

  const sendPromotionView = (slideIndex, slides) => {
    const banner = slides[slideIndex];

    if (banner && sentPromotionEvents.indexOf(slideIndex) === -1) {
      const bannerZone = adZone;
      analytics.promoView([
        {
          bannerZone,
          banner,
        },
      ]);

      setSentPromotionEvents([...sentPromotionEvents, slideIndex]);
    }
  };

  const onSliderInit = (slides) => {
    slides.length > 0 && sendPromotionView(0, slides);
  };

  const onBannerAfterChange = (slides, currentSlide) => {
    const { analytics: analyticsConfig } = configStore;
    const slideCount = slides.length;
    if (analyticsConfig.ga4.enabled) {
      slideCount > 0 && sendPromotionView(currentSlide, slides);
    }

    setCurrentSlideIndex(currentSlide);
    !getSettings().infinite && setSlideResetTimeout(slideCount, currentSlide);
  };

  const onBannerBeforeChange = () => {
    !getSettings().infinite && clearTimeout(resetSlideResetTimeoutID);
  };

  const getSliderEvents = (slides) => {
    const { analytics: analyticsConfig } = configStore;

    const events = {
      beforeChange: onBannerBeforeChange,
      afterChange: (currentSlide) => onBannerAfterChange(slides, currentSlide),
    };

    if (analyticsConfig.ga4.enabled) {
      events.onInit = () => onSliderInit(slides);
    }

    return events;
  };

  const getScentHeight = () => {
    return configStore.getScentHeight(uiStore.isMobile);
  };

  const getMaximumDefaultHeight = () => {
    const parentHeight =
      bannerSliderRef.current && elementWidth > 0
        ? countMainAxisSize(elementWidth, aspectRatio)
        : maximumCrossAxisSize;

    /* Add scent height as it affects CLS */
    return (
      countMinCrossAxisSize(maximumCrossAxisSize, parentHeight) +
      getScentHeight()
    );
  };

  const getSlides = (ads) => {
    return [...ads].filter((banner) => {
      return (
        (uiStore.isSmallest && !banner.hide_from_mobile_users) ||
        (!uiStore.isSmallest && !banner.hide_from_desktop_tablet_users)
      );
    });
  };

  const getAds = () => (ads) => {
    if (!ads || ads.length === 0) {
      return null;
    }

    const slides = getSlides(ads);
    const sliderEvents = getSliderEvents(slides);

    return (
      <div
        className={classNames('BannerSlider', className)}
        id={id}
        ref={bannerSliderRef}
        onMouseEnter={interactionStartHandler}
        onMouseLeave={() => interactionEndHandler(slides.length)}
        onTouchStart={interactionStartHandler}
        onTouchEnd={() => interactionEndHandler(slides.length)}
      >
        <CommonSlider
          ref={sliderRef}
          {...getSettings(slides)}
          {...sliderEvents}
        >
          {slides.map((banner) => {
            return (
              <div key={banner.id} className="BannerSlider__slide">
                <Banner banner={banner} bannerZone={adZone} />
              </div>
            );
          })}
        </CommonSlider>
        <ReactResizeDetector
          handleWidth
          onResize={handleResize}
          refreshMode="throttle"
          refreshRate={1000}
        />
        <BannerSliderStyles
          bannerSliderId={id}
          slides={slides}
          elementWidth={elementWidth}
          bannerSlider={bannerSliderRef.current}
          aspectRatio={aspectRatio}
          maximumCrossAxisSize={maximumCrossAxisSize}
          ifVertical={getSettings().vertical}
          slidesToShow={getSettings(slides).slidesToShow}
        />
      </div>
    );
  };

  if (preLoadedAds && preLoadedAds.length > 0) {
    return <AdLoaded ads={preLoadedAds}>{getAds()}</AdLoaded>;
  }

  const getParentHeight = () => {
    return bannerSliderRef.current && elementWidth > 0
      ? null
      : { style: { height: getMaximumDefaultHeight() } };
  };

  const ads = adStore.getAdsByAdZone(searchParams, adZone);
  if (
    adStore.getAdQueryStateBySearchParameters(searchParams) ===
      RequestState.LOADED &&
    ads?.length === 0
  ) {
    return null;
  }

  return (
    <LazyLoad
      once
      offset={50}
      height={getMaximumDefaultHeight()}
      ref={placeholderRef}
      {...getParentHeight()}
    >
      <AdLoader
        placeHolder={<AdLoaderSkeleton />}
        searchParams={searchParams}
        adZone={adZone}
      >
        {getAds()}
      </AdLoader>
    </LazyLoad>
  );
};

BannerSlider.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  aspectRatio: PropTypes.number.isRequired,
  searchParams: PropTypes.object.isRequired,
  adZone: PropTypes.string.isRequired,
  preLoadedAds: PropTypes.array,
  className: PropTypes.string,
  // Cross axis size here refers to either maximum height (if the slider is
  // horizontal) or maximum width (if the slider is vertical).
  maximumCrossAxisSize: PropTypes.number,
  overrideSettings: PropTypes.object,
};

BannerSlider.defaultProps = {
  maximumCrossAxisSize: 400,
};

export default inject(
  'adStore',
  'configStore',
  'uiStore',
  'analytics'
)(observer(BannerSlider));
