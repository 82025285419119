export const supportedLanguageLocales = {
  da: 'da_DK',
  de: 'de_DE',
  en: 'en_GB',
  es: 'es_ES',
  et: 'et_EE',
  fi: 'fi_FI',
  fr: 'fr_FR',
  it: 'it_IT',
  nl: 'nl_NL',
  no: 'no_NB',
  pt: 'pt_PT',
  ru: 'ru_RU',
  sv: 'sv_SE',
};
