import { blockScroll, unblockScroll } from '../../util/dom';
import Modal from './Modal';

const MobileRegionalSettingsModal = Modal.named(
  'MobileRegionalSettingsModal'
).actions((self) => {
  return {
    close() {
      self.isOpen = false;
      unblockScroll();
    },
    open() {
      self.isOpen = true;
      blockScroll();
    },
  };
});

export default MobileRegionalSettingsModal;
