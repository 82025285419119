import { types } from 'mobx-state-tree';

import LocalizationSites from '../models/LocalizationSites';
import StatefulStore from '../models/StatefulStore';
import Modal from '../models/modal/Modal';
import LocalStorageKey from '../types/LocalStorageKey';
import { getStorageAndParse, stringifyAndSetStorage } from '../util/storage';

const LocalizationStore = StatefulStore.named('LocalizationStore')
  .props({
    countryCode: types.maybeNull(types.string),
    sites: types.array(LocalizationSites),
    localizationModal: types.optional(Modal.named('LocalizationModal'), {}),
  })
  .views((self) => {
    return {
      get suggestionEnabled() {
        return self.sites.length > 0;
      },
    };
  })
  .actions((self) => {
    const getLocalStorage = () => {
      return !!getStorageAndParse(LocalStorageKey.SITE_CHOSEN);
    };
    const setLocalStorage = () => {
      stringifyAndSetStorage(LocalStorageKey.SITE_CHOSEN, true);
    };
    const setup = (localization) => {
      if (localization) {
        self.countryCode = localization.countryCode;
        self.sites = localization.sites;
        self.suggestedSite = localization.suggestedSite;
      }
    };
    return {
      getLocalStorage,
      setLocalStorage,
      setup,
    };
  });

export default LocalizationStore;
