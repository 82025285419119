import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ProductClass from '../../../types/ProductClass';
import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import ProductSigns from '../ProductSigns';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import PropTypes from 'prop-types';
import FullProduct from '../../../models/product/FullProduct';

@observer
export class ProductImageSigns extends Component {
  render() {
    const { accountStore, configStore, product } = this.props;

    const productForDiscountCalculations =
      product.class === ProductClass.MULTI
        ? product.multi.getChildWithBestDiscount()
        : product;

    if (!productForDiscountCalculations) {
      return null;
    }

    const quantityDiscountDisplayStyle =
      configStore.product.productQuantityDiscountDisplayStyle;

    const { discountAmount, hasPriceRange, hasDiscount } =
      productForDiscountCalculations.getDiscountInfo(
        accountStore.showPricesWithTax,
        quantityDiscountDisplayStyle
      );

    return (
      <div className="ProductImageSigns">
        <ProductSigns
          dateAdded={product.date_added}
          hasDiscount={hasDiscount}
          discountProps={{
            percent: discountAmount,
            withUpTo: hasPriceRange,
          }}
        />
      </div>
    );
  }
}

ProductImageSigns.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.oneOfType([
    modelOf(Product).isRequired,
    modelOf(FullProduct).isRequired,
  ]),
};

export default inject('accountStore', 'configStore')(ProductImageSigns);
