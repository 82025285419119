import { useCallback } from 'react';

/**
 *
 * @param {Object} args
 * @param {CategoryStore} args.categoryStore
 * @param {SectionStore} args.sectionStore
 * @param {FullProduct} args.product
 * @returns
 */
const useProductPageContainer = ({ categoryStore, sectionStore, product }) => {
  const getActiveHierarchyCategory = () => {
    // Return only one result.
    return product.allCategories.filter((category) => {
      if (category.id !== product.main_category_id) {
        if (
          category.hierarchy.findIndex(
            (filteredCategory) =>
              filteredCategory.id === categoryStore.latestActiveCategory.id
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    })[0];
  };

  const getCategoryBySection = () => {
    // Will return one result if the current hierarchy is not the main one.
    return product.allCategories.filter((category) => {
      if (category.id !== product.main_category_id) {
        if (
          category.hierarchy.findIndex((filteredCategory) =>
            sectionStore.activeSection.includesCategory(filteredCategory)
          ) !== -1
        ) {
          return true;
        }
      }
      return false;
    })[0];
  };

  const updateActiveCategory = useCallback(() => {
    // Default category is products main category.
    let activeCategory = product.main_category_id;

    if (product.allCategories.length === 1) {
      categoryStore.setActiveCategory(activeCategory);
      return;
    }

    const mainCategory = product.main_category_id
      ? categoryStore.findCategoryById(product.main_category_id)
      : null;

    if (categoryStore.latestActiveCategory) {
      const activeIsMainCategory =
        mainCategory &&
        mainCategory.hierarchy.findIndex(
          (category) => category.id === categoryStore.latestActiveCategory.id
        ) !== -1;

      // When currently active category is not its main category
      // the correct one needs to be scanned from product other categories.
      if (
        !activeIsMainCategory &&
        product.belongsToCategory(categoryStore.latestActiveCategory.id)
      ) {
        const targetCategory = getActiveHierarchyCategory();
        if (targetCategory) {
          activeCategory = targetCategory.id;
        }
      }
    } else {
      if (sectionStore.activeSection) {
        const target = getCategoryBySection();
        if (target) {
          activeCategory = target.id;
        }
      }
    }

    categoryStore.setActiveCategory(activeCategory);
  }, []);

  return [updateActiveCategory];
};
export default useProductPageContainer;
