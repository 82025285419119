const useImageUrl = ({ product, locationContext }) => {
  if (!product?.images || product.images.length === 0) {
    return null;
  }

  const productImage = product.images[0];

  return productImage.sizes['full'].length > 0 &&
    productImage.sizes['full'].startsWith('http')
    ? productImage.sizes['full']
    : `${locationContext.protocol}//${locationContext.host}${productImage.sizes['full']}`;
};

export default useImageUrl;
