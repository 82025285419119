import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import useImageUrl from '../../../hooks/image/useImageUrl';
import FullProduct from '../../../models/product/FullProduct';
import { modelOf } from '../../../prop-types';
import LocationContext from '../../../services/LocationContext';
import RouteService from '../../../services/RouteService';
import AccountStore from '../../../store/AccountStore';
import CurrencyStore from '../../../store/CurrencyStore';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import CanonicalLink from '../../head/CanonicalLink';
import GenericMeta from '../../head/GenericMeta';
import OGPProduct from '../../head/OGPProduct';
import RobotsMeta from '../../head/RobotsMeta';
import SEODescription from '../../head/SEODescription';
import SEOTitle from '../../head/SEOTitle';
import ProductBaseSchema from '../../schema/product/ProductBaseSchema';

const ProductPageHead = ({
  accountStore,
  currencyStore,
  product,
  routeService,
  locationContext,
  activeProductId,
}) => {
  const media = useImageUrl({ product, locationContext });

  const actualProduct = product.getActualProduct(activeProductId) || product;
  const canonicalPath = routeService.getCanonicalProductPath(product);

  const getAvailability = () => {
    let activeProduct = actualProduct;

    if (product.isCollection) {
      activeProduct =
        product.collection.getItemWithProductId(activeProductId)?.product ||
        actualProduct;
    }

    const availabilityHtml = !activeProduct.availability_html;

    if (availabilityHtml.length === 0 || !activeProductId) {
      return null;
    }

    const freeQuantity = activeProduct.free_quantity;

    if (
      actualProduct.availability_type === ProductAvailabilityType.ONLY_IN_SHOP
    ) {
      return 'in store only';
    } else if (
      freeQuantity === 0 &&
      actualProduct.availability_type ===
        ProductAvailabilityType.ALLOW_BACKORDER
    ) {
      return 'back order';
    } else if (freeQuantity === 0 && actualProduct.isNotAvailable()) {
      return 'sold out';
    } else {
      return 'in stock';
    }
  };

  const getItemGroupId = () => {
    if (product.isMulti()) {
      return product.id;
    }

    return null;
  };

  return (
    <>
      {!product.isIndexableByRobots && <RobotsMeta noindex nofollow />}
      <GenericMeta name="twitter:card" content="summary" />
      {media && (
        <>
          <GenericMeta name="twitter:image" content={media} />
          <GenericMeta property="og:image" content={media} />
        </>
      )}
      <GenericMeta property="og:type" content="product" />
      <OGPProduct
        price={actualProduct?.getPriceWithPrecision(
          accountStore.showPricesWithTax
        )}
        availability={getAvailability()}
        manufacturer={product.manufacturer_id}
        currency={currencyStore.activeCurrency.code}
        retailerItemId={
          actualProduct.manufacturer_product_id || actualProduct.id
        }
        itemGroupId={getItemGroupId()}
      />
      <SEOTitle title={product.seoTitle} />
      {product.seoDescription && (
        <SEODescription content={product.seoDescription} />
      )}
      <CanonicalLink path={canonicalPath} />
      <ProductBaseSchema product={product} />
    </>
  );
};

ProductPageHead.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  locationContext: PropTypes.instanceOf(LocationContext).isRequired,
  product: modelOf(FullProduct).isRequired,
  activeProductId: PropTypes.string,
};

export default inject(
  'accountStore',
  'currencyStore',
  'routeService',
  'locationContext'
)(observer(ProductPageHead));
