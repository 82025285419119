import { chunk, debounce } from 'lodash';
import { GoogleAnalytics4EventNames } from '../../AnalyticsEventNames';
import DataLayer from '../Models/DataLayer';
import Item from '../Models/Item';

const IMPRESSION_MAX_BATCH_SIZE = 35;

class Promotion {
  /** @type {Item} item */
  #item;
  #currency;
  #viewPromotionsDataQueue;
  #requestViewPromotionPushToDataLayer;
  #dataLayer;

  /**
   *
   * @param {Item} item
   * @param {CurrencyStore} currencyStore
   */
  constructor(item, currencyStore) {
    this.#dataLayer = new DataLayer();
    this.#item = item;
    this.#currency = currencyStore.currencyCode;

    this.#viewPromotionsDataQueue = [];

    // We don't want to spam Google Analytics, so we will always wait for 100ms for new data before sending impressions
    // and promoViews data out. If we have a constant flow of data, we will send data every 500 ms (maxWait).
    const wait = 100; // ms
    const maxWait = 500; // ms
    this.#requestViewPromotionPushToDataLayer = debounce(
      this.#pushPromotionsToDataLayer,
      wait,
      {
        maxWait,
      }
    );
  }

  /**
   * @param {Object} promotion
   * @param {Ad[]} promotion.bannerList
   */
  sendSelectPromotionEvent = (bannerList) => {
    const items = bannerList.map((item) => this.#createPromotion(item));

    const data = {
      ecommerce: {
        currency: this.#currency,
        items,
      },
    };

    this.#dataLayer.pushEventToDataLayer(
      data,
      GoogleAnalytics4EventNames.selectPromotion
    );
  };

  /**
   * @param {Object} promotion
   * @param {Ad[]} promotion.bannerList
   */
  sendViewPromotionEvent = (bannerList) => {
    const promotions = bannerList.map((item) => this.#createPromotion(item));

    this.#pushToPromoViewDataQueue(promotions);
  };

  #createPromotion = ({ bannerZone, banner }) => {
    const product = {};
    const creativeName =
      banner.title || banner.mobile_image || banner.image || undefined;
    const creativeSlot = bannerZone;

    product.creativeName = creativeName;
    product.creativeSlot = creativeSlot;

    if (banner.productId) {
      product.id = banner.productId;
    }

    if (banner.productId && banner.title) {
      product.name = banner.title;
    }

    if (banner.productId && banner.manufacturer) {
      product.manufacturer = { name: banner.manufacturer };
    }

    if (banner.priceInfo) {
      product.price_info = banner.priceInfo;
    }

    if (banner.canonicalPath) {
      product.canonicalPath = banner.canonicalPath;
    }

    if (banner.mainSectionId) {
      product.main_section_id = banner.mainSectionId;
    }

    return this.#item.analyticsItemToItem({ product }, true);
  };

  /**
   * Promo views come from multiple components, so we queue them instead of sending them right away.
   * @param promoViews
   */
  #pushToPromoViewDataQueue = (promoViews) => {
    this.#viewPromotionsDataQueue =
      this.#viewPromotionsDataQueue.concat(promoViews);
    this.#requestViewPromotionPushToDataLayer();
  };

  #pushPromotionsToDataLayer = () => {
    // GA has a maximum payload size of 8KB. If we have too many promotions we need to split them into chunks.
    const chunks = chunk(
      this.#viewPromotionsDataQueue,
      IMPRESSION_MAX_BATCH_SIZE
    );
    this.#pushChunksToDataLayer(
      chunks,
      GoogleAnalytics4EventNames.viewPromotion
    );
    this.#viewPromotionsDataQueue = [];
  };

  /**
   *
   * @param {Array} chunks
   * @param {string} event
   */
  #pushChunksToDataLayer = (chunks, event) => {
    chunks.forEach((chunk) =>
      this.#dataLayer.pushEventToDataLayer(
        {
          ecommerce: {
            currency: this.#currency,
            items: chunk,
          },
        },
        event
      )
    );
  };
}

export default Promotion;
