import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';
import ProductStore from '../../../store/ProductStore';
import ProductList from '../ProductList';
import ContentForState from '../../loader/ContentForState';
import { paramsToQueryIdentifier } from '../../../util/query';
import useFetchData from './hook';

const SimpleProductList = ({
  productStore,
  searchParams,
  listId,
  name,
  listComponent: ListComponent,
  completeListingLink,
  preloadOnly,
  allSections,
  singleRowMode,
  requireCanonicalLink,
  ...rest
}) => {
  const getQueryIdentifier = () => {
    return paramsToQueryIdentifier(searchParams);
  };

  const getCurrentSearchResult = () => {
    return productStore.productQueryResults.get(getQueryIdentifier());
  };

  const getCurrentSearchState = () => {
    return productStore.productQueryStates.get(getQueryIdentifier());
  };

  const params = {
    searchParams,
    allSections,
    state: getCurrentSearchState(),
  };

  useFetchData({ productStore, params });

  if (preloadOnly) {
    return null;
  }

  return (
    <div className="SimpleProductList">
      <ContentForState
        state={getCurrentSearchState()}
        error={productStore.lastError}
        forLoaded={() => (
          <ListComponent
            products={getCurrentSearchResult().data}
            listId={listId}
            name={name}
            completeListingLink={!singleRowMode ? completeListingLink : null}
            cardSettings={{
              requireCanonicalLink: requireCanonicalLink,
            }}
            {...rest}
          />
        )}
      />
    </div>
  );
};

SimpleProductList.propTypes = {
  productStore: modelOf(ProductStore).isRequired,
  searchParams: PropTypes.object.isRequired,
  listId: PropTypes.string,
  name: PropTypes.string,
  listComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  completeListingLink: PropTypes.node,
  preloadOnly: PropTypes.bool,
  allSections: PropTypes.bool,
  singleRowMode: PropTypes.bool,
  requireCanonicalLink: PropTypes.bool,
};

SimpleProductList.defaultProps = {
  listComponent: ProductList,
  preloadOnly: false,
  allSections: false,
  singleRowMode: false,
  requireCanonicalLink: false,
};

export default inject('productStore')(observer(SimpleProductList));
