import { types } from 'mobx-state-tree';
import Storage from '../../Storage';
import { ProductIncomingQuantityModel } from './ProductCollectionProduct';

const ProcessingDay = types
  .model('ProcessingDay', {
    min: types.number,
    max: types.number,
  })
  .views((self) => ({
    get maxProcessingTime() {
      if (self.max < 1) {
        return Math.round(self.max * 24);
      }

      return self.max;
    },
    get minProcessingTime() {
      if (self.min < 1) {
        return Math.round(self.min * 24);
      }

      return self.min;
    },
  }));

const ProductDetailedStockInfo = types.model('ProductDetailedStockInfo', {
  free_quantity: types.number,
  in_stock_quantity: types.maybeNull(types.number),
  incoming_quantities: types.array(ProductIncomingQuantityModel),
  stock_unit: types.string,
  storages: types.array(
    types.model({
      free_quantity: types.number,
      in_stock_processing_days: ProcessingDay,
      in_stock_quantity: types.number,
      incoming_quantities: types.array(ProductIncomingQuantityModel),
      out_of_stock_processing_days: ProcessingDay,
      storage_id: types.reference(Storage),
      translations: types.model({
        storage_name: types.string,
        processing_time_notice: types.string,
        different_processing_time: types.string,
      }),
    })
  ),
});

export default ProductDetailedStockInfo;
