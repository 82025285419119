import { types } from 'mobx-state-tree';

export const ProductIncomingQuantityModel = types.model(
  'ProductIncomingQuantityModel',
  {
    date: types.maybeNull(types.string),
    free_quantity: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
  }
);

const ProductCollectionProduct = types.model('ProductCollectionProduct', {
  availability_html: types.string,
  can_be_ordered_out_of_stock: types.boolean,
  ean: types.optional(types.string, ''),
  free_quantity: types.number,
  id: types.string,
  in_stock_quantity: types.maybeNull(types.number),
  incoming_quantities: types.array(ProductIncomingQuantityModel),
});

export default ProductCollectionProduct;
