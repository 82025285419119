import { types } from 'mobx-state-tree';

import Modal from './modal/Modal';
import FullProduct from './product/FullProduct';
import Storage from './Storage';

const ReserveInStoreModal = Modal.named('ReserveInStoreModal')
  .props({
    storage: types.maybeNull(types.reference(Storage)),
    product: types.union(
      types.reference(FullProduct),
      types.undefined,
      types.null
    ),
    activeProductId: types.optional(types.string, ''),
    // Used as a "key" for forcing the modal to remount when it is opened.
    // Using isOpen directly would work, except that it breaks the fadeout
    // animation; this way, we can make the key only change when opened and
    // not when it is closed.
    refreshCounter: 0,
  })
  .actions((self) => {
    const superToggle = self.toggle;

    return {
      setStorage(storageId) {
        self.storage = storageId;
      },
      setProduct(productId, activeProductId) {
        self.product = productId;
        self.activeProductId = activeProductId;
        self.storage = null;
      },
      toggle() {
        superToggle();
        if (self.isOpen) {
          self.refreshCounter++;
          self.storage = null;
        }
      },
    };
  });

export default ReserveInStoreModal;
