const Paths = {
  FrontPage: '/',
  AdvancedSearch: '/s/search/',
  Checkout: '/kauppa/kassa/81/',
  LiteCheckout: '/lite-checkout/',
  ProposalCheckout: '/s/proposal-checkout/',
  InfoPage: '/i/:slug/:id/',
  Product: '/:slug/p/:id/:activeProductId?/',
  OnSale: '/s/sale/',
  NewProducts: '/s/new/',
  PopularProducts: '/s/popular/',
  RecommendedProducts: '/s/recommended/',
  ManufacturerList: '/s/brands/',
  Manufacturer: '/b/:slug/:id/',
  Section: '/:section/',
  Category: '/:rootProductGroup/:productGroup?/:subProductGroup?/c/:id/',
  MainProductGroup: '/:rootProductGroup/c/:id/',
  ProductGroup: '/:rootProductGroup/:productGroup/c/:id/',
  SoldProducts: '/s/sold-products/',
  SubProductGroup: '/:rootProductGroup/:productGroup/:subProductGroup/c/:id/',
  MyAccount: '/s/my-account/',
  MyAccountAddressList: '/s/my-account/addresses/',
  MyAccountAddress: '/s/my-account/addresses/:id/',
  MyAccountAddressNew: '/s/my-account/addresses/new/',
  MyAccountBalanceUser: '/s/my-account/money-transfer/payment/',
  MyAccountBalanceUserAccountInfo: '/s/my-account/money-transfer/info/',
  MyAccountBalanceUserPaymentHistory:
    '/s/my-account/money-transfer/payment/history/',
  MyAccountBalanceSubUser: '/s/my-account/money-transfer/usage/',
  MyAccountChangePassword: '/s/my-account/change-password/',
  MyAccountCreditAccount: '/s/my-account/credit/',
  MyAccountInfo: '/s/my-account/info/',
  MyAccountOrderList: '/s/my-account/orders/',
  MyAccountOrder: '/s/my-account/orders/:id/',
  MyAccountPaymentTokenList: '/s/my-account/card-tokenization/',
  MyAccountPurchasesList: '/s/my-account/purchases/',
  MyAccountRecurringOrder: '/s/my-account/recurring-orders/:id/',
  MyAccountRecurringOrderList: '/s/my-account/recurring-orders/',
  MyAccountUserList: '/s/my-account/users/',
  MyAccountUser: '/s/my-account/users/:id/',
  MyAccountUserNew: '/s/my-account/users/new/',
  SetNewPassword: '/s/set-new-password/:token/',
  ThankYouPage: '/s/checkout/thank-you/',
  MyAccountThankYouPage: '/s/my-account/money-transfer/success/',
  ProposalThankYouPage: '/s/proposal-checkout/thank-you/:id/:trackingCode/',
  NotFoundPage: '/not-found/',
  CustomerProducts: '/s/customer-prices/',
  WishList: '/s/wishlist/',
  WishListProductList: '/s/wishlist/:accessToken',
  VehiclePartSearch: '/s/vehicle-part-search/',
};

export default Paths;
