import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import ProductImageModel from '../../../models/ProductImage';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import ProductAvailabilityText from '../ProductAvailabilityText';
import ProductExtraInfo from '../ProductExtraInfo';
import ProductImage from '../ProductImage';
import ProductPackageSize from '../ProductPackageSize';
import ProductPagePrice from '../ProductPagePrice';
import ProductPrice from '../ProductPrice';

const createProductImage = (url) => {
  return url
    ? ProductImageModel.create({
        id: 0,
        product_id: '',
        sizes: {
          small: url,
        },
      })
    : null;
};

const ProductMultiMatrixTooltip = ({
  accountStore,
  configStore,
  product,
  isOpen,
  renderQuantityDiscounts,
  className,
  children,
  placement,
  boundariesElement,
}) => {
  const productImage = product.images.find(
    (image) => image.product_id === product.id
  );

  const withTax = accountStore.showPricesWithTax;

  const renderPrice = () => {
    const quantityDiscountDisplayStyle =
      configStore.product.productQuantityDiscountDisplayStyle;

    const { hasPriceRange } = product.getDiscountInfo(
      withTax,
      quantityDiscountDisplayStyle
    );

    const price = (
      <ProductPrice
        product={product}
        withTax={withTax}
        priceInfo={product.price_info}
      />
    );

    return (
      <ProductPagePrice
        price={price}
        product={product}
        quantityDiscounts={product.getQuantityDiscounts().discounts}
        discountInverted={hasPriceRange}
      />
    );
  };

  const renderContent = () => {
    return (
      <>
        <PopoverHeader>
          <FormattedMessage
            id="product.informationTitle"
            defaultMessage="Product information"
          />
        </PopoverHeader>
        <PopoverBody>
          <div className="ProductMatrixTooltip__content">
            <div className="ProductMatrixTooltip__product-information">
              <div className="ProductMatrixCell__popover-image_info">
                {productImage && productImage.sizes.small && (
                  <ProductImage
                    product={product}
                    productImage={createProductImage(productImage.sizes.small)}
                    size="small"
                    forceAspectRatio={false}
                  />
                )}
                <ProductExtraInfo product={product} singleColumn={true} />
              </div>
              {renderPrice()}
              <ProductAvailabilityText
                availabilityHtml={product.availability_html}
              />
              {product.sellInPackage && (
                <ProductPackageSize product={product} withTax={withTax} />
              )}
            </div>
            <span className="ProductMatrixTooltip__quantity-discounts">
              {renderQuantityDiscounts}
            </span>
          </div>
        </PopoverBody>
      </>
    );
  };

  return (
    <Popover
      target={`ProductInfo-${product.id}`}
      isOpen={isOpen}
      placement={placement}
      boundariesElement={boundariesElement}
      popperClassName={classNames('ProductMatrixTooltip', className, {
        'ProductMatrixTooltip--left': placement === 'left',
      })}
    >
      {children ? children : renderContent()}
    </Popover>
  );
};

ProductMultiMatrixTooltip.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  boundariesElement: PropTypes.string,
  renderQuantityDiscounts: PropTypes.node,
};

ProductMultiMatrixTooltip.defaultProps = {
  placement: 'top',
  boundariesElement: 'window',
};

export default inject(
  'accountStore',
  'configStore'
)(observer(ProductMultiMatrixTooltip));
