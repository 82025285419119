import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { LocationContextPropType } from '../../../services/LocationContext';
import GenericMeta from '../GenericMeta';

const SEOLink = ({ rel, path, addOpenGraphTag, locationContext }) => {
  const url = `${locationContext.protocol}//${locationContext.host}${path}`;

  return (
    <>
      {addOpenGraphTag && (
        <>
          <GenericMeta name="twitter:url" content={url} />
          <GenericMeta property="og:url" content={url} />
        </>
      )}
      <Helmet>
        <link rel={rel} href={url} />
      </Helmet>
    </>
  );
};

SEOLink.propTypes = {
  rel: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  locationContext: LocationContextPropType.isRequired,
  addOpenGraphTag: PropTypes.bool,
};
export default inject('locationContext')(observer(SEOLink));
