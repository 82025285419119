import React from 'react';
import { inject, observer } from 'mobx-react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';
import { modelOf } from '../../../prop-types';
import FullProduct from '../../../models/product/FullProduct';
import ConfigStore from '../../../store/ConfigStore';

const messages = defineMessages({
  downloadFile: {
    id: 'product.downloadFile',
    defaultMessage: 'Download',
  },
});

const ProductFiles = ({ configStore, product, intl }) => {
  const getProductFileUrl = (name) => {
    const isShoppingCenter = configStore.siteConfig.isShoppingCenter;

    return isShoppingCenter ? name : `/storage/product_files/${name}`;
  };

  const downloadFileText = intl.formatMessage(messages.downloadFile);

  return (
    <div className="ProductFiles">
      {product.files.map((file) => (
        <div key={file.name} className="ProductFile">
          <a
            title={downloadFileText}
            className="ProductFile__link"
            target="_blank"
            rel="noopener"
            href={getProductFileUrl(file.name)}
          >
            <div className="ProductFile__name">{file.description}</div>
            <div className="ProductFile__download">
              <Icon className="ProductFile__icon" name="file" />{' '}
              <span className="ProductFile__download-text">
                {downloadFileText}
              </span>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

ProductFiles.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  product: PropTypes.oneOfType([modelOf(FullProduct), PropTypes.object])
    .isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(inject('configStore')(observer(ProductFiles)));
