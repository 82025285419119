import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import Category from '../../../models/Category';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import CategoryBadges from '../../common/CategoryBadges';

const defaultImagePath = '/img/no_image_available.png';

const NavigationCategoryImage = ({ sectionStore, routeService, category }) => {
  const name = category.navigationName;
  const image = category.image || defaultImagePath;
  const mobileImage = category.mobile_image;

  const renderImage = () => {
    return (
      <div
        className={classNames('NavigationCategoryImage__image', {
          'NavigationCategoryImage__image--with-discount': category.discount,
        })}
      >
        {category.discount && <CategoryBadges category={category} />}
        <picture>
          {mobileImage && (
            <source
              media={`(max-width:${ViewBreakpointMinWidth.SM}px)`}
              srcSet={encodeURI(mobileImage)}
              height={130}
              width={130}
            />
          )}
          <img src={image} alt={name} title={name} height={130} width={130} />
        </picture>
      </div>
    );
  };

  return (
    <div key={category.id} className="NavigationCategoryImage">
      <Link
        className="CategoryLink"
        to={routeService.getPath(category.path, sectionStore.activeSection)}
      >
        {renderImage()}
        <div className="NavigationCategoryImage__name">
          {category.navigationName}
        </div>
      </Link>
    </div>
  );
};

NavigationCategoryImage.propTypes = {
  sectionStore: modelOf(SectionStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  category: modelOf(Category).isRequired,
};

export default inject(
  'sectionStore',
  'routeService'
)(observer(NavigationCategoryImage));
