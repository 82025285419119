import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import Paths from '../../types/Paths';
import AdvancedSearchPage from '../../pages/AdvancedSearchPage';
import InfoPage from '../../pages/InfoPage';
import ManufacturerPage from '../../pages/ManufacturerPage';
import OnSalePage from '../../pages/OnSalePage';
import NewProductsPage from '../../pages/NewProductsPage';
import PopularProductsPage from '../../pages/PopularProductsPage';
import RecommendedProductsPage from '../../pages/RecommendedProductsPage';
import ManufacturerListPage from '../../pages/ManufacturerListPage';
import SetNewPasswordPage from '../../pages/SetNewPasswordPage';
import MyAccountThankYouPage from '../../pages/MyAccountThankYouPage';
import FallbackSpinner from '../../components/loader/FallbackSpinner';
import SectionRoute from '../../components/route/SectionRoute';
import useSectionDependantRoutes from './useSectionDependantRoutes';
import SectionAwareRoute from '../../components/route/SectionAwareRoute';
import MyAccountOrder from '../../components/my-account/MyAccountOrder';
import { parse } from '../../util/queryString';

const loadableOptions = {
  fallback: <FallbackSpinner />,
};

const MyAccountPage = loadable(
  () => import(/* webpackChunkName: "myAccount" */ '../../pages/MyAccountPage'),
  loadableOptions
);

const CheckoutPage = loadable(
  () => import(/* webpackChunkName: "checkout" */ '../../pages/CheckoutPage'),
  loadableOptions
);

const CustomerProductsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "sortingLists" */ '../../pages/CustomerProductsPage'
    ),
  loadableOptions
);

const ThankYouPage = loadable(
  () => import(/* webpackChunkName: "checkout" */ '../../pages/ThankYouPage'),
  loadableOptions
);

const ProposalThankYouPage = loadable(
  () =>
    import(
      /* webpackChunkName: "checkout" */ '../../pages/ProposalThankYouPage'
    ),
  loadableOptions
);

const WishListPage = loadable(
  () => import(/* webpackChunkName: "wishlist" */ '../../pages/WishListPage'),
  loadableOptions
);

const createCommonRoute = (path, component) => {
  return {
    path,
    component,
  };
};

const commonRoutes = [
  createCommonRoute(Paths.AdvancedSearch, <AdvancedSearchPage />),
  createCommonRoute(Paths.InfoPage, <InfoPage />),
  createCommonRoute(Paths.Manufacturer, <ManufacturerPage />),
  createCommonRoute(Paths.OnSale, <OnSalePage />),
  createCommonRoute(Paths.NewProducts, <NewProductsPage />),
  createCommonRoute(Paths.PopularProducts, <PopularProductsPage />),
  createCommonRoute(Paths.RecommendedProducts, <RecommendedProductsPage />),
  createCommonRoute(Paths.ManufacturerList, <ManufacturerListPage />),
  createCommonRoute(Paths.SetNewPassword, <SetNewPasswordPage />),
  createCommonRoute(Paths.ThankYouPage, <ThankYouPage />),
  createCommonRoute(Paths.MyAccountThankYouPage, <MyAccountThankYouPage />),
  createCommonRoute(Paths.CustomerProducts, <CustomerProductsPage />),
  createCommonRoute(Paths.ProposalCheckout, <CheckoutPage />),
  createCommonRoute(Paths.ProposalThankYouPage, <ProposalThankYouPage />),
];

/**
 * @param {Object} props
 * @param {ConfigStore} props.configStore
 * @param {Section[]} props.sections
 * @param {string} props.match
 * @param {ReactNode} props.overrideComponent
 * @returns {Route[]}
 */
const useRoutes = ({
  accountStore,
  configStore,
  sections,
  match,
  overrideComponent = null,
}) => {
  const location = useLocation();

  const sectionDependantRoutes = useSectionDependantRoutes({
    configStore,
    match,
    overrideComponent,
  });

  const getCurrentOrderAuthenticationCode = () => {
    const query = parse(location.search);
    return query.code;
  };

  /**
   * @returns {Route[]|[]}
   */
  const getSectionRoutes = () =>
    configStore.activateSections
      ? sections.map((section) => {
          const path = `${match}/${section.slug}`;

          return (
            <Route
              key={section.id}
              path={path}
              children={() => (
                <SectionRoute
                  section={section}
                  overrideComponent={overrideComponent}
                />
              )}
            />
          );
        })
      : [];

  /**
   * @returns {Route[]}
   * Wrapping common routes in a component for state updates.
   * Using mst actions inside render prop causes "bad state" errors.
   */
  const getCommonRoutes = () =>
    commonRoutes.map((route) => {
      return (
        <Route
          key={route.path}
          exact
          path={match + route.path}
          children={() => (
            <SectionAwareRoute
              component={overrideComponent || route.component}
            />
          )}
        />
      );
    });

  /**
   * @returns {Route[]}
   */
  const getRoutesBehindConfig = () => {
    const routes = [];
    const code = getCurrentOrderAuthenticationCode();

    /* Order preview should be available when MyAccount is not enabled.
       Code / hash must exist when user is not logged in. */
    if (
      !configStore.account.enableMyAccount &&
      ((!accountStore.loggedIn && code) || accountStore.loggedIn)
    ) {
      routes.push(
        <Route
          exact
          path={match + Paths.MyAccountOrder}
          render={(props) => <MyAccountOrder {...props} code={code} />}
        />
      );
    }

    if (
      configStore.account.enableMyAccount &&
      !configStore.siteConfig.isShoppingCenter
    ) {
      routes.push(
        <Route
          key={Paths.MyAccount}
          path={match + Paths.MyAccount}
          children={() => (
            <SectionAwareRoute
              component={overrideComponent || <MyAccountPage />}
            />
          )}
        />
      );
    }

    if (configStore.wishlist.enabled) {
      routes.push(
        <Route
          key={Paths.WishList}
          exact
          path={match + Paths.WishList}
          children={() => (
            <SectionAwareRoute
              component={overrideComponent || <WishListPage />}
            />
          )}
        />,
        <Route
          key={Paths.WishListProductList}
          exact
          path={match + Paths.WishListProductList}
          children={() => (
            <SectionAwareRoute
              component={overrideComponent || <WishListPage />}
            />
          )}
        />
      );
    }

    return routes;
  };

  return [
    ...getRoutesBehindConfig(),
    ...getCommonRoutes(),
    ...getSectionRoutes(),
    ...sectionDependantRoutes,
  ];
};

export default useRoutes;
