import { inject, observer } from 'mobx-react';
import React from 'react';

import { supportedLanguageLocales } from '../../../i18n/languageLocales';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import LanguageStore from '../../../store/LanguageStore';
import GenericMeta from '../GenericMeta';

const OGPBasic = ({ configStore, languageStore }) => {
  const siteName = configStore.store.name;

  const getTwitterSiteName = () => {
    if (configStore.twitter.siteName) {
      return configStore.twitter.siteName;
    } else {
      return null;
    }
  };

  const twitterSiteName = getTwitterSiteName();

  const locale = supportedLanguageLocales[languageStore.activeLocale];

  return (
    <>
      <GenericMeta property="og:site_name" content={siteName} />
      <GenericMeta property="og:locale" content={locale} />
      <GenericMeta property="og:type" content="website" />
      {twitterSiteName && (
        <GenericMeta name="twitter:site" content={twitterSiteName} />
      )}
    </>
  );
};

OGPBasic.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
};

export default inject('configStore', 'languageStore')(observer(OGPBasic));
