import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { modelOf } from '../../../prop-types';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';

import ProductFileUploadForm from '../ProductFileUploadForm';
import RequestState from '../../../types/RequestState';
import ProductStore from '../../../store/ProductStore';
import ButtonLink from '../../common/ButtonLink';
import Icon from '../../common/Icon';
import FullProduct from '../../../models/product/FullProduct';

const messages = defineMessages({
  'product.fileUploadDeleteFile': {
    id: 'product.fileUploadDeleteFile',
    defaultMessage: 'Delete file {file}',
  },
});

@observer
class ProductFileUploads extends Component {
  componentDidMount() {
    this.loadUploadedFiles();
  }

  loadUploadedFiles = () => {
    this.props.productStore.loadUploadedFiles();
  };

  deleteFile = (fileName) => {
    const { product } = this.props;
    product.deleteFile(fileName).then(this.loadUploadedFiles);
  };

  renderContent = () => {
    const { product, productStore, intl } = this.props;

    if (productStore.uploadedFilesState !== RequestState.LOADED) {
      return (
        <FormattedMessage
          id="product.fileUploadLoading"
          defaultMessage="Loading file list..."
        />
      );
    }

    return (
      <>
        {product.uploadedFiles.length > 0 && (
          <>
            <div className="ProductFileUploads__subheader">
              <FormattedMessage
                id="product.fileUploadExistingFilesHeader"
                defaultMessage="Currently uploaded files"
              />
            </div>
            <ul className="ProductFileUploads__uploaded-items">
              {product.uploadedFiles.map((file) => {
                const fileDeleteString = intl.formatMessage(
                  messages['product.fileUploadDeleteFile'],
                  {
                    file,
                  }
                );

                return (
                  <li className="ProductFileUploads__uploaded-item" key={file}>
                    <span>{file}</span>
                    <ButtonLink
                      className="ProductFileUploads__uploaded-item-delete-button"
                      onClick={() => this.deleteFile(file)}
                      title={fileDeleteString}
                    >
                      <span className="sr-only">{fileDeleteString}</span>
                      <Icon name="close" />
                    </ButtonLink>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        <ProductFileUploadForm
          product={product}
          onFileUploaded={this.loadUploadedFiles}
        />
      </>
    );
  };

  render() {
    return (
      <div className="ProductFileUploads">
        <p>
          <FormattedMessage
            id="product.fileUploadExplanationSentence"
            defaultMessage="Upload your files for this product here"
          />
        </p>
        {this.renderContent()}
      </div>
    );
  }
}

ProductFileUploads.propTypes = {
  product: modelOf(FullProduct).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(inject('productStore')(ProductFileUploads));
