import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Storage from '../../../models/Storage';
import Stock from '../../../models/Stock';
import GoogleMapModal from '../../map/GoogleMapModal';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import ProductReserveInStoreButton from '../ProductReserveInStoreButton';
import Product from '../../../models/Product';
import PhoneNumber from '../../../types/PhoneNumber';
import Icon from '../../common/Icon';
import FullProduct from '../../../models/product/FullProduct';

@observer
export class ProductStorageStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  renderGoogleMapsModal = () => {
    const { storage, intl } = this.props;

    if (!storage.hasInformation || !this.state.modal) {
      return null;
    }

    return (
      <GoogleMapModal
        location={storage.map_link_search_text}
        locale={intl.locale}
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        title={storage.name}
      />
    );
  };

  renderNavigationAidButton = () => {
    const { uiStore, storage, intl } = this.props;

    if (!storage.hasInformation) {
      return null;
    }

    return (
      <Button
        className={classNames('ProductStorageStock__map-button', {
          'ProductStorageStock__map-button-margin':
            uiStore.isMobile && storage.hasInformation && storage.hasAddress,
        })}
        onClick={this.toggleModal}
        color="link"
        aria-label={intl.formatMessage({
          id: 'stock.mapLink',
          defaultMessage: 'Map and driving instructions',
        })}
      >
        <FormattedMessage
          id="stock.mapLink"
          defaultMessage="Map and driving instructions"
        />
      </Button>
    );
  };

  renderStockLabel = () => {
    const { stock } = this.props;

    return stock && stock.stock_label ? (
      <div
        className="ProductStorageStock__availability"
        dangerouslySetInnerHTML={{ __html: stock.stock_label }}
      />
    ) : null;
  };

  renderStockShelfLocation() {
    const { stock } = this.props;

    return stock && stock.shelf_location ? (
      <div className="ProductStorageStock__shelfLocation">
        <span>
          <FormattedMessage
            id="stock.shelfLocation"
            defaultMessage="Shelf Location"
          />
          : {stock.shelf_location}
        </span>
      </div>
    ) : null;
  }

  renderStockStorePickupLocation = () => {
    const { storage, configStore, activeProductId, product } = this.props;

    return (
      storage.is_pickup_storage &&
      configStore.reserveInStore.enabled && (
        <ProductReserveInStoreButton
          storage={storage}
          activeProductId={activeProductId}
          product={product}
          shouldHideButton={true}
          onlyButton={true}
        />
      )
    );
  };

  renderStorageAddressInformation = () => {
    const { uiStore, storage } = this.props;

    if (!storage.hasInformation) {
      return null;
    }

    if (uiStore.isMobile) {
      return (
        <div className="ProductStorageStock__address-information">
          <div className="ProductStorageStock__street-address">
            {storage.street_address} {storage.postcode} {storage.city}{' '}
            {this.renderNavigationAidButton()}
          </div>
        </div>
      );
    }

    return (
      <div className="ProductStorageStock__address-information">
        <div className="ProductStorageStock__street-address">
          <span>{storage.street_address}</span>
          <div>
            <span className="ProductStorageStock__postcode">
              {storage.postcode}
            </span>
            <span className="ProductStorageStock__city">{storage.city}</span>
          </div>
        </div>
      </div>
    );
  };

  renderStoragePhoneNumbers = () => {
    const { storage } = this.props;
    const phoneNumberCount = storage.phone_numbers.length;
    return (
      phoneNumberCount > 0 && (
        <div className="ProductStorageStock__phone-numbers">
          {storage.phone_numbers.map(
            (phone, index) =>
              phone && (
                <div key={index} className="ProductStorageStock__phone-number">
                  {this.getPhoneNumber(phone)}
                </div>
              )
          )}
        </div>
      )
    );
  };

  getPhoneNumber = (phone) => {
    const { type, number } = phone;
    if (type === PhoneNumber.WHATSAPP) {
      return (
        <Fragment>
          <Icon name="whatsapp" />
          <a href={`https://wa.me/${number}`} target="_blank" rel="noopener">
            {number}
          </a>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Icon name="phone" />
        <span>
          <a href={`tel:${number}`}>{number}</a>
        </span>
      </Fragment>
    );
  };

  renderStorageEmails = () => {
    const { storage } = this.props;
    const emailCount = storage.emails.length;

    return (
      emailCount > 0 && (
        <div className="ProductStorageStock__storage-emails">
          {storage.emails.map(
            (email, index) =>
              email && (
                <div key={index} className="ProductStorageStock__storage-email">
                  <Fragment>
                    <Icon name="envelope" />
                    <span>
                      <a href={`mailto:${email}`}>{email}</a>
                    </span>
                  </Fragment>
                </div>
              )
          )}
        </div>
      )
    );
  };

  renderStorageOpeningHours = () => {
    const { storage } = this.props;
    const emailCount = storage.emails.length;

    return (
      storage.opening_hours && (
        <div
          className={classNames('ProductStorageStock__storage-opening-hours', {
            separator: emailCount > 0,
          })}
        >
          <Fragment>
            <Icon name="clock-o" />
            <span>{storage.opening_hours}</span>
          </Fragment>
        </div>
      )
    );
  };

  render() {
    const { storage, uiStore } = this.props;

    return (
      <div className="ProductStorageStock">
        {this.renderGoogleMapsModal()}
        <Row>
          <Col md="3">
            <div className="ProductStorageStock__storage-name">
              {storage.name}
            </div>
            {!uiStore.isMobile && this.renderNavigationAidButton()}
          </Col>
          <Col md="3">
            {this.renderStockLabel()}
            {this.renderStockShelfLocation()}
            {this.renderStockStorePickupLocation()}
          </Col>
          <Col md="3">{this.renderStorageAddressInformation()}</Col>
          <Col md="3">
            <div className="ProductStorageStock__storage-contact-information">
              {this.renderStoragePhoneNumbers()}
              {this.renderStorageEmails()}
            </div>
            {this.renderStorageOpeningHours()}
          </Col>
        </Row>
      </div>
    );
  }
}

ProductStorageStock.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  activeProductId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  product: modelOf(FullProduct).isRequired,
  storage: modelOf(Storage).isRequired,
  stock: modelOf(Stock),
};

export default inject(
  'configStore',
  'uiStore'
)(injectIntl(ProductStorageStock));
