import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import Category from '../../../models/Category';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import ConfigStore from '../../../store/ConfigStore';
import SectionStore from '../../../store/SectionStore';
import CategoryBadges from '../../common/CategoryBadges';

const NavigationCategoryButton = ({
  configStore,
  sectionStore,
  routeService,
  category,
}) => {
  return (
    <div key={category.id} className="NavigationCategoryButton">
      <Link
        className="CategoryLink"
        to={routeService.getPath(category.path, sectionStore.activeSection)}
      >
        <Button
          className={classNames('NavigationCategoryButton__button', {
            ConfigurationInputsRounded: configStore.layout.corners.isRounded,
            ConfigurationInputsBox: configStore.layout.corners.isBoxed,
            'NavigationCategoryButton__button--with-discount':
              category.discount,
          })}
          color="secondary"
        >
          {category.navigationName}
          {category.discount && <CategoryBadges category={category} />}
        </Button>
      </Link>
    </div>
  );
};

NavigationCategoryButton.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  category: modelOf(Category).isRequired,
};

export default inject(
  'configStore',
  'sectionStore',
  'routeService'
)(observer(NavigationCategoryButton));
