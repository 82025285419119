import { types, getEnv, flow, resolveIdentifier } from 'mobx-state-tree';

import RequestState from '../types/RequestState';
import StatefulStore from '../models/StatefulStore';
import ReserveInStoreService from '../models/ReserveInStoreService';
import ReserveInStoreModal from '../models/ReserveInStoreModal';

const ReserveInStoreStore = StatefulStore.named('ReserveInStoreStore')
  .props({
    services: types.optional(types.array(ReserveInStoreService), []),
    modal: types.optional(ReserveInStoreModal, {}),
  })
  .actions((self) => ({
    makeSureServicesLoaded() {
      if (self.state === RequestState.NONE) {
        return self.loadServices();
      }
      return Promise.resolve();
    },
    loadServices: flow(function* loadServices() {
      self.setLoading(true);

      try {
        self.services = yield getEnv(self).apiWrapper.request(
          `reserve-in-store/services`
        );
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
    }),
  }));

export default ReserveInStoreStore;
