import { useCallback, useState } from 'react';

export const TEXT_STATE = {
  NONE: 'NONE',
  SHOW_MORE: 'SHOW_MORE',
  SHOW_LESS: 'SHOW_LESS',
};

const useShowMoreText = ({ text }) => {
  const READ_MORE_ACTIVATION_LENGTH = 170;

  const [showingMoreText, showMoreText] = useState(TEXT_STATE.NONE);

  const hasMoreContent = text.length >= READ_MORE_ACTIVATION_LENGTH;

  const handleTextState = useCallback(() => {
    if (
      showingMoreText === TEXT_STATE.NONE ||
      showingMoreText === TEXT_STATE.SHOW_LESS
    ) {
      showMoreText(TEXT_STATE.SHOW_MORE);
    }

    if (showingMoreText === TEXT_STATE.SHOW_MORE) {
      showMoreText(TEXT_STATE.SHOW_LESS);
    }
  }, [showingMoreText]);

  const getText = useCallback(() => {
    if (showingMoreText === TEXT_STATE.SHOW_MORE) {
      return text;
    }

    if (showingMoreText === TEXT_STATE.SHOW_LESS) {
      return text.substring(0, READ_MORE_ACTIVATION_LENGTH) + '...';
    }

    return hasMoreContent && showingMoreText === TEXT_STATE.NONE
      ? text.substring(0, READ_MORE_ACTIVATION_LENGTH) + '...'
      : text;
  }, [showingMoreText, hasMoreContent, text]);

  return [hasMoreContent, showingMoreText, getText, handleTextState];
};

export default useShowMoreText;
