import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { modelOf } from '../../../prop-types';

import { paramsToQueryIdentifier } from '../../../util/query';
import ProductStore from '../../../store/ProductStore';
import ContentForState from '../../loader/ContentForState';
import RequestState from '../../../types/RequestState';
import ConfigStore from '../../../store/ConfigStore';

const MissingProductLoader = ({
  configStore,
  productStore,
  ids,
  onLoadSuccess,
  children,
  ...rest
}) => {
  const [missingIds, setMissingIds] = useState(null);

  useEffect(() => {
    filterMissingIds();
  }, []);

  useEffect(() => {
    if (missingIds) {
      loadProducts();
    }
  }, [missingIds]);

  const loadProducts = () => {
    productStore
      .loadProducts(getQueryParams(), true)
      .then(() => onLoadSuccess && onLoadSuccess(getCurrentSearchProducts()))
      .catch((e) => {
        /* nothing here */
      });
  };

  const filterMissingIds = () => {
    const missing = ids.filter((productId) => {
      return !productStore.products.has(productId);
    });
    setMissingIds(missing);
  };

  const getQueryParams = () => {
    return {
      ids: missingIds,
      includeFilters: 0,
      excludeContent: 1,
      optimizeResponse: 1,
    };
  };

  const getQueryIdentifier = () => {
    return paramsToQueryIdentifier(getQueryParams());
  };

  const getCurrentSearchProducts = () => {
    // Skip products that were not found.
    return ids
      .map((productId) => productStore.products.get(productId))
      .filter((product) => !!product);
  };

  const getCurrentSearchState = () => {
    if (!missingIds) {
      return RequestState.LOADING;
    }
    if (missingIds.length === 0) {
      return RequestState.LOADED;
    }
    return productStore.productQueryStates.get(getQueryIdentifier());
  };

  return (
    <ContentForState
      state={getCurrentSearchState()}
      error={productStore.lastError}
      forLoaded={() => children(getCurrentSearchProducts())}
      {...rest}
    />
  );
};

MissingProductLoader.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  productStore: modelOf(ProductStore).isRequired,
  ids: PropTypes.array.isRequired,
  onLoadSuccess: PropTypes.func,
};

export default inject(
  'productStore',
  'configStore'
)(observer(MissingProductLoader));
