import { types } from 'mobx-state-tree';

import ProductMulti from './ProductMulti';
import ChildFullProduct from './ChildFullProduct';

const FullProductMulti = types.compose(
  ProductMulti,
  types
    .model('ProductMulti', {
      children: types.optional(types.array(types.late(ChildFullProduct)), []),
    })
    .actions((self) => {
      return {
        setChildren(children) {
          self.children = children;
        },
      };
    })
    .views((self) => {
      return {
        canUseMatrixLayout() {
          const count = self.getMultiElementProperties().length;
          return count >= 1 && count < 5;
        },
        findChildWithProperties(propertyElementMap) {
          return self.children.find((childProduct) =>
            childProduct.hasProperties(propertyElementMap)
          );
        },
        getMultiElementProperties() {
          return self.properties.filter(
            (property) => property.elements.length > 1
          );
        },
        getPropertyElementsById(elementId) {
          return self.properties.find((property) => property.id === elementId);
        },
        getPropertyElementStatus(propertyElementMap) {
          let status = {
            exists: false,
            available: false,
          };

          // propertyElementMap contains all the properties user has chosen
          const matchingChildren = self.children.filter((currentChild) =>
            currentChild.hasProperties(propertyElementMap)
          );

          if (matchingChildren.length > 0) {
            status.exists = true;
          }

          status.available = matchingChildren.some(
            (currentChild) => currentChild.free_quantity > 0
          );

          return status;
        },
      };
    })
);

export default FullProductMulti;
