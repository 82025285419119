import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import AccountStore from '../../store/AccountStore';
import ConfigStore from '../../store/ConfigStore';
import { modelOf } from '../../prop-types';
import FacebookEventHandler from './FacebookEventHandler';
import OrderStore from '../../store/OrderStore';
import CountryStore from '../../store/CountryStore';
import TrackingStore from '../../store/TrackingStore';

const FacebookPixel = ({
  accountStore,
  configStore,
  countryStore,
  orderStore,
  trackingStore,
}) => {
  if (
    !configStore.integrations.facebookPixel.enabled ||
    !configStore.integrations.facebookPixel.id.length
  ) {
    return null;
  }

  useEffect(() => {
    setupFacebookAnalytics();
  }, []);

  const setupFacebookAnalytics = () => {
    if (configStore.integrations.facebookGraphAPI.enabled) {
      new FacebookEventHandler(
        accountStore,
        orderStore,
        countryStore,
        trackingStore.postGraphAPIServerEvent
      );

      return;
    }

    new FacebookEventHandler(accountStore, orderStore, countryStore, null);
  };

  return (
    <div>
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[1];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            window.fbq && fbq('init', '${
              configStore.integrations.facebookPixel.id
            }');
            window.fbq && fbq('track', 'PageView', {}, {eventID: ${String(
              Math.random()
            )}});
            `}
        </script>
      </Helmet>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src={`https://www.facebook.com/tr?id=${configStore.integrations.facebookPixel.id}&ev=PageView&noscript=1`}
        />
      </noscript>
    </div>
  );
};

FacebookPixel.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  orderStore: modelOf(OrderStore).isRequired,
  trackingStore: modelOf(TrackingStore).isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'countryStore',
  'orderStore',
  'trackingStore'
)(observer(FacebookPixel));
